import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import API from 'services/axios-config'
import VitrineContainer from 'views/vitrineContainer'
import IndexSectionsUseCase, { IntroductionForUseCase } from '../sections/usecaseSections'

const SingleUseCaseView = () => {
  const navigate = useNavigate()
  const [useCase, setUseCase] = useState({})
  const [isLoaded, setIsLoaded] = useState(false)
  const { name } = useParams();
  console.log(name)
  // const { params, name } = useParams();
  // const decodeParams = decodeURIComponent(params)
  // const decodeParams = JSON.parse(atob(params))
  useEffect(() => {
    setIsLoaded(false)
    API?.post(`/get_usecase_by_name`, {...{name}})
      .then((res) => {
       
        setUseCase(res?.data)
        setIsLoaded(true)
      })
      .catch((err) => {
        console.log("::::: ", err?.response?.status)
        if(err?.response?.status === 404) {
          navigate('/notfound')
        }
      })
  }, [name])

  return (
    <>
      {isLoaded &&
        <VitrineContainer
          Sections={() => <IndexSectionsUseCase data={useCase} />} Introduction={() =>
            <IntroductionForUseCase data={useCase?.header?.datas} header_url={useCase?.header_url} />}
          hidden={!useCase?.video_header?.visible} widthp1={'90%'} 
          videoUrl={useCase?.video_header?.datas?.url}
        />}
    </>
  )
}

export default SingleUseCaseView
