import React from 'react'
import { Avatar } from '@mui/material'
import './comments.scss'
import { ParentContainer } from 'components/ParentContainer/parentContainer'
import DivideTitle from 'components/DivideTitle/divideTitle'

const Comments = ({ avatar, comments }) => {
  return (
    <ParentContainer className="usecase_comments_section" style={{ backgroundColor: 'white', display: 'flex' }}>
      <div className='usecase__comments_container'>
        <DivideTitle type={'h3'} w_title={"ILS EN PARLENT MIEUX QUE NOUS !"} style={{ width: '100%' }}
          w_style={{ fontSize: 40, color: '#23272A', textAlign: 'center' }} />
        <div className='usecase_comments_content'>
          <Avatar className='avatar' sx={{ bgcolor: '#576574' }}>
            <img src={avatar} alt="logo-comment"
              className='lazyload upload-logo' />
          </Avatar>
          <div className="usecase_comments">
            <p>{comments} </p>
          </div>
        </div>

      </div>
    </ParentContainer>
  )
}

export default Comments