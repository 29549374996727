import {
  FLYING_BOX_MACDO_2, FLYING_STORE_BOXY_3, vignetteNUK, vignetteOp2, vignettePokawa, vignetteSchloukmap,
  IMG_SHOW_REEL, FLYING_HAND_CAMPUSCOACH_3, MINIATURE_CHEIKH, MINIATURE_IVAN, MINIATURE_MAÉVA, MINIATURE_THIAGO
} from "./imageUrls"

// FLYING BOX 
export const videoFlyingBox = {
  iframe: <iframe src="https://www.youtube.com/embed/whMLv7Nv03w?autoplay=1&rel=0" className="lazyload" title="YouTube video player" allow="autoplay" allowFullScreen> </iframe>,
  thumbnail: FLYING_BOX_MACDO_2
};

export const showReel = {
  iframe: <iframe src="https://www.youtube.com/embed/uOhYF6969Cc?autoplay=1&rel=0" className="lazyload" title="YouTube video player" allow="autoplay" allowFullScreen> </iframe>,
  thumbnail: IMG_SHOW_REEL
}

export const HandLinks = [
  {
    iframe: <iframe src="https://www.youtube.com/embed/9khPuYrwZsc?autoplay=1&rel=0" className="lazyload" title="YouTube video player" allow="autoplay" allowFullScreen> </iframe>,
    thumbnail: vignetteNUK
  },
  {
    iframe: <iframe src="https://www.youtube.com/embed/yJ_u8a77zIo?autoplay=1&rel=0" className="lazyload" title="YouTube video player" allow="autoplay" allowFullScreen > </iframe>,
    thumbnail: vignettePokawa
  },
]
export const storeLinks =
{
  iframe: <iframe src="https://www.youtube.com/embed/EUnQD_B4ZWc?autoplay=1&rel=0" className="lazyload" title="YouTube video player" allow="autoplay" allowFullScreen> </iframe>,
  thumbnail: FLYING_STORE_BOXY_3
}

export const handLinks =
{
  iframe: <iframe src="https://www.youtube.com/embed/XCc5LCgURIo?autoplay=1&rel=0" className="lazyload" title="YouTube video player" allow="autoplay" allowFullScreen> </iframe>,
  thumbnail: FLYING_HAND_CAMPUSCOACH_3
}

export const bikeLinks =
{
  iframe: <iframe src="https://www.youtube.com/embed/TxOVodfPk10?autoplay=1&rel=0" className="lazyload" title="YouTube video player" allow="autoplay" allowFullScreen> </iframe>,
  thumbnail: vignetteSchloukmap
}

export const wallLinks =
{
  iframe: <iframe src="https://www.youtube.com/embed/lOMfxc-JWkU?autoplay=1&rel=0" className="lazyload" title="YouTube video player" allow="autoplay" allowFullScreen> </iframe>,
  thumbnail: vignetteOp2
}

// PEOPLE INTERVIEW
export const INTERVIEW_CHEIKH = {
  iframe: <iframe src="https://www.youtube.com/embed/4a6q1sgD-Vs?list=PLscl_21NqJKqbv7iBrKxwrgI_wYRaQ3I3"
    className="lazyload" title="INTERVIEW - CHEIKH" allow="autoplay" allowFullScreen>
  </iframe>,
  thumbnail: MINIATURE_CHEIKH
}

export const INTERVIEW_IVAN = {
  iframe: <iframe src="https://www.youtube.com/embed/kYIy1feqcm8?list=PLscl_21NqJKqbv7iBrKxwrgI_wYRaQ3I3"
    className="lazyload" title="INTERVIEW - IVAN" allow="autoplay" allowFullScreen>
  </iframe>,
  thumbnail: MINIATURE_IVAN
}

export const INTERVIEW_MAEVA = {
  iframe: <iframe src="https://www.youtube.com/embed/KKWFW8Lzwo8?list=PLscl_21NqJKqbv7iBrKxwrgI_wYRaQ3I3"
    className="lazyload" title="INTERVIEW - MAEVA" allow="autoplay" allowFullScreen>
  </iframe>,
  thumbnail: MINIATURE_MAÉVA
}

export const INTERVIEW_THIAGO = {
  iframe: <iframe src="https://www.youtube.com/embed/9GQs0EgCm4k?list=PLscl_21NqJKqbv7iBrKxwrgI_wYRaQ3I3"
    className="lazyload" title="INTERVIEW - THIAGO" allow="autoplay" allowFullScreen>
  </iframe>,
  thumbnail: MINIATURE_THIAGO
}