import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import ListLinks from './listLinks';
import './drawer.scss'

export const CustomDrawer = (props) => {
  const { open, handleClose } = props
  const [subMenu, setSubMenu] = useState(null)
  const handleOpenSubMenu = (itemIndex) => setSubMenu(itemIndex)
  const handleCloseSubMenu = () => setSubMenu(null)

  return (
    <Drawer
    anchor={'right'}
    open={open}
    onClose={handleClose}
  >
    <ListLinks handleClose={handleClose} handleCloseSubMenu={handleCloseSubMenu}
      handleOpenSubMenu={handleOpenSubMenu} subMenu={subMenu} />
  </Drawer>

  );
}

export default CustomDrawer
