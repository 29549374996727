import { CircularProgress, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useSearchParams } from "react-router-dom";
import API from 'services/axios-config';
import { logo } from 'utils/imageUrls'



const Deeplinking = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState({})
    const [errors, setErrors] = useState(null)
    const [fetching, setFetching] = useState(false)
    const [passwordChanged, setPasswordChanged] = useState(false)

    const handleSubmitNewPassword = () => {
        setErrors(null)
        if (!data.password || !data.password_confirmation)
            setErrors("Veuillez remplir tout les champs")
        else if (data?.password?.length < 6)
            setErrors("Le mot de passe est trop court (minimum 6 caractères)")
        else if (data?.password !== data?.password_confirmation)
            setErrors("La confirmation de mot de passe est différente")
        else {
            setFetching(true)
            API.put('reset_password', {...data, ...Object.fromEntries([...searchParams])})
            .then(() => {
                setFetching(false)
                setPasswordChanged(true)
            })
            .catch((errors) => {
                setFetching(false)
                setErrors(errors?.response?.data || "Une erreur est survenu")
            })
        }
    }

    return (
        <div style={{ gap: "20px", flex: 1, height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "0 15px" }}>
            <img src={logo} data-src={logo} alt="logo" width={150} className="lazyload logo" />
            {passwordChanged ? <div style={{ fontSize: 14, maxWidth: 350, color: "#fff" }}>Votre mot de passe à bien été modifié, vous pouvez à present retourner sur l'application pour vous connecter</div> : 
            <div style={{borderRadius: 10, padding: 20, display: "flex", flexDirection: "column", width: "100%", maxWidth: 350, gap: 18, backgroundColor: "#fff"}}>
            <TextField
              required
              type="password"
              id="outlined-required"
              label="Nouveau mot de passe"
              name="password"
              disabled={fetching}
              onChange={(e) => setData({...data, password: e.target?.value})}
              error={!!errors}
            />
            <TextField
              required
              type="password"
              id="outlined-disabled"
              label="Confirmation de mot de passe"
              name="password_confirmation"
              disabled={fetching}
              onChange={(e) => setData({...data, password_confirmation: e.target?.value})}
              helperText={errors}
                error={!!errors}
            
            />
            <button 
                onClick={() => handleSubmitNewPassword()} 
                disabled={fetching}
                style={{ border: "none", backgroundColor: "#60EED2", borderRadius: 5, padding: "10px 5px", textAlign: "center", color: "#fff", cursor: "pointer" }}>
                    {!fetching ? "Changer le mot de passe" : <CircularProgress />}
            </button>
            </div>
            }
        </div>
    )
}

export default Deeplinking