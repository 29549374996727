import { ParentContainer } from 'components/ParentContainer/parentContainer';
import { FiArrowUpRight } from 'react-icons/fi'
import { Link, useNavigate } from 'react-router-dom';
import { APP_STORE_LINK, HOME_USE_CASE, PLAY_STORE_LINK } from 'routes/vitrineRoutes';
import { APP_STORE, GOOGLE_PLAY } from 'utils/imageUrls';
import './buttons.scss'

export const RedirectButton = ({ text, style, className, icon, link }) => {
  return (
    <a target={'_blank'} href={link} rel="noreferrer"
      className={className} style={{ ...style }} >
      {text}
      {icon && <FiArrowUpRight className='icon' size={30} />}
    </a>
  );
}
export const InternRedirectionButton = ({ text, style, className, icon, link }) => {
  return (
    <Link to={link}
      className={className} style={{ ...style }} >
      {text}
      {icon && <FiArrowUpRight className='icon' size={30} />}
    </Link>
  );
}
export const SimpleButton = ({ text, style, className, icon, event }) => {
  return (
    <div className={className} style={{ ...style }} onClick={event} >
      {text}
      {icon && <FiArrowUpRight className='icon' size={30} />}
    </div>
  );
}

export const DownloadBtns = ({ position, padding }) => {
  return <div className='download' style={{ display: 'flex', padding: padding, justifyContent: position, gap: 50 }}>
    <a target={'_blank'} href={APP_STORE_LINK} rel="noreferrer" className='download_btn'>
      <img src={APP_STORE} alt="app-store" />
    </a>
    <a target={'_blank'} href={PLAY_STORE_LINK} rel="noreferrer" className='download_btn'>
      <img src={GOOGLE_PLAY} alt="google-play" />
    </a>
  </div>
}

export const UseCaseAction = ({ prevName, nextName, navigate }) => {
  const nextUsecase = () => {
    navigate(`${HOME_USE_CASE}/${nextName}`)
  }
  const prevUsecase = () => {
    navigate(`${HOME_USE_CASE}/${prevName}`)
  }

  return (
    <ParentContainer className='usecase_action' style={{ backgroundColor: 'white' }}>
      <div className='action-usecase'>
        {prevName && <SimpleButton event={prevUsecase}
          className={'simpleBtnWhite'} style={{ fontSize: 24 }} icon={true} text="PRÉCÉDENT"
        />}
        {nextName && <SimpleButton event={nextUsecase}
          className={'simpleBtnWhite'} style={{ fontSize: 24 }} icon={true} text="SUIVANT"
        />}
      </div>

    </ParentContainer>
  );
}
