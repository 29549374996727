import React, { useEffect, useMemo, useState } from 'react'
import { SelectSimpleComponent } from 'components/SelectSimple'
import { getAllUsecases } from '../home/functions/apiFunction'
import { problmatique, secteurActivity, solutionsName } from 'utils/dataText'
import { selectPattern } from '../home/functions/functions'
import MyMasonry from './masonry'
import './usecases.scss'

const IndexSectionUsecase = () => {
  const [useCaseList, setUseCaseList] = useState([])
  const [selectValues, setSelectValues] = useState({
    'secteurActivity': [],
    'problmatique': [],
    'serviceFFY': []
  })
  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    setIsLoaded(false)
    getAllUsecases(setUseCaseList, setIsLoaded)
  }, [])
  const filtredList = useMemo(() => {
    if (Object.values(selectValues).some(arr => arr.length > 0)) {
      return useCaseList?.filter((usecase, _) =>
        (selectValues?.problmatique?.some(_item => usecase?.current_problem?.includes(_item))) ||
        (selectValues?.secteurActivity?.some(_item => usecase?.current_activity === _item)) ||
        (selectValues?.serviceFFY?.some(_item => usecase?.current_presta?.includes(_item)))
      )
    } else {
      return useCaseList
    }
  }, [selectValues, useCaseList])

  const handleSelect = (values, type) => {
    setSelectValues((prev) => ({ ...prev, [type]: values }))
  }

  return (
    <div className='container_list_cases'  >
      <div className='selection'>
        <div className='select_input'>
          <SelectSimpleComponent items={secteurActivity.sort()} multiple={true}
            name="SECTEUR D’ACTIVITÉ" handleAction={(v) => handleSelect(v, 'secteurActivity')} />
        </div>
        <div className='select_input'>
          <SelectSimpleComponent items={problmatique} multiple={true}
            name="PROBLÉMATIQUE" handleAction={(v) => handleSelect(v, 'problmatique')} />
        </div>
        <div className='select_input'>
          <SelectSimpleComponent items={solutionsName} multiple={true}
            name="SERVICES FFY" handleAction={(v) => handleSelect(v, 'serviceFFY')} />
        </div>
      </div>
      {isLoaded &&
        <div className='content'>
          <MyMasonry filtredList={filtredList} selectPattern={selectPattern} />
        </div>}
    </div>
  )
}

export default IndexSectionUsecase


