import React from 'react'
import logo from 'assets/logo.webp'
import { ParentContainer } from 'components/ParentContainer/parentContainer'
import './introductionffy.scss'

export const IntroductionUseCase = (props) => {
  const { avatar, title, activitySector, paraph1, paraph2, ffy_style, style, padding='50px 0'} = props
  return (
    <ParentContainer className="introduction_usecase" style={{ backgroundColor: "#23272a", padding: padding }}>
      <div className='introduction_usecase'>
        <div className='introduction_usecase_logos'>
          <div className='ffylogo'>
            <img src={logo} alt="logoffy" width={100} height={100} />
          </div>
          <div style={{ borderLeft: '1px solid white', height: 60, marginLeft: 10, marginRight: 20 }}></div>
          <div className='usecase-logo'>
            <img src={avatar} alt="usecase-logo" width={100} height={100} />
          </div>
        </div>
        <div className='introduction_usecase_container'>
          <div className='introduction_usecase_content' style={ffy_style}>
            <h1 style={style} >{title} </h1>
            <h2 className='introduction_activity_sector' >{activitySector} </h2>
            <div className='paragraphs'>
              <p className='paraph1' >{paraph1} </p>
              <p className='paraph2'>{paraph2} </p>
            </div>
          </div>
        </div>
      </div>
    </ParentContainer>
  )
}