
import React from "react"
import { InternRedirectionButton, SimpleButton } from "components/Buttons/button"
import { HOME_USE_CASE } from "routes/vitrineRoutes"
import { useNavigate } from "react-router-dom"

const MyMasonry = ({ filtredList, selectPattern }) => {
  const isMobile = window.innerWidth < 500
  const navigate = useNavigate()
  return (filtredList?.map((item, index) => {
    // const encodeParams = btoa(JSON.stringify({ _id: item?.id }))
    const currentClassName = selectPattern(isMobile, index)
    return (
      <div key={`useCase-${item?.name}-${index}`} className={`${currentClassName} 
      current-${index % (isMobile ? 3 : 7)} useCase-block`}>
        <div className='information' onClick={() => navigate(`${HOME_USE_CASE}/${item?.name}`)}>
          <div className='detail'>
            <span className='one'>
              {item?.name || 'undefined'}
            </span>
            <span>
              {item?.current_presta?.join(" / ") || 'undefined'}
            </span>
          </div>
          <div className='more-info'>
            <SimpleButton text={'EN SAVOIR PLUS'} icon={true}
              className={'simpleBtnWhite'}
              // link={`${HOME_USE_CASE}/${encodeParams}?${item?.name}`}
              // link={`${HOME_USE_CASE}/${item?.id}`}
              style={{ fontSize: 14, cursor: 'pointer', padding: '7px 15px' }} />
          </div>
        </div>
        <img src={item?.cover_url} alt={item?.name} />
      </div>
    )
  }))
}

export default MyMasonry