import React from 'react'
import { ffyHomePageData } from 'globalContent/config_global_section'
import GlobalSection from 'globalContent/globalSection'
import VitrineContainer from 'views/vitrineContainer'
import IndexSectionsPoeplePage, { IntroductionPeoplePage } from './Sections/indexSectionsPeople'

const PeoplePageContainer = () => {
  return (<GlobalSection metaData={ffyHomePageData} AllSections={
    () => <VitrineContainer
      Sections={IndexSectionsPoeplePage}
      Introduction={IntroductionPeoplePage}
    />
  } />)
}


export default PeoplePageContainer