import React, { Fragment } from 'react'
import { Box } from '@mui/system'
import { Link } from 'react-router-dom'
import { Typography } from '@mui/material'
import { dataFooter } from 'utils/dataFooter'
import { ParentContainer } from 'components/ParentContainer/parentContainer'
import { CONFIDENTIAL, GENERAL_SALES_CONDITION, LEGAL_MENTION } from 'routes/vitrineRoutes'
import SocialMedia from './SocialMedia/socialMedia'
import './footer.scss'

const Footer = ({style}) => {
  return (
    <ParentContainer style={{ backgroundColor: 'white', ...style }}>
      <Box className="footer_container">
        {dataFooter?.map((section, index) => {
          return <Box key={`footer-clu-${index}`} className="column_footer">
            <Typography variant='h4' className='title'>{section?.title} </Typography>
            {section?.list?.map((item, indexj) => {
              return (<Fragment key={`footer-link-${indexj}`}>
                {section?.title !== 'Contact' ?
                  <Link to={item?.link}>
                    <Typography key={indexj} variant='p' className='text'>{item?.name}</Typography>
                  </Link>
                  :
                  <a target={'_blank'} href={item?.link} rel="noreferrer">
                    <Typography key={indexj} variant='p' className='text'>{item?.name}</Typography>
                  </a>
                }
              </Fragment>)
            })}
          </Box>
        })}
      </Box>
      <Box display='flex' justifyContent='center' >
        <SocialMedia />
      </Box>
      <Box className="footer_rights" >
        <div className='ffy'>
          <Box>© Flying For You - 2023</Box>
        </div>
        <div className='ffy_conditions'>
          <Box>
            <a target={'_blank'} href={GENERAL_SALES_CONDITION} rel="noreferrer">
              <Typography variant='p' >Conditions générales de vente</Typography>
            </a>
          </Box>
          <Box>
            <a target={'_blank'} href={CONFIDENTIAL} rel="noreferrer">
              <Typography variant='p' >Confidentialité</Typography>
            </a>
          </Box>
          <Box>
            <a target={'_blank'} href={LEGAL_MENTION} rel="noreferrer">
              <Typography variant='p' >Mentions légales</Typography>
            </a>
          </Box>
        </div>
      </Box>
    </ParentContainer>
  )
}

export default Footer