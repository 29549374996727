import {
  SFR,
  BOXY,
  HSBC,
  MCDO,
  ORPI,
  ENGIE,
  PARIS,
  CHANEL,
  DEEZER,
  POKAWA,
  ORANGE,
  EIFFAGE,
  LACTALIS,
  LA_FORET,
  ANIMALIS,
  FUN_RADIO,
  RADIOFRANCE,
  THE_BRADERY,
  FITNESS_PARK,
  TOTAL_ENERGIES,
  STEPHANE_PLAZA,
  BOUYGUES_IMMOBILIER, 
  CERGY,
  ENERTRAG,
  MASTEOS,
  REPETTO,
  WTN,
  CLICHY,
  VITAMIN_WELL,
  ORLEANS,
} from "./imageUrls";

const partnerList = [
 
  {
    name: "SFR",
    link: <img alt="SFR-logo" width={146} height={146} src={SFR} />,
  },
  {
    name: "BOXY",
    link: <img alt="BOXY-logo" width={146} height={146} src={BOXY} />,
  },
  {
    name: "HSBC",
    link: <img alt="HSBC-logo" width={146} height={146} src={HSBC} />,
  },
  {
    name: "MCDO",
    link: <img alt="MCDO-logo" width={146} height={146} src={MCDO} />,
  },
  {
    name: "ORPI",
    link: <img alt="ORPI-logo" width={146} height={146} src={ORPI} />,
  },
  {
    name: "ENGIE",
    link: <img alt="ENGIE-logo" width={146} height={146} src={ENGIE} />,
  },
  {
    name: "PARIS",
    link: <img alt="PARIS-logo" width={146} height={146} src={PARIS} />,
  },
  {
    name: "CHANEL",
    link: <img alt="CHANEL-logo" width={146} height={146} src={CHANEL} />,
  },
  {
    name: "DEEZER",
    link: <img alt="DEEZER-logo" width={146} height={146} src={DEEZER} />,
  },
  {
    name: "POKAWA",
    link: <img alt="POKAWA-logo" width={146} height={146} src={POKAWA} />,
  },
  {
    name: "ORANGE",
    link: <img alt="ORANGE-logo" width={146} height={146} src={ORANGE} />,
  },
  {
    name: "EIFFAGE",
    link: <img alt="EIFFAGE-logo" width={146} height={146} src={EIFFAGE} />,
  },
  {
    name: "LACTALIS",
    link: <img alt="LACTALIS-logo" width={146} height={146} src={LACTALIS} />,
  },
  {
    name: "LA_FORET",
    link: <img alt="LA_FORET-logo" width={146} height={146} src={LA_FORET} />,
  },
  {
    name: "ANIMALIS",
    link: <img alt="ANIMALIS-logo" width={146} height={146} src={ANIMALIS} />,
  },
 
  {
    name: "FUN_RADIO",
    link: <img alt="FUN_RADIO-logo" width={146} height={146} src={FUN_RADIO} />,
  },
  {
    name: "RADIOFRANCE",
    link: <img alt="RADIOFRANCE-logo" width={146} height={146} src={RADIOFRANCE} />,
  },
  {
    name: "THE_BRADERY",
    link: <img alt="THE_BRADERY-logo" width={146} height={146} src={THE_BRADERY} />,
  },
  {
    name: "FITNESS_PARK",
    link: <img alt="FITNESS_PARK-logo" width={146} height={146} src={FITNESS_PARK} />,
  },
  {
    name: "TOTAL_ENERGIES",
    link: <img alt="TOTAL_ENERGIES-logo" width={146} height={146} src={TOTAL_ENERGIES} />,
  },
  {
    name: "STEPHANE_PLAZA",
    link: <img alt="STEPHANE_PLAZA-logo" width={146} height={146} src={STEPHANE_PLAZA} />,
  },
  {
    name: "BOUYGUES_IMMOBILIER",
    link: <img alt="BOUYGUES_IMMOBILIER-logo" width={146} height={146} src={BOUYGUES_IMMOBILIER} />,
  },
  {
    name: "CERGY",
    link: <img alt="CERGY-logo" width={146} height={146} src={CERGY} />,
  },
  {
    name: "ENERTRAG",
    link: <img alt="ENERTRAG-logo" width={146} height={146} src={ENERTRAG} />,
  },
  {
    name: "MASTEOS",
    link: <img alt="MASTEOS-logo" width={146} height={146} src={MASTEOS} />,
  },
  {
    name: "REPETTO",
    link: <img alt="REPETTO-logo" width={146} height={146} src={REPETTO} />,
  },
  {
    name: "WTN",
    link: <img alt="WTN-logo" width={146} height={146} src={WTN} />,
  },
  {
    name: "CLICHY",
    link: <img alt="CLICHY-logo" width={146} height={146} src={CLICHY} />,
  },
  {
    name: "VITAMIN_WELL",
    link: <img alt="VITAMIN_WELL-logo" width={146} height={146} src={VITAMIN_WELL} />,
  },
  {
    name: "ORLEANS",
    link: <img alt="ORLEANS-logo" width={146} height={146} src={ORLEANS} />,
  },
]

export default partnerList