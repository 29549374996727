import React from 'react'
import { useCaseHomePageData } from 'globalContent/config_global_section'
import GlobalSection from 'globalContent/globalSection'
import VitrineContainer from 'views/vitrineContainer'
import IndexSectionUsecase from './indexSection'
import IntroductionFFY from 'components/IntroductionFFY/introductionFFY'

const style = { fontSize: 32, fontWeight: 600 }

const IndtroductionUseCase = () => <IntroductionFFY w_title={"Toutes nos"} logo={true}
  w_title2={"clients !"} g_title={"références"} ffy_style={{ padding: '40px 0' }}
  paraph2={<>Flying For You, c’est plus de 100 entreprises clientes, plusieurs dizaines de milliers
    d’euros d’investissements publicitaires sous gestion par an en France.<br /><br />
    Retrouvez nos références clients et leurs sites.</>} p_size={17}
  btn_size={25} title_type2={true} w_style={style} g_style={style}
/>

const UseCaseView = () => <GlobalSection metaData={useCaseHomePageData} AllSections={() =>
  <VitrineContainer
    hidden={true}
    Sections={IndexSectionUsecase}
    Introduction={IndtroductionUseCase}
  />} />


export default UseCaseView