import { CASE_SWYTOUCH, swy_touch1, swy_touch2, swy_touch_logo, touch_sub_image } from "utils/imageUrls";

export const usecaseData = {
  id: 9999,
  name: "SWYTOUCH",
  visible: true,
  header: {
    id: 372,
    position: 0,
    visible: true,
    name: "header",
    datas: {
      title: "SWYTOUCH",
      subTitle: <>Swytouch (anciennement Clicbubble) permet à de nombreux annonceurs de transformer leurs vidéos en vidéos intéractives en ajoutant notamment des fonctionnalités d’ajout au panier en direct.
        <br />Il souhaitait recréer leur image de marque pour être cohérente avec leur cible, créer une réelle crédibilité et retranscrire cet aspect technique de la vidéo intéractive.</>,
      description: "COMMENT CRÉER UNE NOUVELLE IDENTITÉ GRAPHIQUE POUR UNE MARQUE QUI SE VEUT ÊTRE INNOVANTE ET INCITER LE PUBLIC À L’UTILISER ?"
    },
    usecase_id: 72
  },
  video_header: {
    id: 373,
    position: 1,
    visible: null,
    name: "video_header",
    datas: null,
    usecase_id: 72
  },
  double_image: {
    id: 374,
    position: 2,
    visible: true,
    name: "double_image",
    datas: null,
    usecase_id: 72
  },
  explain: {
    id: 375,
    position: 3,
    visible: true,
    name: "explain",
    datas: {
      problem: [
        "IDENTITÉ",
        "LANCEMENT"
      ],
      solutions: [
        {
          title: "CRÉATION GRAPHIQUE",
          list: [
            "CHARTE GRAPHIQUE",
            "PLAQUETTE COMMERCIALE",
            "SIGNATURE DE MAIL",
          ]
        }
      ]
    },
    usecase_id: 72
  },
  special_explain: {
    id: 375,
    position: 6,
    visible: true,
    name: "special_explain",
    datas: {
      solutions: [
        {
          title: 'PLAQUETTE COMMERCIALE',
          list: [
            'UTILISATION DE LA NOUVELLE CHARTE SUR UN SUPPORT DE VENTE.'
          ]
        },
        {
          title: "SIGNATURE DE MAIL ",
          list: [
            "CRÉATION D’UNE SIGNATURE DE MAIL POUR ACCENTUER LA NOUVELLE IDENTITÉ."
          ]
        }
      ]
    },
    usecase_id: 72
  },
  sub_image: {
    id: 376,
    position: 5,
    visible: true,
    name: "sub_image",
    datas: null,
    usecase_id: 72
  },
  campaignStep: {
    id: 376,
    position: 4,
    visible: true,
    name: "campaignStep",
    datas: null,
    usecase_id: 72
  },
  commentary: {
    id: 378,
    position: 7,
    visible: true,
    name: "commentary",
    datas: {
      description: "“Équipe très sérieuse, je referais appel à eux en cas de besoin.”"
    },
    usecase_id: 72
  },
  size: null,
  cover_url: CASE_SWYTOUCH,
  file1_url: swy_touch1,
  file2_url: swy_touch2,
  sub_image_url: touch_sub_image,
  commentary_url: swy_touch_logo,
  header_url: swy_touch_logo,
  prev_id: 71,
  next_id: 73
}

export const specialCover = {
  cover_url: CASE_SWYTOUCH,
  current_activity: "INNOVATION",
  current_presta: ['CRÉATION GRAPHIQUE'],
  current_problem: ['IDENTITÉ, LANCEMENT'],
  id: 9999,
  name: "SWYTOUCH",
  size: null,
  visible: true,
}