import { InternRedirectionButton } from 'components/Buttons/button'
import './style.scss'
import Footer from 'components/Footer/footer'
import { useEffect, useState } from 'react';

const Cgv = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    
      useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }
        
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        }
      }, []);

      const zoom = windowSize?.width < 1200 ? windowSize?.width / 1200 : 1;
  return (
    <div id='merci-devis' style={{ zoom: zoom, transformOrigin: 'top left' }}>
    <div style={{ flex: 1, margin: "40px auto", maxWidth: 1440, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "20px 0px 0px", gap: "30px" }}>
        <InternRedirectionButton text={"Accueil"} style={{ fontSize: 18, alignSelf: "start", marginLeft: 20 }} className={'redirectBtnWhite'} link={"/"} />
        <div className="condition-page">
      <div className="page-content">
        <div className="sub-title-conditions">Conditions Générales de Vente</div>
        <p>Les présentes Conditions Générales de Vente sont à jour au 01 Janvier 2022.</p>


        <div className="1 definitions">
          <div className="sub-title-conditions">1. DÉFINITIONS</div>
          <p>1.1 - Les présentes Conditions Générales de Vente (ci-après “CGV”) sont proposées par la Société FLYING FOR YOU (ci-après la “Société”), SASU, inscrite au Registre du commerce et des Sociétés de Asnières-sur-Seine sous le numéro 845050517, représentée par Cheikh Kane, dont le siège social est fixé au 3 Rue Olympe de Gouges, 92600 Asnières-sur-Seine, France.</p>

          <p>1.2 - Son numéro de téléphone non surtaxé est le +33 6 12 42 25 29, et son adresse électronique est hello@flyingforyou.fr.</p>

          <p>1.3 - La Société est propriétaire et éditeur du Site internet https://www.flyingforyou.fr/ (ci-après le “Site”). Le Site est hébergé par OVH, SAS, domiciliée au 2 Rue Kellermann, 59100 Roubaix, France, joignable au 06 12 42 25 29. Le directeur de la publication est Cheikh Kane.</p>

          <p>1.4 - Le Site propose au Client (ci-après le “Client”) la possibilité de remplir un devis pour des Services de Street Marketing, de Marketing Digital et de création graphique (ci-après les “Services”).</p>

          <p>1.5 - La dénomination d’Affichage Sauvage/Urbain (ci-après “Affichage”) désigne toute prestation d’Affichage de campagne de publicité pour des marques, enseignes, services et produits commercialisée et autre(s) réalisée par la Société pour le compte d’Annonceurs ou de leurs mandataires. <br />
            Est désigné Annonceur, les personnes physiques ou morales (sociétés, associations, administrations, établissements publics et/ou parapublics et autres entités) faisant de la publicité par Affichage pour des marques, enseignes, services ou produits, ou réalisant des campagnes de communication (ci-après “Annonceur”) <br />
            Les personnes morales ou sociétés du groupe du Mandataire dont le capital est détenu majoritairement par une même entité mère et à l’exclusion de sociétés sous mandataires, agissant au nom et pour le compte de l’Annonceur et dûment mandatées dans le cadre d’un contrat de mandat écrit et signé (ci-après “Mandataire”).
          </p>

          <p>1.6 - Les emplacements liés à l’Affichage tels qu’ils ont été convenus entre les Parties (ci-après “Emplacement”) sont discutés entre la Société et l’Annonceur en amont. L’Ordre d’Affichage désigne les demandes d’Affichage confiées à la Société conformément aux bons de commande validés, aux devis acceptés ou à tout document contractuel engageant les Parties (ci-après “Ordre d’Affichage”).</p>

          <p>1.7 -  Avant toute utilisation du Site, le Client doit s’assurer qu’il dispose des moyens techniques et informatiques lui permettant d’utiliser le Site et que son navigateur permet un accès sécurisé au Site. Le Client doit également s’assurer que la configuration informatique de son matériel/équipement est en bon état et ne contient pas de virus.</p>
        </div>

        <div className="2 application-opposabilite-cgv">
          <div className="sub-title-conditions">2. APPLICATION ET OPPOSABILITÉ DES CGV</div>
          <p>2.1 - Les présentes CGV ont pour objet de définir l’ensemble des conditions dans lesquelles la Société commercialise les Services tels que proposés sur le Site aux Clients. Elles s’appliquent donc à tout devis signé par le Client.</p>

          <p>2.2 - Le Client déclare avoir pris connaissance et accepté les présentes CGV avant la signature du devis.</p>

          <p>2.3 - La validation du devis vaut donc acceptation de ces CGV. Celles-ci sont régulièrement mises à jour, les CGV applicables sont celles en vigueur sur le devis à la date de la signature.</p>

          <p>2.4 - Toute condition contraire posée par le Client serait donc, à défaut d’acceptation expresse, inopposable à la Société quel que soit le moment où elle aura pu être portée à sa connaissance.</p>

          <p>2.5 - Le fait que la Société ne se prévale pas à un moment donné d’une quelconque disposition des présentes CGV ne peut être interprété comme valant renonciation à se prévaloir ultérieurement d’une quelconque disposition desdites CGV.</p>

          <p>2.6 - L’Annonceur bénéficie des dispositions des présentes CGV seulement en son nom personnel. Il ne peut les utiliser que pour lui-même, ses produits, ses services ou les articles vendus sous sa marque et nommément désignés sur la demande d’Affichage.</p>

          <p>2.7 - Toute signature d’un ordre ou d’une demande d’Affichage par l’Annonceur ou son Mandataire dûment mandaté par écrit, agissant en son nom et pour son compte, emporte acceptation entière et sans réserve des présentes CGV nonobstant les conditions figurant notamment dans les conditions générales d’achat, bons de Commandes et/ou tout autre document émis par le Client.</p>
        </div>

        <div className="3 modalites-street-marketing">
          <div className="sub-title-conditions">3. MODALITÉS D’ÉXÉCUTION STREET MARKETING</div>
          <p>3.1 - Seuls les documents à distribuer d’un format compatible avec celui des Boîtes-Aux-Lettres (ci-après “BAL”) normalisées peuvent faire l’objet d’une distribution.</p>

          <p>3.2 - Les documents seront livrés à la Société ou chez le Client en accord avec les deux parties.</p>

          <p>3.3 - En cas de retard de livraison ou de conditionnement défectueux :</p>
          <p className="liste">- Les délais contractuels de distribution ne seront pas opposables à la Société qui pourra refuser de distribuer. La Société ne sera redevable d’aucune pénalité au titre de l’absence de distribution ou du retard affectant la distribution.</p>
          <p className="liste">- Un retard de livraison est considéré comme tel après un jour ouvrés de la date initiale de livraison.</p>
          <p className="liste">- Si la Société acceptait d’effectuer la distribution malgré le retard de livraison ou le conditionnement défectueux et selon les nouvelles modalités de distribution agrées par écrit avec le Client, des frais supplémentaires pourront être appliqués en plus du prix de la distribution agréée.</p>

          <p>3.4 - Si les documents parvenaient à la Société plus de 20 jours ouvrés avant la date de début de distribution agréée, ou devaient y être exposés par la Société pour des raisons indépendantes de sa volonté (livraison tardive, conditionnement défectueux ou non-conforme, distribution différée ou annulée par le Client, documents en suppléments…) la Société facturera au Client des frais supplémentaires pour le stockage des documents.</p>

          <p>3.5 - Lors de l’impression des supports de communication, la Société s’engage à fournir au Client un gabarit ainsi que des instructions au format PDF afin que le fichier soit conforme. Si le Client fournit un fichier ne correspondant pas aux instructions et aux différentes caractéristiques définies dans ces dernières, la Société proposera la reprise graphique du support moyennant une compensation financière considérée comme une nouvelle prestation de design.</p>

          <p>3.6 - La Société et le Client définissent pour chaque campagne la/les zone(s) de distribution. La Société informe le Client que la réalité opérationnelle peut parfois entraîner la distribution des documents dans les zones de distribution limitrophes aux zones de distribution définies avec le Client.</p>

          <p>3.7 - La Société distribuera les documents confiés, à compter de la date de début de distribution mentionnée sur le Bon de Commande, dans un délai de 5 jours (base de 10 000 documents, les délais peuvent varier en fonction de la quantité), sauf intempéries, cas de force majeure, retard de livraison, nécessité de recompter et/ou reconditionner les documents livrés.</p>

          <p>3.8 - La distribution des documents s’effectuera dans les BAL recensées par la Société.</p>

          <p>3.9 - La Société tiendra en compte, lors de chaque prestation, des éventuelles restrictions de distribution mentionnées sur les BAL (Stop Pub). Sa responsabilité ne saurait être mise en cause de quelque manière que ce soit si des documents étaient retrouvés dans les BAL comportant une restriction de distribution. la Société décline toute responsabilités en cas de plainte d’un tiers ayant reçu un imprimé publicitaire dans sa BAL.</p>

          <p>3.10 - Les Emplacements d’Affichage, en nombre comme en qualité et localisation, sont fonction des autorisations qui sont obtenues par l’Annonceur ou son Mandataire, des libertés consenties par les autorités municipales et/ou préfectorales (dans le cadre des festivals notamment) et des conditions d’accessibilité des lieux choisis selon les dates des prestations convenues. En l’absence de lieu d’Affichage privé et/ou réservé, les Emplacements sont définis en commun accord avec l’Annonceur ou son Mandataire par zone(s) d’Affichage ainsi que par nombre de matériels à afficher dans le périmètre de la ou des zone(s) convenue(s). Aucun lieu fixe n’est à ce titre garanti.</p>

          <p>3.11 - La Société s’engage à mettre en œuvre tous les moyens dont elle dispose pour réaliser un Affichage correspondant au plus près aux demandes et souhaits de l’Annonceur tels que matérialisés dans l’Ordre d’Affichage. Dans l’hypothèse de l’utilisation par un tiers comme de l’inaccessibilité d’Emplacements préalablement envisagés, la Société s’engage à mettre tout en œuvre pour trouver, dans la ou les zone(s) convenue(s), des Emplacements de substitution permettant d’assurer une diffusion équivalente en nombre et qualité du matériel confié. <br />
            Les affiches de produits concurrents ou similaires pourront figurer sur des emplacements, faces voisines ou côte à côte. La Société s’efforcera toutefois dans la mesure du possible de les séparer visuellement.
          </p>

          <p>3.12 - Dans le cadre de l’exécution de ses obligations contractuelles, la Société se réserve le droit de supprimer ou de déplacer une ou plusieurs affiches, notamment dans le cas où la publicité serait contraire aux intérêts légitimes des commerçants et/ou habitants et ce, sans diminution du prix de l’Affichage.</p>

          <p>3.13 - Si le matériel relatif à l’Affichage ou au vélo publicitaire devait être déposé en totalité ou en partie à cause de la publicité elle-même ou de l’Affichage (suite notamment à une mise en application des réglementations locales ou à une injonction des autorités compétentes) alors, le prix de la campagne d’Affichage serait exigé dans sa totalité. L’intervention supplémentaire de dépose et/ou d’arrêt de l’Affichage serait facturée en sus à l’Annonceur ou au Mandataire à concurrence de 30% du montant de l’ordre d’Affichage.</p>

          <p>3.14 - L’Annonceur ou son Mandataire doit présenter à la Société, pour approbation 15 jours au moins avant exécution de l’Affichage et/ou de la distribution, une maquette ou un prototype reprenant le texte, la forme, les couleurs, les graphismes, les sigles et les dessins de la publicité définitive envisagée. Conformément à l’Article 3, la Société se réserve le droit de refuser la maquette ou d’exiger les modifications que le Mandataire ou l’Annonceur s’oblige à exécuter à ses frais. Le matériel final, tel qu’accepté par la Société devra être remis à celle-ci, selon les modalités prévues au devis accepté ou bon de commande et en tout état de cause dans un délai raisonnable permettant de réaliser l’Affichage.</p>

          <p>3.15 - Si l’Annonceur ou le Mandataire ne remet pas dans les délais fixés les documents, éléments, matériels ou dispositifs nécessaires à la bonne exécution de l’Affichage ou la campagne de vélo publicitaire où si ceux-ci ne sont pas conformes aux mentions figurant sur le bon de commande et le devis accepté, l’Annonceur ou son Mandataire restera tenu au règlement de l’intégralité de l’Affichage mentionné sur le bon et/ou devis accepté. En outre, la Société ne pourra pas dans ces conditions être tenue pour responsable de la mauvaise exécution de la prestation d’Affichage ou la campagne de vélo publicitaire?</p>

          <p>3.16 - La Société réalise les opérations d’Affichage à compter de la date de début des prestations telle que convenue entre les Parties mais n’a aucunement pour obligation de faire en sorte que chaque affiche soit maintenue en son emplacement pendant une certaine durée. L’engagement contractuel de la Société consiste à garantir à l’Annonceur ou à son Mandataire, et ce quelles que soient les circonstances normales d’exécution, un minimum de pose et/ou de distribution dans le délai contractuel équivalent à 95% du matériel confié par l’Annonceur ou son Mandataire.<br />
            Les 5% de tolérance dûment constatés correspondant à la passe négative inhérente aux aléas normaux de l’activité (conditions atmosphériques endommageant certains matériels, lieux d’Affichage inaccessibles, etc.). Dès lors, aucune réclamation de la part de l’Annonceur ou de son Mandataire ne saurait être reconnue recevable pour une campagne qui aurait au moins 95% de matériels affichés et/ou distribués. De même, la Société ne saurait facturer à l’Annonceur ou son Mandataire, un supplément de campagne au titre de la passe positive réellement affichée et/ou distribuée.
          </p>
        </div>

        <div className="4 modalites-digital-design">
          <div className="sub-title-conditions">4. MODALITÉS D’ÉXÉCUTION DIGITAL ET DESIGN</div>
          <p>4.1 - La prestation s’effectue en collaboration avec le Client et dépend de sa coopération pleine et entière. Le Client s’engage à fournir tous les éléments nécessaires à la réalisation de la prestation commandé dès le lancement des travaux. Tout élément fourni par le Client sera considéré comme définitif par la Société. Ainsi la Société peut refuser d’intégrer de nouveaux éléments (ou des éléments modifiés) dans la prestation commandée lorsque ceux-ci ne sont pas fournis dès le lancement des travaux. Ainsi, dès lors qu’un élément (texte, photo, vidéo, logo, lien…) est fourni il sera exploité tel quel par la Société sans qu’aucune modification ne soit apporté sur celui-ci.</p>

          <p>4.2 - Modifications sur les réalisations graphiques <br /><br />
            Si le Client commande une prestation forfaitaire incluant une création graphique, et sauf disposition contraire dans le Cahier des Charges, le Client a le droit de demander plusieurs modifications sur les travaux graphiques qui lui sont soumis :</p>
          <ul>
            <li><p className="liste-puce">3 allers-retours sur les créations de : </p>
              <ul>
                <li><p className="liste-puce">Maquette site internet</p></li>
                <li><p className="liste-puce">Flyers et autres supports prints</p></li>
              </ul>
            </li>
            <li> <p className="liste-puce">5 allers-retours sur les créations de :</p>
              <ul>
                <li><p className="liste-puce">Chartes Graphiques</p></li>
              </ul>
            </li>
          </ul>
          <p>Au-delà, si le Client souhaite faire apporter plus de modifications aux travaux graphiques qui lui ont étés soumis, les travaux supplémentaires seront facturés au taux horaire en vigueur. Le Client a 8 jours ouvrés pour effectuer une demande de modification sur chaque proposition graphique. Sans retour de sa part avant ce délai, la création graphique est automatiquement considérée comme validée. Si le Client s’absente et ne peut pas valider les travaux, il doit le signifier par écrit à la Société afin que soit ajouté un délai supplémentaire à la période de validation.</p>

          <p>4.3 - Livrables <br />
            <br />
            Compte tenu de la nature complexe de la prestation, compte tenu que les délais d’exécution de la prestation dépendent en grande partie de la disponibilité et de la réactivité du Client, compte tenu que la prestation est une prestation sur mesure et unique, les délais de livraison ne sont pas rigoureux ; ils ne sont donnés qu’à titre indicatif. La Société ne peut être tenu responsable d’un quelconque retard qui ne peut être invoqué pour refuser la prestation ou exiger une indemnité.<br /> <br />
            Lorsque le Client ne transmet pas dans les délais convenus les éléments nécessaires à la bonne réalisation de la prestation (textes, logos, images, instructions, etc.), ce fait a pour conséquence directe de retarder la réalisation et donc la livraison de la prestation par la Société.<br />
            <br />
            La livraison d’une prestation se matérialise par son exécution finale (lancement d’un site, mise en ligne, mise en production de sources, envoi d’une newsletter, impression, intégration, exécution d’un script ou d’une commande ou toute autre prestation). Cette livraison (exécution finale) est précédée d’une période permettant au Client de contrôler les travaux fournis (phase de recette ou de contrôle).<br />
            <br />
            Le Client demande que soit effectuée l’exécution finale de la prestation par une demande écrite lorsqu’il considère que les travaux sont conformes à sa demande.<br />
            <br />
            Le fait de demander l’exécution finale des travaux vaut donc validation de la prestation par le Client.Il se peut toutefois dans le cadre de prestations urgentes, courantes, habituelles ou mineures que la livraison se fasse sans validation expresse du Client dans la mesure où :
            <ul>
              <li><p className="liste-puce">le Client avait demandé la réalisation de la prestation</p></li>
              <li><p className="liste-puce">le Client a eu connaissance de son exécution imminente.</p></li>
            </ul>
            <br />
            A compter du règlement final, le Client disposera d’un délai de 30 jours pour analyser les résultats de la prestation obtenue en exploitation réelle par rapport aux spécifications et aux performances attendues. <br />
            <br />
            Dès que ce contrôle est satisfaisant pendant plus de 7 jours consécutifs, la Recette est considérée comme acceptée, si elle n’est pas dénoncée par le Client pendant ce délai.<br />
            <br />
            Tous travaux engagés par la Société sur des réclamations faites par le Client qui s’avèrent être infondés, inexistantes ou mal formulés seront facturés au Client au tarif horaire en vigueur.<br />
            <br />
            En tout état de cause, la garantie d’une prestation ne concerne que les éléments mentionnés dans les éléments contractuels d’une prestation forfaitaire, et ne couvre pas les prestations devisés ou facturés à l’heure ou à la journée travaillé.<br />
            <br />
            En particulier, en cas de réclamation sur une prestation forfaitaire et essentiellement immatérielle (site Internet, référencement naturel, référencement payant, création graphique) le devis reste le document de référence auquel les deux parties doivent se référer pour évaluer si la prestation est conforme aux spécificités du projet. Seuls les éléments mentionnés dans ce document sont considérés comme relevant des obligations de la Société.<br />
            <br />
            A défaut de présentation du devis, les obligations de la Société sont strictement limitées à une obligation de moyens, soit à la matérialisation d’un travail fourni équivalent au montant payé pour la prestation. Dans ce cas, le Client ne peut se prévaloir que d’une absence de réalisation de la prestation.
          </p>
        </div>

        <div className="5 reglementation-affichage">
          <div className="sub-title-conditions">5. RAPPEL DE LA RÈGLEMENTATION SUR L’AFFICHAGE</div>
          <p>5.1 - Pour les durées et dates convenues telles qu’elles sont décrites sur l’ordre d’Affichage, la Société s’engage, selon l’accord des Parties, à afficher aux Emplacements les matériels qui lui sont communiqués par l’Annonceur ou son Mandataire.</p>
          <p> 5.2 - Au regard des Emplacements d’Affichage, l’Annonceur ou son Mandataire reconnaît que préalablement à tout engagement avec la Société, il a été informé que l’Affichage sur la voie publique, à des fins notamment publicitaires, est réglementé en France pour l’essentiel par la loi n° 79-1150 du 29 décembre 1979, relative à la publicité, aux enseignes et pré enseignes qui est intégrée dans le Code de l’environnement. <br />
            Les dispositions utiles dont l’Annonceur ou son Mandataire a pris connaissance avant tout engagement contractuel se trouvent plus particulièrement aux articles L.581 et suivants du Code de l’environnement.
          </p>
          <p>5.3 - L’Annonceur ou son Mandataire reconnaît qu’il a pris connaissance de la législation applicable et connaît la réglementation limitant sinon interdisant la publicité dite sauvage. La Société entend rappeler que la législation prévoit un dispositif administratif et pénal pour réprimer l’Affichage sauvage dans les villes, qu’il soit publicitaire ou commercial et quel qu’en soit le contenu, dès lors qu’il ne se situe pas dans une zone de publicité restreinte prévue par la réglementation. En cas d’absence de déclaration préalable, l’Article L. 581-29 du code de l’environnement donne pouvoir au maire ou au préfet de faire procéder d’office à la suppression immédiate de cette publicité, sous réserve, dans le cas où elle se trouve dans une propriété privée, de la demande du propriétaire ou de son information préalable. <br />
            Les frais d’exécution d’office sont supportés par la personne pour laquelle la publicité a été réalisée. À ces mesures de police et sanctions administratives, le code de l’environnement ajoute des sanctions pénales dont la mise en œuvre est laissée à l’appréciation du procureur de la République, au vu des procès-verbaux établis par des agents habilités. Enfin, l’Article L. 581-34 du Code de l’environnement sanctionne d’une amende délictuelle l’Affichage sauvage en des lieux interdits, ainsi que l’absence de déclaration voire d’autorisation préalable. Aux termes dudit article, l’amende délictuelle est applicable autant de fois qu’il existe de publicité en infraction. Ces textes sont rappelés ici de manière non exhaustive et il revient à l’Annonceur de se reporter au Code ainsi qu’aux mesures légales comme réglementaires qui peuvent en modifier ou préciser les dispositions.
          </p>
        </div>

        <div className="6 limitations-responsabilites">
          <div className="sub-title-conditions">6. LIMITATIONS DE RESPONSABILITÉS</div>
          <p>6.1 - La Société n’est tenue à l’égard du Client qu’à une obligation de moyens pour l’exécution des prestations. La Société sera responsable uniquement des dommages matériels directs que pourront subir le Client ou tout tiers résultant d’une défaillance contractuelle exclusivement imputable à la Société. La Société n’accorde aucune garantie quant aux retombées commerciales attendues par le Client. En tout état de cause, la responsabilité pécuniaire de la Société n’excèdera pas le montant exclusif de la/des prestation(s) contestée(s) facturée(s) au Client.</p>

          <p>6.2 - Le Client reconnaît détenir tous les droits de propriété intellectuelle des documents et déclare en assumer l’entière responsabilité. Il certifie que ces documents sont conformes aux lois et règlements en vigueur et respectent les droits des tiers. Il relève de la Société, ses sous-traitants et fournisseurs de toutes responsabilités, les garantit contre toutes condamnations, frais judiciaires et extra-judiciaires qui résulteraient de tout recours en relation avec le contenu des documents et les indemnise pour tout dommage résultant de violation des dispositions de cet article.</p>

          <p>6.3 - La Société pourra :</p>
          <ul>
            <li><p className="liste-puce">Refuser toute campagne d’un Client n’ayant pas respecté les CGV lors d’une précédente relation commerciale avec la Société (défaut paiement…).</p></li>
            <li><p className="liste-puce">Refuser toute prestation :</p>
              <ul>
                <li><p className="liste-puce">Incompatible avec son organisation ou de produits dangereux pouvant se dégrader pendant le temps de transport ou détériorer les BAL et/ou leur contenu.</p></li>
                <li><p className="liste-puce">De documents, objets, échantillons non conformes aux lois, règlement, bonnes moeurs, pornographiques, diffamatoires ou jugés tels par toute juridiction ou une autorité légale, judiciaire ou administrative aurait proscrit la prestation, sans que cela crée à la charge de la Société une obligation de vérifier les documents.</p></li>
              </ul>
            </li>
            <li><p className="liste-puce">Interrompre de telles campagnes programmées et refuser tout Bon de Commande non conforme aux tarifs en vigueur, même payé, moyennant restitution du prix sous réserve d’en informer par écrit le Client avant la date agréée d’enlèvement ou de livraison des documents, la Société ne fera naître au profil du Client aucun droit à l’indemnité et ou frais déjà exposés par la Société seront dus par le Client.</p></li>
          </ul>

          <p>6.4 - La Société décline toute responsabilité en cas de réclamation du Client dans les hypothèses suivantes :</p>
          <ul>
            <li><p className="liste-puce">Absence de BAL</p></li>
            <li><p className="liste-puce">BAL incompatibles avec le format ou le volume des documents</p></li>
            <li><p className="liste-puce">Documents distribués enlevés par des tiers</p></li>
            <li><p className="liste-puce">Refus par les destinataires d’accepter les documents (Stop pub, gardien, etc.)</p></li>
            <li><p className="liste-puce">Habitations ne comportant pas de BAL fermées individuelles, ayant seulement une boîte commune pour plusieurs foyers</p></li>
            <li><p className="liste-puce">Habitations temporaires, accès impossible, lieux dangereux (chiens, animaux dangereux…)</p></li>
            <li><p className="liste-puce">Difficultés d’accessibilité aux BAL en zones urbaines (défaut badges…)</p></li>
          </ul>

          <p>6.5 - La Société, ses sous-traitants et fournisseurs ne seront pas responsables des manquements à leurs obligations qui résulteraient directement ou indirectement de cas de force majeure reconnus par la réglementation et les juridictions françaises (y inclus notamment incendies, inondations, grèves des transports en commun, du personnel…, lock-out, épidémies, conditions climatiques exceptionnelles, piratage informatique, coupure d’alimentation électrique de plus de 48h…) ou d’un événement échappant à leur contrôle raisonnable. Dans ce cas, le Bon de Commande sera suspendu et les dates de début et de fin de campagne seront prolongées d’une durée équivalente à celle de l’événement sans versement au Client d’indemnités. Si le cas de force majeure se prolongeait plus d’un mois, le Bon de Commande pourra être résilié de plein droit avec effet immédiat par la Société ou le Client sans versement d'indemnités. Il en sera de même pour toute prestation dont le déroulement sera affecté par l’intervention d’une autorité légale, judiciaire ou administrative.</p>

          <p>6.6 - Toute BAL ne comportant pas la mention claire et explicite “STOP PUB” ne sera pas considérée comme telle. La Société décline toute responsabilité en cas de problème juridique ou réclamation Client qui arriverait à l’issue de la campagne.</p>

          <p>6.7 - Lors de campagnes d’affichage sauvage, la Société décline toute responsabilité en cas de problème juridique qui arriverait à l’issue de la campagne. Lors de la signature du devis, le Client certifie avoir pris conscience des amendes pouvant être reçues à la suite de la campagne. La totalité des amendes sont à la charge du Client.</p>

          <p>6.8 - Les visuels, textes, annonces et matériels, objet des campagnes d’Affichage sont diffusées sous la seule responsabilité de l’Annonceur ou du Mandataire. Ceux-ci doivent être conformes à la réglementation en vigueur en France.</p>

          <p>6.9 - La Société ou son Mandataire garantit qu’il dispose pour les matériels transmis pour l’Affichage de tous les droits nécessaires à l’Affichage desdits matériels et se porte garant pour la Société de toutes les réclamations et/ou condamnations quels qu’en soient les motifs à cet égard. <br />
            Dans ce cadre, la responsabilité de la Société ne saurait être recherchée, à quelque titre que ce soit. Dans l’hypothèse de poursuites, l’Annonceur ou son Mandataire sera tenu de garantir la Société de toute condamnation pouvant être prononcée à son encontre. En outre, et en cas de poursuites, la Société se réserve le droit de demander à l’Annonceur ou à son Mandataire des dommages et intérêts pour le préjudice subi.
          </p>
          <p>6.10 - La Société n’est pas responsable des résultats de la campagne d’Affichage. Elle ne saurait à ce titre se voir demander de compenser une perte de profits ou un manque à gagner. L’exclusion de responsabilité ne s’applique pas si la Société a négligé un devoir contractuel essentiel. L’obligation de dédommagement de la Société dans ce cas est cependant limitée au dommage typique et prévisible prévu par le contrat dans les limites du prix total facturé au Contrat.</p>
          <p>6.11 - Tous les matériels qui sont remis à la Société Affichage sont traités avec soin. La Société ne saurait être tenu responsable, en cas de dommage ou de perte de ceux-ci, qu’à hauteur de leur seul valeur matérielle. D’autres réclamations sont exclues.</p>
          <p>6.12 - Un contrôle contradictoire du bon peut être effectué conjointement par l’Annonceur ou le Mandataire et la Société.</p>
          <p>6.13 - Le défaut d’Affichage défini conformément aux dispositions des présentes Conditions Générales donnera droit, le cas échéant, à une compensation sans que les anomalies identifiées puissent entraîner de la part de l’Annonceur ou du Mandataire une retenue partielle ; un retard dans le paiement de la commande, la résiliation partielle ou totale de l’ordre ou une demande de dommages et intérêts.</p>
          <p>6.14 - Toute anomalie mise en exergue par un contrôle et qui résulterait du non-respect par l’Annonceur ou le Mandataire des dispositions prévues à son égard dans les présentes Conditions Générales de Ventes, ne pourra donner lieu à aucune réclamation ou indemnisation.</p>
        </div>

        <div className="7 prix-street-marketing">
          <div className="sub-title-conditions">7. PRIX ET CONDITIONS DE PAIEMENT STREET MARKETING</div>
          <p>7.1 - La Société communiquera ses tarifs sur demande du Client. Le Client devra régler 30% du montant total de la prestation sur la base des tarifs en vigueur au jour de la signature du devis puis les 70% restants dans un délai de 21 jours après la fin de la prestation désignée par la Société.  <br />
            Les prix sont en Euros Hors Taxes et sont établis en fonction des quantités et du poids des documents, des zones de distribution du type de prestation commandée, de l’engagement en termes de volume de et/ou de toute autre taxe à la charge du Client, aux taxes en vigueur à la date d’émission de l’émission de la facture.
          </p>
          <p>7.2 - L’Ordre de prestation doit être matérialisé :
            <ul>
              <li><p className="liste-puce">Soit par un bon de commande signé par l’Annonceur;</p></li>
              <li><p className="liste-puce">Soit par un document daté et signé, émanant de l’Annonceur ou de son Mandataire ayant adhéré aux présentes conditions générales de vente</p></li>
              <li><p className="liste-puce">Soit par un devis émanant de la Société, signé par l’Annonceur</p></li>
            </ul>
            Ce bon de commande, ce document ou ce devis indiquera clairement :
            <ul>
              <li><p className="liste-puce">Le nom de l’Annonceur à qui la facture doit être adressée </p></li>
              <li><p className="liste-puce">Le cas échéant, le nom de l’Annonceur pour le compte de qui la campagne est exécutée </p></li>
              <li><p className="liste-puce">La nature précise, le(s) lieu(x) des prestations demandées et les dates d’exécution de celles-ci </p></li>
            </ul>
            Pour entraîner valablement la conclusion d’un contrat engageant les Parties :
            <ul>
              <li><p className="liste-puce">Le bon de commande devra être signé par toutes les Parties </p></li>
              <li><p className="liste-puce">Soit le document émanant de l’Annonceur ou de son Mandataire devra être validé et signé par la Société </p></li>
              <li><p className="liste-puce">Soit le devis devra être accepté et signé par l’Annonceur ou son Mandataire </p></li>
            </ul>
          </p>
          <p>7.3 - Sauf dispositions particulières prévues et mentionnées aux factures, le montant des factures est toujours exigible au siège de la Société (la Société ne renonçant nullement à ce droit lorsqu’elle établit une traite sur l’acheteur). La Société a seule qualité pour percevoir le montant des factures qu’elle a établies.</p>
          <p>7.4 - Les prestations de la Société sont faites aux conditions tarifaires et taxes en vigueur au jour de la signature de l’Ordre d’Affichage par l’Annonceur ou le Mandataire.</p>
          <p>7.5 - Les tarifs sont établis hors taxes et la TVA sera comptée en sus au taux en vigueur ainsi que tout nouvel impôt ou taxe qui pourrait devenir applicable à la charge de l’Annonceur ou du Mandataire.</p>
          <p>7.6 - Sont facturés en sus des prestations contractuelles convenues entre les Parties, les frais occasionnés par des demandes spécifiques qui seraient formulées postérieurement à l’Ordre d’Affichage ou de distribution à l’initiative de l’Annonceur ou du Mandataire dont notamment mais non exclusivement le recouvrement des affiches, le complément ou changement des matériels en cours de conservation, les frais liés à des opérations spéciales, ainsi que les frais occasionnés par le retard de livraison des matériels.</p>
          <p>7.7 - L’exécution d’une commande à un prix convenu pour des raisons spécifiques n’oblige pas la Société à exécuter les commandes ultérieures aux mêmes conditions.</p>
          <p>7.8 - Les factures sont émises au nom de l’Annonceur ou du Mandataire en fonction de l’ordre. Dans le cas où l’Annonceur fait appel à un Mandataire, la facture mentionnera, le cas échéant et selon les indications portées sur l’attestation du mandat, que l’Annonceur est représenté par ce Mandataire.</p>
          <p>7.9 - Compte tenu du nombre de possibilités de personnalisation des prestations, les prix au détail des diverses prestations disponibles ne figure pas dans les présentes Conditions Générales de Vente. <br />
            Notre site internet n’étant pas un site de commerce en ligne, il est établi un Devis ou un Bon de Commande préalable à chacune des commandes que l’Acheteur potentiel souhaiterait passer avec la Société, le Devis et le Bon de Commande mentionnant expressément le prix au détail des articles et prestations choisis.
          </p>
          <p>7.10 - Les réductions de prix et promotions éventuelles sont indiquées de même sur le Devis transmis pour acceptation comme sur le Bon de Commande.</p>
          <p>7.11 - Sauf Conditions Particulières mentionnant des modalités de paiement différentes, les prestations sont payées comptant à réception de la facture. Aucune remise pour paiement comptant et/ou anticipé n’est accordée.</p>
          <p>7.12 - En cas de défaillance d’un Mandataire titulaire d’une attestation de mandat et même si le Mandataire est mandataire payeur, la Société pourra poursuivre directement le recouvrement des créances dues, auprès de l’Annonceur. Sauf accord particulier, les ventes à l’étranger doivent faire l’objet d’un paiement comptant en Euro à réception de la facture ou de l’ordre.</p>
          <p>7.13 - La Société n’est en aucun cas tenue de procéder à l’exécution des prestations commandées si le paiement n’est pas effectif sur son compte, le délai d’exécution desdites prestations courant à compter de la date du paiement effectif du prix dû, c’est-à-dire de son encaissement effectivement constaté.</p>
          <p>
            7.14 - Le paiement de la facture est exigible à réception de la facture. En cas de retard de paiement, une pénalité de 3 fois le taux d'intérêt légal sera appliquée.<br />
            Le taux sera appliqué sur le montant TTC de la facture, les pénalités courant dès le jour suivant la date de règlement prévue. Ces pénalités sont exigibles de plein droit, conformément à l’article L.441-6 du code de commerce, sans qu’un rappel soit nécessaire. <br />
            En outre, en cas de retard de paiement, il est dû une indemnité forfaitaire de 40€ pour frais de recouvrement. Dans l’hypothèse où les frais de recouvrement réellement engagés seraient supérieurs à ce montant de 40€, une indemnisation complémentaire sur justification sera demandée. <br />
            Cette indemnité forfaitaire ou l’indemnisation complémentaire en cas de frais réels de recouvrement supérieurs à 40€ s’applique à chaque facture payée en retard. <br />
            Enfin, le Client sera redevable, à titre de clause pénale conformément aux articles 1152 et 1226 et suivants du Code civil, dont 1229, d’une indemnisation d’un montant correspondant forfaitairement à 15% du prix HT des prestations impayées sans pouvoir être inférieure à 150€.
          </p>
          <p>7.15 - En cas d’exécution fractionnée successive des prestations, chaque exécution successive est subordonnée au paiement effectif de la précédente.</p>
          <p>7.16 - Dans l’hypothèse d’un paiement échelonné convenu entre les Parties, le non-paiement d’une seule échéance entraînera, dans tous les cas, la déchéance du terme et la possibilité de résilier le contrat si bon semble à la Société.</p>
          <p>7.17 - Si lors d’une précédente commande, le Client s’est soustrait à l’une de ses obligations (défaut ou retard de règlement notamment), un refus de vente pourra lui être opposé à moins que ce Client ne fournisse des garanties satisfaisantes ou un paiement comptant.</p>
        </div>

        <div className="8 prix-digital-design">
          <div className="sub-title-conditions">8. PRIX ET CONDITIONS DE PAIEMENT DIGITAL ET DESIGN</div>
          <p>8.1 - La Société communiquera ses tarifs sur demande du Client.</p>

          <p className="liste">- Le Client devra régler 50% du montant total de la prestation digitale ou design sur la base des tarifs en vigueur au jour de la signature du devis puis les 50% restants dans un délai de 21 jours après la fin de la prestation désignée par la Société.</p>
          <p className="liste">- Le versement d’un acompte ne donne pas l’accès du Client à une quelconque forme de propriété sur tout ou partie des éléments de la prestation qui reste la propriété exclusive de la Société jusqu’au paiement intégral.</p>
          <p className="liste">- Toute prestation supplémentaire et non prévue sur les documents (devis) fera l’objet d’une majoration équivalente à sa valeur. Le refus de paiement d’une telle prestation ouvrira le droit pour la Société à la résiliation et au paiement intégral du contrat de vente, et à une indemnité égale au préjudice subi et/ou à la valeur de la prestation additionnelle.</p>
          <p className="liste">- En cas de retard de paiement, la Société pourra suspendre toutes les prestations en cours, sans préjudice de toute autre voie d’action. En tout état de cause, le Client devra verser la totalité des sommes contractées et les intérêts sur les sommes dues après mise en demeure. Si aucune somme n’a été versée à la Société, la commande est résiliée de plein droit après une mise en demeure de payer restée infructueuse pendant une semaine.</p>
          <p className="liste">- Le non-paiement d’une seule échéance entraînera l’exigibilité immédiate de la totalité de la dette, sans mise en demeure. Dans le cas où le défaut de règlement du Client obligerait la Société à adresser de multiples relances (plus de 2 relances après la date d’échéance de la facture) et/ou à engager une action judiciaire, le Client devrait régler, outre le principal de la facture, frais, dépens et émoluments ordinairement et légalement mis à sa charge, une indemnité fixée à 20% du montant en principal de la créance TTC, à titre de dommages et intérêts conventionnels et forfaitaires.</p>
          <p className="liste">- Toute contestation ou réclamation relative à la facturation doit être adressée par écrit dans les 15 jours ouvrés à réception de ladite facture, à défaut de quoi le prestataire n’accepte plus aucun grief.</p>

          <p>8.2 - La Société demeure propriétaire de tous les éléments graphiques crées pour le Client jusqu’à règlement de la facture en totalité.</p>
        </div>

        <div className="9 annulation-distribution">
          <div className="sub-title-conditions">9. DÉCALAGE OU ANNULATION D’UNE PRESTATION</div>
          <p>9.1 - Toute demande d’annulation ou de décalage d’une campagne devra, pour être recevable, être signifiée par le Client par écrit au plus tard 5 jours ouvrés en Ile-de-France et 10 jours ouvrés hors Ile-de-France avant la date agréée de livraison ou d’enlèvement des documents. La demande de décalage d’une prestation devra préciser les nouvelles dates de campagnes sollicitées. Si le Client respecte ce délai, la Société annulera ou décalera la campagne qui ne sera pas facturée.</p>
          <p>9.2 - La réservation pour le service Flying Bike n’est valable que pour la date, le lieu adéquat, le type de prestation et le nombre de personnes estimées, tout changement essentiel de l’un de ces éléments peut entraîner l’annulation par le traiteur de sa prestation. </p>
          <p>9.3 - Toute demande de décalage ou d’annulation d’une prestation signifiée après le terme de ce délai acceptée par la Société donnera lieu à l’application au Client d’une facturation horaire de la prestation de logistique des documents préparés par la Société mais aussi des heures de préparation de la campagne.</p>
          <p>9.4 - Sauf exceptions infra, dans l’hypothèse spécifique où le Client est un particulier (un consommateur) qui aurait sollicité un Devis sans se présenter (ou se faire représenter par un mandataire) dans les locaux de la Société, ce Client a la possibilité de faire valoir son Droit dit de rétractation lié aux Ventes à Distance. Ce délai qui est de 14 jours court à compter du jour de la conclusion du contrat tel que décrit aux (2.2) et (2.3) supra.</p>
          <p>9.5 - Le Client peut réclamer l’exécution immédiate des prestations devisées qu’il a acceptées en exprimant de manière explicite et par écrit sa décision de ne pas se prévaloir du délai de rétractation.</p>
          <p>9.6 - Les prestations réalisées par la Société étant par définition personnalisées, il est ici rappelé qu’aucune prestation ne fera l’objet d’un commencement d’exécution avant expiration de ce délai de rétractation. <br />
            L’impossibilité d’exécution du contrat avant l’expiration du délai de rétractation ou de la renonciation expresse au droit de rétractation ressort des dispositions de l’Article L.121-20-2-3° du Code de la Consommation stipulant que le droit de rétractation ne peut être exercé pour les biens confectionnés selon spécifications du consommateur ou nettement personnalisés.
          </p>
        </div>

        <div className="10 reliquat">
          <div className="sub-title-conditions">10. RELIQUAT</div>
          <p>10.1 - Les documents non distribués demeurent la propriété du Client, ce dernier s’oblige à les récupérer dans un délai de 3 à 6 jours ouvrés après le dernier jour programmé pour leur distribution.<br />
            Dans ce cas, le Client assurera, à ses frais, l’enlèvement des documents restants, la Société pourra facturer au Client les coûts de stockage, de manutention, de désassemblage des documents, de tri et de gestion ainsi que de transport éventuel des documents en vue de leur restitution au Client.
            Passé ce délai, la Société placera les documents en destruction.
          </p>
        </div>

        <div className="11 service-client">
          <div className="sub-title-conditions">11. SERVICE CLIENT</div>
          <p>11.1 - Pour toute demande d’informations, de précisions ou pour toute réclamation, le Client doit contacter, en priorité, le service Client de la Société par écrit afin de permettre à ce dernier de tenter de trouver une solution au problème dans un délai de 3 jours ouvrés suivant la fin de la prestation et comporter l’adresse précise de la prestation contestée. Pour avoir force probante, les contestations portant sur le nombre de documents distribués ou l’absence de distribution devront contenir un constat effectué au plus tard 3 jours ouvrés après la distribution, dressé soit par un huissier de justice, soit par une liste d’attestants conforme à l’article 202 du Code de procédure civile. La Société disposera d’un délai de 3 jours ouvrés dès réception des réclamations formulées pour apporter une réponse au Client.</p>
          <p>11.2 - Les réclamations, quelle qu’en soit la nature, ne seront reçues que par écrit par lettre recommandée avec accusé de réception et dans un délai maximum de 7 jours après la date de réalisation de la totalité de la prestation d’Affichage.</p>

          <p>11.3 - En cas de désaccord sur une partie de nos factures (litiges, attente d’avoir…), l’Annonceur et/ou le Mandataire s’oblige à payer, sans aucun retard à l’échéance initiale prévue, la partie non contestée de la facture.</p>

          <p>11.4 - Les factures non payées le jour de leur échéance ou qui feront l’objet d’un litige total ou partiel, ne pourront pas être prises en compte pour la détermination de la base de calcul des remises tarifaires ou commerciales ou de toutes autres conditions accordées aux termes des présentes Conditions Générales de Vente. <br />
            Le service Client de la Société est accessible de 8h-20h du lundi au vendredi en utilisant les coordonnées suivantes :
          </p>
          <ul>
            <li><p className="liste-puce"><a href="tel:+33 6 12 42 25 29">Téléphone : 06 12 42 25 29</a></p></li>
            <li><p className="liste-puce"><a href="mailto:hello@flyingforyou.fr">Email : hello@flyingforyou.fr</a></p></li>
            <li><p className="liste-puce"><a href="/https://www.flyingforyou.fr/#">Courrier : 3 Rue Olympe de Gouges, 92600 Asnières-sur-Seine, France</a></p></li>
          </ul>
        </div>

        <div className="12 obligations-client">
          <div className="sub-title-conditions">12. OBLIGATIONS DU CLIENT</div>
          <p>12.1 - Le Client s’engage à respecter les termes des présentes CGV.</p>

          <p>12.2 - Le Client s’engage à utiliser le Site et les Services de manière conforme aux instructions de la Société.</p>

          <p>12.3 - Le Client convient qu’il n’utilise le Site que pour son usage personnel, conformément aux présentes CGV. A cet égard, le Client convient de s’abstenir :</p>

          <ul>
            <li><p className="liste-puce">D’utiliser le Site de toute manière illégale, pour toute finalité illégale ou de toute manière incompatible avec ces CGV.</p></li>
            <li><p className="liste-puce">De vendre, copier, reproduire, louer, prêter, distribuer, transférer ou concéder sous sous-licence tout ou partie des contenus figurant sur le Site ou de décompiler, désosser, désassembler, modifier, afficher sous forme lisible par le Client, tenter de découvrir tout code source ou utiliser tout logiciel activant ou comprenant tout ou une partie du Site.</p></li>
            <li><p className="liste-puce">De tenter d’obtenir l’accès non autorisé au système informatique du Site ou de se livrer à toute activité perturbant, diminuant la qualité ou interférant avec les performances ou détériorant les fonctionnalités du Site.</p></li>
            <li><p className="liste-puce">D’utiliser le Site à des fins abusives en y introduisant volontairement des virus ou tout autre programme malveillant et de tenter d’accéder de manière non autorisée au Site.</p></li>
            <li><p className="liste-puce">De porter atteinte aux droits de propriété intellectuelle de la Société.</p></li>
            <li><p className="liste-puce">De dénigrer le Site.</p></li>
          </ul>
          <p>12.4 - Si, pour un quelconque motif, la Société considère que le Client ne respecte pas les présentes CGV, la Société peut à tout moment, et à son entière discrétion, supprimer son accès au Site et prendre toutes mesures incluant toute action judiciaire civile et pénale à son encontre.</p>

          <p>12.5 - Le Client garantit au prestataire qu’il possède l’ensemble des autorisations et déclarations administratives nécessaires à l’exploitation du Site et notamment celles auprès de la CNIL sur les données nominatives.</p>

          <p>12.6 - Le Client déclare avoir eu connaissance de l’existence des moyens techniques pour restreindre l’accès à certains Services. Il appartient au Client de s’assurer que toutes les modifications ou évolutions techniques qu’il pourrait envisager effectuer sur le Site à l’avenir par tout moyen y compris autre que par les Services du prestataire, sont possibles et compatibles avec les caractéristiques techniques des prestations qu’il a commandées.</p>

          <p>12.7 - Le Client est conscient que toute prestation informatique peut contenir des dysfonctionnements, et qu’au-delà de la période de garantie il est possible que le Client ait à subir à sa charge des travaux correctifs sur l’application ou le Site qui lui sera livré.</p>
        </div>

        <div className="13 obligations-societe">
          <div className="sub-title-conditions">13. OBLIGATIONS ET RESPONSABILITÉ DE LA SOCIÉTÉ</div>
          <p>13.1 - La Société met en œuvre toutes les mesures propres à assurer au Client la fourniture, dans des conditions optimales, d’un ou de service(s) de qualité. Elle ne saurait cependant en aucun cas voir sa responsabilité engagée pour toute inexécution ou mauvaise exécution de tout ou partie des prestations prévues au contrat, qui serait imputable soit au Client, soit au fait imprévisible et insurmontable d’un tiers étranger au contrat, soit à un cas de force majeure. Plus généralement, si la responsabilité de la Société se trouvait engagée, elle ne pourrait en aucun cas accepter d’indemniser le Client pour des dommages indirects ou dont l’existence et/ou le quantum ne seraient pas établi par des preuves.</p>

          <p>13.2 - La Société ne saurait être tenue responsable des dommages causés par un mésusage d’un de ses Services ou par non-respect des précautions d’utilisation et conditions d’hygiène, de stockage et de sécurité lors de l’utilisation d’un de ses Produits/Services.</p>

          <p>13.3 - Il est expressément stipulé que la Société ne pourra en aucun cas être tenue responsable, de quelque manière que ce soit, pour le cas où le matériel informatique ou la messagerie électronique des Clients rejetterait, par exemple du fait d’un anti-spam, les courriers électroniques adressés par la Société, et notamment, sans que cette liste ne soit exhaustive, la copie du ticket de paiement, l’état récapitulatif de la Commande ou encore le courrier électronique de suivi d’expédition.</p>

          <p>13.4 - La Société s’engage à apporter tout le soin et la diligence nécessaires à la fourniture d’un service de qualité conformément aux usages de la profession et à l’état de l’art. La Société ne répond que d’une obligation de moyens qui ne doit en aucun cas être assimilée à une obligation de résultat.</p>

          <p>Tout particulièrement, et à titre d’exemple non-exhaustif, la Société ne saurait être tenue responsable dans les cas suivants :</p>
          <ul>
            <li><p className="liste-puce">Faute, négligence, omission mauvaise utilisation ou défaut d’entretien de la part du Client, non-respect des conseils donnés.</p></li>
            <li><p className="liste-puce">Intégration de scripts, APIs ou logiciels tiers nécessaires pour le fonctionnement de la prestation livrée : le prestataire ne peut garantir le bon fonctionnement de la prestation livré dans la mesure ou son exécution dépendra aussi de la bonne exécution d’une solution tierce.</p></li>
            <li><p className="liste-puce">Hébergement, modification ou manipulation de la prestation livrée par un prestataire tierce. Dans le cas ou un autre prestataire est impliqué dans la manipulation de la prestation livrée, le prestataire est automatiquement dégagé de toute responsabilité quant aux travaux livrés, l’indisponibilité des pages, le défaut d’affichage, la détérioration partielle ou totale du contenu des pages.</p></li>
            <li><p className="liste-puce">Non-signalement par le Client d’une circonstance ayant entrainé un dysfonctionnement et qui aurait pu être évité en amont.</p></li>
            <li><p className="liste-puce">Retour sur investissement jugé insuffisant par le Client.</p></li>
            <li><p className="liste-puce">Manquement volontaire ou involontaire du Client aux conseils de bonne utilisation de son Site.</p></li>
            <li><p className="liste-puce">Dysfonctionnement ou ralentissement des réseaux ou de l’Internet dans son ensemble.</p></li>
            <li><p className="liste-puce">Dysfonctionnement ou ralentissement de la prestation livrée pour cause de maintenance par le prestataire ou pour cause de sur-utilisation des ressources mises à disposition.</p></li>
          </ul>
          <p>Compatibilité navigateur : les travaux livrés par le prestataire couvrent exclusivement une compatibilité pour les versions de navigateurs de bureau ayant été lancés dans les 2 ans précédant la date de contractualisation et couvre les navigateurs suivants : Chrome, FireFox, Internet Explorer, Safari.</p>

          <p>13.5 - Le Client a pleinement conscience des dispositions du présent article et notamment des garanties et limitations de responsabilité susvisées, conditions essentielles sans lesquelles la Société n’aurait jamais contracté.</p>
          <p>13.6 - En cas de force majeure, de cas fortuits, de grèves de toute nature, de conditions atmosphériques, troubles sociaux, politiques ou civils, manifestations locales, etc., rendant impossible l’exécution de la prestation au(x) jour(s) prévu(s) dans l’ordre d’Affichage et agenda, le jour du démarrage de la campagne sera décalé avec l’accord de l’Annonceur ou du Mandataire, dans la mesure des disponibilités du planning de réalisation ou de pose de la Société; la diminution de la quantité de la prestation ou du temps de la conservation pour l’Affichage, entraînant alors la réduction du montant de l’ordre d’Affichage au prorata de la quantité réalisée ou prorata temporis.</p>
          <p>13.7 - La Société décline toute responsabilité en cas de vol de fond et valeur, perte, dégradation des effets appartenant aux Clients et/ou aux participants qui pourraient survenir à l’occasion de la réception. En outre, le Client sera responsable de toute perte, casse, dégradation qui pourrait être causé par les participants et/ou le personnel dont il a la charge, sur les biens et le matériel appartenant à la Société et ceux mis à la disposition de l’organisation.</p>
        </div>

        <div className="14 sous-traitance">
          <div className="sub-title-conditions">14. SOUS-TRAITANCE</div>
          <p>14.1 - La Société peut recourir à un ou des sous-traitants pour exécuter ses prestations. Elle conserve néanmoins la direction et la responsabilité de l’exécution des prestations.</p>
        </div>

        <div className="15 securite">
          <div className="sub-title-conditions">15. SECURITÉ</div>
          <p>15.1 - Le Client s’engage à ne pas porter atteinte à la sécurité du Site. A cette fin, il s’engage à ne pas procéder à tout accès et/ou maintien frauduleux dans le système d’information de la Société. Le Client ne peut non plus porter atteinte ou entraver le système d’information de la Société. A défaut, la Société pourra prendre à son encontre toute mesure et notamment engager sa responsabilité pénale au titre des articles 323-1 et suivants du Code pénal.</p>
        </div>

        <div className="16 propriete-intellectuelle">
          <div className="sub-title-conditions">16. PROPRIÉTÉ INTELLECTUELLE ET DONNÉES PERSONNELLES</div>
          <p>16.1 - L’ensemble des éléments de ce Site et le Site lui-même, sont protégés par le droit d’auteur, le droit des marques, des dessins et modèles et/ou tous autres droits de propriété intellectuelle. Ces éléments sont la propriété exclusive de la Société. L’ensemble de ces droits est réservé pour le monde entier.</p>

          <p>16.2 - Le nom et la marque, les logos, les dessins et modèles, lettres stylisées, marques figuratives, et tous les signes représentés sur ce Site sont et demeureront la propriété exclusive de la Société.</p>

          <p>16.3 - Aucun titre ni droit quelconque sur aucun élément ou logiciel ne sera obtenu par téléchargement ou copie d’éléments de ce Site. Il est formellement interdit au Client de reproduire (à part pour son utilisation personnelle et non commerciale), publier, éditer, transmettre, distribuer, montrer, enlever, supprimer, ajouter à ce Site et aux éléments et logiciels qu’il contient, pas plus que les modifier ou effectuer un quelconque travail en les prenant pour base, ni vendre ou participer à aucune vente en rapport avec ce Site, les éléments de ce Site ni aucun logiciel y afférent.</p>

          <p>16.4 - La Société concède au Client une licence non exclusive pour utiliser le Site. Cette licence est strictement personnelle et ne peut en aucun cas être cédée ou transférée à quel que tiers que ce soit. La licence est concédée pour la durée d’utilisation du Site.</p>

          <p>16.5 - Toute utilisation par le Client des dénominations sociales, marques et signes distincts appartenant à la Société est strictement prohibée sauf en cas d’accord exprès et préalable de la Société.</p>

          <p>16.6 - La Société comprend que la protection des données et de la vie privée est un enjeu pour l’ensemble des internautes visitant le Site. La Société s’engage, conformément à la réglementation RGPD, à respecter votre vie privée et à protéger vos données à caractère personnel, c'est-à-dire susceptible de vous identifier directement ou indirectement en tant que personne.</p>

          <p>16.7 - Dans le cadre du devis, la Société a vocation à récolter des données personnelles du Client. La Société s’engage à protéger les données à caractère personnel des Clients.</p>

          <p>16.8 - Les fichiers comportant des données à caractère personnel nécessaires pour la commande sont notamment conservés sur les serveurs de l’hébergeur du Site. Ce prestataire assure être en conformité avec les prescriptions du règlement général sur la protection des données (RGPD). La Société ne communique pas et ne fait pas commerce des données personnelles des Clients.</p>

          <p>16.9 - Au stade de la commande sur le Site, le Client consent expressément à la collecte et au traitement de ses données à caractère personnel nécessaires pour effectuer les commandes.</p>

          <p>16.10 - Les données personnelles récoltées par la Société ont pour objet de permettre la réalisation de la commande. Les différentes données à caractère personnelles ne seront pas conservées plus longtemps que nécessaire aux fins pour lesquelles elles ont été récoltées, y compris au regard du respect des obligations légales ou fiscales.</p>

          <p>16.11 - Conformément aux dispositions de la loi n° 78-17 du 6 janvier 1978, telle que modifiée par la loi n°2004-801 du 6 août 2004 dite “Informatique et Libertés”, et au règlement général sur la protection des données (RGPD), sous réserve de justifier de votre identité, tout Client, quelle que soit sa nationalité, dispose d’un droit d’accès, de modification et de suppression de ses données à caractère personnel. Chaque Client est également en droit de solliciter une limitation du traitement de ses données et dispose, par ailleurs, d’un droit à la portabilité des données ainsi que d’un droit d’opposition au traitement des données à caractère personnel le concernant.</p>

          <p>16.12 - Aux fins d’application de la présente clause et, notamment, de s’assurer du traitement de confidentialité des données des Clients, la Société a désigné, conformément aux dispositions du règlement général sur la protection des données (RGPD), un délégué à la protection des données, qu’il est possible de contacter à l’adresse suivante : hello@flyingforyou.fr</p>

          <p>16.13 - En tout état de cause, tout Client a le droit de faire toute réclamation auprès de la CNIL.</p>

          <p>16.14 - Sur les créations fournies par le Client <br />
            Tout élément fourni par le Client est protégé par le droit de la propriété intellectuelle et reste sa seule propriété. Il déclare disposer de tous les droits et/ou autorisations nécessaires, et garantit le prestataire contre toute réclamation éventuelle d’un tiers qui prétendrait avoir un droit de propriété intellectuelle sur l’un quelconque des éléments fournis par lui notamment de tous textes, images, logos, graphiques, photos, films audio ou vidéo, fichiers, logiciels, bases de données. <br />
            Le Client est propriétaire du contenu c’est à dire des informations se trouvant sur son site (logo, pages HTML, fichiers images, sons …), des bases de données, fichier Clients ou autres.
          </p>

          <p>16.15 - Sur les créations fournies par le prestataire <br />
            L’ensemble des créations du prestataire restent sa propriété exclusive à l’exception de l’ensemble des créations design du prestataire (logo, boutons, fichiers images, sons, charte graphique du site Internet …) qui font l’objet d’une cession de droits de propriété intellectuelle dans les conditions de l’article 13.3 ci-dessous). <br />
            Le prestataire se réserve le droit de revendre ou d’utiliser tout ou partie de ses créations. Ainsi, tout logiciel, application métier, ou autres développements spécifiques réalisés pour le Client restent la propriété du prestataire. Les droits d’exploitation de ces créations ne sont donc cédés au Client qu’à titre non exclusif. <br />
            Le prestataire concède au Client une licence gratuite sur l’ensemble des créations informatique du prestataire (code source du site Internet, du logiciel, du progiciel intégré etc…) et ce, pendant toute la durée de protection par le droit d’auteur de ces créations. Ainsi, le prestataire donne l’autorisation sans limite de temps au Client, lors d’une prestation immatérielle de modifier ou améliorer le code source existant. Dans ce cas le Client est le seul responsable des modifications ou améliorations et donc ne peut en aucune manière engager la responsabilité du prestataire. <br />
          </p>

          <p>16.16 - Cession de droit de propriété intellectuelle sur le design <br />
            Après paiement complet du prix prévu à l’article 12 des présentes, le prestataire concède à titre exclusif au Client, pour le monde entier et pour toute la durée de protection du droit d’auteur, le droit d’exploiter comprenant l’intégralité des droits d’auteur de nature patrimoniale, notamment le droit de reproduction, de représentation, d’adaptation, de correction, de traduction, de distribution, de commercialisation sur l’ensemble des créations design réalisées par le prestataire (logo, boutons, pages HTML, fichiers images, sons, charte graphique du site Internet …) pour le compte du Client et prévues dans le Cahier des charges.
          </p>
          <p>16.17 - L’Annonceur autorise la Société à le citer et à reproduire et/ou représenter, dans un but documentaire et/ou marketing, ses logos, produits, affiches et/ou marques sur tout produit de l’imprimerie ainsi que sur tout support numérique servant à la publicité de la Société ou à ses sites Internet et à réaliser toute étude ou enquête permettant de calculer l’impact de l’Affichage.</p>
        </div>

        <div className="17 applicable-juridiction">
          <div className="sub-title-conditions">17. LOI APPLICABLE ET ATTRIBUTION DE JURIDICTION</div>
          <p>17.1 - Les présentes CGV sont régies et interprétées conformément au droit français, sans tenir compte des principes de conflits de lois.</p>

          <p>17.2 - En cas de litige susceptible de survenir à l’occasion de l’interprétation et/ou de l’exécution des présentes ou en relation avec les présentes CGV, le Client peut décider de soumettre le litige avec la Société à une procédure de médiation conventionnelle ou tout autre mode alternatif de règlement des différends.</p>

          <p>17.3 - Conformément aux dispositions du Code de la consommation concernant le règlement amiable des litiges, la Société adhère au Service du Médiateur du e-commerce Fédération du commerce et de la distribution dont les coordonnées sont les suivantes : 12 Rue Euler, 75008 Paris, France – <a className="visible-link" target="_blank" href="http://www.fcd.fr" rel="noopener noreferrer">http://www.fcd.fr/</a> <br />
            Vous pouvez recourir au service de médiation pour les litiges de consommation liés à une commande effectuée sur internet. Pour connaître les modalités de saisine du Médiateur :  <a className="visible-link" target="_blank" href="https://mediateur.fcd.fr/reclamation" rel="noopener noreferrer">https://mediateur.fcd.fr/reclamation/</a></p>

          <p>17.4 - Il est enfin rappelé que la médiation n'est pas obligatoire mais uniquement proposée afin de résoudre les litiges en évitant un recours à la justice.</p>

          <p>17.5 - Le Client peut également se rendre sur la plateforme européenne de règlement des litiges de consommation mise en place par la Commission européenne à l’adresse suivante et répertoriant l’ensemble des organismes de règlement de litiges agrées en France : <a className="visible-link" target="_blank" href="https://webgate.ec.europa.eu/odr" rel="noopener noreferrer">https://webgate.ec.europa.eu/odr/</a></p>

          <p>17.6 - En cas d’échec de cette procédure de médiation ou si le Client souhaite saisir une juridiction, les règles du code de procédure civile s’appliqueront.</p>
        </div>

        <div className="sub-title-conditions">ANNEXES</div>
        <a className="visible-link" target="_blank" href="https://docs.google.com/document/d/1LaLNiPvu26tD4Gwog6KvOhN2sath2tHfAI1ncXL-R6k/edit" rel="noopener noreferrer">Annexe.1 - Formulaire de rétractation</a>
      </div>
    </div>
    <div style={{ width: "100%" }}>
            <Footer />
    </div>
    </div>
    </div>
  )
}

export default Cgv