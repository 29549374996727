import { InternRedirectionButton } from 'components/Buttons/button'
import './style.scss'
import Footer from 'components/Footer/footer'
import { useEffect, useState } from 'react';

const Cgu = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    
      useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }
        
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        }
      }, []);

      const zoom = windowSize?.width < 1200 ? windowSize?.width / 1200 : 1;
  return (
    <div id='merci-devis' style={{ zoom: zoom, transformOrigin: 'top left' }}>
    <div style={{ flex: 1, margin: "40px auto", maxWidth: 1440, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "20px 0px 0px", gap: "30px" }}>
        <InternRedirectionButton text={"Accueil"} style={{ fontSize: 18, alignSelf: "start", marginLeft: 20 }} className={'redirectBtnWhite'} link={"/"} />
        <div className="condition-page">
      <div className="page-content">
        <div className="sub-title-conditions">MENTIONS LÉGALES FLYING FOR YOU</div>
        <p>La navigation sur ce site est soumise aux présentes conditions d’utilisation. En accédant aux informations mises à disposition par le site www.flyingforyou.fr, vous reconnaissez avoir pris connaissance des présentes conditions d’utilisation et les accepter.</p>


        <div className="sub-title-conditions">ÉDITION DU SITE INTERNET</div>
        <p>Le site Flying For You (ci-après “le Site”), accessible à l’adresse www.flyingforyou.fr est édité par la société Flying For You (ci-après “l’Editeur”). SASU au capital de 500 euros, dont le siège social est situé 3 Rue Olympe de Gouges, 92600 Asnières-sur-Seine, France (0612422529, contact@flyingforyou.fr). RCS 845050517 Nanterre</p>

        <div className="sub-title-conditions">RÉDACTION DU SITE INTERNET</div>
        <p>Directeur de la publication : Cheikh Kane<br />
          Contact : 0612422529 ; cheikh.kane@flyingforyou.fr</p>

        <div className="sub-title-conditions">RÉDACTION DES MENTIONS LÉGALES</div>
        <p>Dénomination sociale : Captain Contrat<br />
          Adresse siège social : 12 rue saint-fiacre, 75002 Paris<br />
          Site internet : www.captaincontrat.com</p>

        <div className="sub-title-conditions">HÉBERGEUR DU SITE INTERNET</div>
        <p>Le site Flying For You est hébergé par OVH, SAS au capital de 10 euros, dont le siège social est situé 2 Rue Kellermann, 59100 Roubaix, France, joignable par le moyen suivant : 1007.</p>

        <div className="sub-title-conditions">RESPECT DE LA PROPRIÉTÉ INTELLECTUELLE </div>
        <p>Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéo, sons, ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire fonctionner le Site, et plus généralement tous les éléments reproduits ou utilisés sur le Site sont protégés par les lois en vigueur au titre de la propriété intellectuelle. Ils sont la propriété pleine et entière de l’Editeur ou de ses partenaires, sauf mentions particulières. Toute reproduction, représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces éléments, y compris les applications informatiques, sans l’accord préalable et écrit de l’Editeur, sont strictement interdites. Le fait pour l’Editeur de ne pas engager de procédure dès la prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation desdites utilisations et renonciation aux poursuites. Seule l’utilisation pour un usage privé dans un cercle de famille est autorisée et toute autre utilisation est constitutive de contrefaçon et/ou d’atteinte aux droits voisins, sanctionnées par Code de la propriété intellectuelle. La reprise de tout ou partie de ce contenu nécessite l’autorisation préalable de l’Editeur ou du titulaire des droits sur ce contenu.</p>

        <div className="sub-title-conditions">LIENS HYPERTEXTES</div>
        <p>Le Site peut contenir des liens hypertexte donnant accès à d’autres sites web édités et gérés par des tiers et non par l’Editeur. L’Editeur ne pourra être tenu responsable directement ou indirectement dans le cas où lesdits sites tiers ne respecteraient pas les dispositions légales. La création de liens hypertexte vers le Site ne peut être faite qu’avec l’autorisation écrite et préalable de l’Editeur.</p>
      </div>

    </div>
    <div style={{ width: "100%" }}>
            <Footer />
    </div>
    </div>
    </div>
  )
}

export default Cgu