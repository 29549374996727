
import { useEffect, useState } from 'react';
import { icon_black_top_right_arrow, icon_white_top_right_arrow } from 'utils/imageUrls';
import './style.scss'


export const SelectSimpleComponent = ({ name, handleAction, items = [], multiple = false }) => {
  const [focused, setFocused] = useState(false)
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const [valueSelect, setValueSelect] = useState([])

  useEffect(() => {
    const targetDiv = document.getElementById(`customSelect`);
    const handleClickOustside = (event) => {
      const isClickedOutside = !targetDiv.contains(event.target);
      if (isClickedOutside) {
        setFocused(false)
      }
    }
    document.addEventListener('click', handleClickOustside);
    return () => document.removeEventListener('click', handleClickOustside)
  }, [])


  useEffect(() => {
    handleAction(valueSelect)
  }, [valueSelect])

  const handleChange = (item) => {
    const currentValue = typeof item === "object" ? item?.value : item
    if (multiple) {
      if (valueSelect?.includes(currentValue)) {
        setValueSelect(valueSelect?.filter(e => e !== currentValue))
      }
      else {
        setValueSelect(prev => [...prev, currentValue])
      }
    }
  }

  const isSelect = (item) => {
    if (valueSelect.includes(item) || valueSelect === item)
      return "select"
    return ""
  }
  return (
    <div id={`customSelect`} className={`customSelect ${focused && 'focus'}  `}
      onClick={(e) => { e.stopPropagation(); setFocused(prev => !prev) }}>
      <div className={`customSelect_container`}>
        <input value={valueSelect?.length === 0 ? name : valueSelect?.join(", ")}
          className={`inputSelect  ${focused && 'focus'} `} onChange={() => {}} readOnly
        /> 
        <div className={`rightIcon ${focused ? "focused" : ''}`} >

          <img src={focused ? icon_white_top_right_arrow : icon_black_top_right_arrow}
            alt='icon' width={34} height={34} />
        </div>
      </div>
      <div style={{ position: "" }} onClick={(e) => e?.stopPropagation()} >
        <div className={`contentList ${focused ? "focused" : ''} ${isSafari && 'safariList'}`}>
          <div>
            {items.map((item, index) => <div key={index}
              className={`content-item ${isSelect(typeof item === "object" ? item?.value : item)}`}
              onClick={() => handleChange(item)}>{typeof item === "object" ? item?.name : item}
            </div>)}
          </div>
        </div>
      </div>
    </div>
  )
}