
export const HOME_PAGE_FFY = '/'
export const HOME_STREET_MARKETING = '/street-marketing'
export const HOME_FLYING_HAND = '/distribution-flyers'
export const HOME_FLYING_BOX = '/distribution-flyers-en-boites-aux-lettres'
export const HOME_FLYING_BIKE = '/velo-publicitaire'
export const HOME_FLYING_STORE = '/flyer-commerce'
export const HOME_FLYING_WALL = '/affichage-sauvage'
export const HOME_DIGITAL_MARKETING = '/marketing-digital'
export const HOME_DIGITAL_WEB_SITE = '/creation-site-web'
export const HOME_DIGITAL_SEO = '/referencement-naturel'
export const HOME_DIGITAL_SEA_SMO = '/regie-publicitaire'
export const HOME_DIGITAL_CRATION_GRAPHIC = '/creation-graphique'
export const HOME_STREET_MARKETEUR = '/street-marketeur'
export const HOME_USE_CASE = '/usecase'
export const NEWSLETTER = 'https://blog.flyingforyou.fr/accueil/flying-news/'
export const HOME_PEOPLE_PAGE = '/equipe-ffy'
export const BLOG = 'https://www.blog.flyingforyou.fr/accueil/'
export const HELP = 'https://www.blog.flyingforyou.fr/accueil/aide/'
export const LOCATION = "http://maps.google.com/?q=18, Terrasse Bellini, 92800 puteaux"
export const PHONE = "tel:+33 6 12 42 25 29"
export const EMAIL = "mailto:contact@flyingforyou.fr"
export const GENERAL_SALES_CONDITION = "/conditions-generales-de-vente"
export const CONFIDENTIAL = "https://www.flyingforyou.fr/politique-confidentialite.pdf"
export const LEGAL_MENTION = "/conditions-generales-d-utilisation" 
export const PLAY_STORE_LINK = "https://play.google.com/store/apps/details?id=fr.flyingforyou.app"
export const APP_STORE_LINK = "https://apps.apple.com/us/app/flying-for-you/id1618953728"
export const GTM_TESTING_HOME = "/gtm-testing-home"
export const GTM_TESTING_SUCCESS = "gtm-testing-paiement-success"


