import React from 'react'
import { ParentContainer } from 'components/ParentContainer/parentContainer'
import './imageBloc.scss'

const ImageBloc = ({ src, height = 842 }) => {
  return (
    <div className='usecase_image' style={{ backgroundColor: '#23272A', height: height }}>
      <img src={src} height={height} alt="bloc" className='lazyload'
        style={{ width: '100%', objectFit: 'cover', height: '100%' }} />
    </div>
  )
}

export default ImageBloc

export const DoubleImageBloc = ({ srcLeft, srcRight, height }) => {
  return (
    <ParentContainer className='usecase_double_image' style={{backgroundColor: 'white'}} >
    <div className='usecase_double_image_container' style={{ height: height }}>
      <div className='usecase_double_image_content' >
        <div className='usecase_double_image_src1'>
          <img src={srcLeft} height={height} width={700} alt="bloc" className='lazyload'/>
        </div>
        <div className='usecase_double_image_src1'>
          <img src={srcRight} height={height} width={600} alt="bloc" className='lazyload'/>
        </div>
      </div>
    </div>

    </ParentContainer>
  )
}