import { Fragment } from "react"


export const convertExplainToArray = (explain) => {
  return [
    {
      title: 'PROBLÉMATIQUE',
      content: explain?.problem && Object.values(explain?.problem)?.map((prob) => 
      <p className="explain_content_title" > {prob?.toUpperCase()} </p>)
    },
    {
      title: 'SOLUTION PROPOSÉE',
      content: explain?.solutions && Object.values(explain?.solutions)?.map((sol, index) => (
        <Fragment key={`explai-item-${index}`}>
          <p className="explain_content_title">{sol?.title?.toUpperCase()} :</p>
          {Object.values(sol?.list)?.map((item, indexj) => {
            return (
              <Fragment key={`solution-itel-${indexj}`}>
                <p className="usecase_explain_argument_item">{item?.toUpperCase()} </p>
              </Fragment>
            )
          })}
        </Fragment>
      ))
    }
  ]
}
export const convertSpecialExplainToArray = (explain) => {
  return [
    {
      title: 'AUTRES ÉLÉMENTS CRÉES',
      content: explain?.solutions && Object.values(explain?.solutions)?.map((sol, index) => (
        <Fragment key={`explai-item-${index}`}>
          <p className="explain_content_title">{sol?.title?.toUpperCase()} :</p>
          {Object.values(sol?.list)?.map((item, indexj) => {
            return (
              <Fragment key={`solution-itel-${indexj}`}>
                <p className="usecase_explain_argument_item">
                  {item?.toUpperCase()}</p>
              </Fragment>
            )
          })}
        </Fragment>
      ))
    }
  ]
}

export const convertStatsToArray = (stats) => {
  return stats && Object?.values(stats)
}

export const getWidthOfCover = (size) => {
  switch (size) {
    case 'xLarge': return 800;
    case 'Large': return 700;
    case 'Medium': return 500;
    default: return 400;
  }
}

export const sortUsecases = (usecaseList) => {
  const sizesPriority = { 'xlarge': 4, 'large': 3, 'medium': 2, 'small': 1 };

  const fcases = usecaseList.sort((a, b) => sizesPriority[b?.size] - sizesPriority[a?.size]);

  let smalls = fcases.filter((item) => item?.size === "Small");
  let mediums = fcases.filter((item) => item?.size === "Medium");
  let larges = fcases.filter((item) => item?.size === "Large");
  let xlarges = fcases.filter((item) => item?.size === "xLarge");

  const sortedSizes = xlarges?.map((size, index) => [size, smalls[index]]).flat()
    .concat(larges.map((size, index) => [size, mediums[index]]).flat())
    .concat([smalls].slice(xlarges.length)
      .concat(mediums.slice(larges.length)));

  return (sortedSizes)
};

export const selectPattern = (isMobile, index) => {
  const numberOfpattern = index % (isMobile ? 3 : 7)
  if (isMobile) {
    if (numberOfpattern === 0)
      return ("firstPattern-mobile")
    else
      return ("secondPattern-mobile")
  }
  else {
    if (numberOfpattern >= 0 && numberOfpattern <= 1)
      return ("firstPattern")
    if (numberOfpattern >= 2 && numberOfpattern <= 3)
      return ("secondPattern")
    if (numberOfpattern >= 4 && numberOfpattern <= 6)
      return ("thirdPattern")
  }
}