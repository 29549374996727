
import {
  mockupAppFfy,
  BIRDY_USECASE,
} from "utils/imageUrls"
 
export const ffyHomePageData = {
  metaTitle: "Flying For You - Agence de communication",
  metaDescription: "L'agence de communication qui allie street marketing et marketing digital pour vous aider à vous développer.",
  metaOgTitle: "Flying For You - Agence de communication",
  metaOgDescription: "L'agence de communication qui allie street marketing et marketing digital pour vous aider à vous développer.",
  metaOgImage: "https://dmsc62vqrvq4p.cloudfront.net/vitrine_v3/birdy-city.webp",
  metaRobots: "index",
  metaKeywords: "",
  metaLink: "https://www.flyingforyou.fr/",
  titePage: "Flying For You - Agence de communication",
}

export const ffyHomeDigitalPageData = {
  metaTitle: "Flying For You - Marketing Digital",
  metaDescription: "De la réalisation du cahier des charges jusqu’au rendu final, notre agence travaille main dans la main avec vous pour satisfaire vos besoins en matière de marketing digital pour votre acquisition client.",
  metaOgTitle: "Flying For You - Marketing Digital",
  metaOgDescription: "De la réalisation du cahier des charges jusqu’au rendu final, notre agence travaille main dans la main avec vous pour satisfaire vos besoins en matière de marketing digital pour votre acquisition client.",
  metaOgImage: "https://dmsc62vqrvq4p.cloudfront.net/vitrine_v3/birdy-city.webp",
  metaRobots: "index",
  metaKeywords: "",
  metaLink: "https://www.flyingforyou.fr/marketing-digital",
  titePage: "Flying For You - Marketing Digital",
}

export const streetMarketeurHomePageData = {
  id: "marketeur",
  metaTitle: "Flying For You - Street Marketeur",
  metaDescription: "Tous nos services street marketing pour accroitre votre visibilité et votre nombre de client !",
  metaOgTitle: "Flying For You - Street Marketing",
  metaOgDescription: "Tous nos services street marketeur pour accroitre votre visibilité et votre nombre de client !",
  metaOgImage: "https://www.flyingforyou.fr/street-marketing.svg",
  metaRobots: "index",
  metaKeywords: "",
  metaLink: "https://www.flyingforyou.fr/street-marketeur",
  titePage: "Flying For You - Street Marketeur",
}

export const streetMarketingHomePageData = {
  id: "marketing",
  metaTitle: "Agence Street-Marketing - Flying For You",
  metaDescription: "L'agence de street marketing experte dans toutes les prestations de street-marketing, allant de la distribution de flyers à l'affichage sauvage.",
  metaOgTitle: "Agence Street-Marketing - Flying For You",
  metaOgDescription: "L'agence de street marketing experte dans toutes les prestations de street-marketing, allant de la distribution de flyers à l'affichage sauvage.",
  metaOgImage: "https://www.flyingforyou.fr/street-marketing.svg",
  metaRobots: "index",
  metaKeywords: "",
  metaLink: "https://www.flyingforyou.fr/street-marketing",
  titePage: "Agence Street-Marketing - Flying For You",
}

export const flyingBoxHomePageData = {
  id: "flyingbal",
  metaTitle: "Distribution de flyers en Boîtes-aux-lettres - Flying For You",
  metaDescription: "La distribution de flyers en boîtes aux lettres vous permet d'atteindre directement votre cible dans votre zone de chalandise et vous assurez un bon retour sur investissement.",
  metaOgTitle: "Distribution de flyers en Boîtes-aux-lettres - Flying For You",
  metaOgDescription: "La distribution de flyers en boîtes aux lettres vous permet d'atteindre directement votre cible dans votre zone de chalandise et vous assurez un bon retour sur investissement.",
  metaOgImage: "https://www.flyingforyou.fr/mailbox.svg",
  metaRobots: "index",
  metaKeywords: "",
  metaLink: "https://www.flyingforyou.fr/distribution-flyers-en-boites-aux-lettres",
  titePage: "Distribution de flyers en Boîtes-aux-lettres - Flying For You",
}

export const flyingHandHomePageData = {
  id: "fflyinghand",
  metaTitle: "Distribution de flyers et animation Street-Marketing - Flying For You",
  metaDescription: "Démarquez vous de vos concurrents en allant à la rencontre de votre cible grâce à l'animation et la distribution de flyers devant les lieux susceptibles d'être visités par votre cible.",
  metaOgTitle: "Distribution de flyers et animation Street-Marketing - Flying For You",
  metaOgDescription: "Démarquez vous de vos concurrents en allant à la rencontre de votre cible grâce à l'animation et la distribution de flyers devant les lieux susceptibles d'être visités par votre cible.",
  metaOgImage: "https://www.flyingforyou.fr/map.svg",
  metaRobots: "index",
  metaKeywords: "",
  metaLink: "https://www.flyingforyou.fr/distribution-flyers",
  titePage: "Distribution de flyers et animation Street-Marketing - Flying For You",
}





export const flyingDepotHomePageData = {
  id: "flyingdepot",
  metaTitle: "Dépôt et affiche en boutique - Flying For You",
  metaDescription: "Déposez directement vos support de communication chez les commerçants afin d'être vu par vos prospects sans être intrusifs.",
  metaOgTitle: "Dépôt et affiche en boutique - Flying For You",
  metaOgDescription: "Déposez directement vos support de communication chez les commerçants afin d'être vu par vos prospects sans être intrusifs.",
  metaOgImage: "https://www.flyingforyou.fr/package-arrived.svg",
  metaRobots: "index",
  metaKeywords: "",
  metaLink: "https://www.flyingforyou.fr/flyer-commerce",
  titePage: "Dépôt et affiche en boutique - Flying For You",
}

export const flyingBikeHomePageData = {
  id: "flyingbike",
  metaTitle: "Flying Bike, le vélo publicitaire - Flying For Yo",
  metaDescription: "Flying Bike, le vélo publicitaire est la meilleure solution éco-responsable pour toucher vos prospects et les convertir.",
  metaOgTitle: "Flying Bike, le vélo publicitaire - Flying For Yo",
  metaOgDescription: "Flying Bike, le vélo publicitaire est la meilleure solution éco-responsable pour toucher vos prospects et les convertir.",
  metaOgImage: "image-flyingbike.webp",
  metaRobots: "index",
  metaKeywords: "vélo publicitaire",
  metaLink: "https://www.flyingforyou.fr/velo-publicitaire",
  titePage: "Vélo Publicitaire - Flying For You",
}

export const flyingWallHomePageData = {
  id: "flyingwall",
  metaTitle: "Flying Wall, l'affichage sauvage - Flying For You",
  metaDescription: "Flying Wall, l'affichage sauvage, la solution pour être vu par vos prospects dans la rue.",
  metaOgTitle: "Flying Wall, l'affichage sauvage - Flying For You",
  metaOgDescription: "Flying Wall, l'affichage sauvage, la solution pour être vu par vos prospects dans la rue.",
  metaOgImage: "Flying Wall, l'affichage sauvage, la solution pour être vu par vos prospects dans la rue.",
  metaRobots: "image-flyingwall.webp",
  metaKeywords: "index",
  metaLink: "Affichage Sauvage",
  titePage: "Affichage Sauvage - Flying For You",
}

export const digitalWebSiteHomePageData = {
  id: "digitalwebsite",
  metaTitle: "Site web - Flying For You",
  metaDescription: "Notre agence de marketing digital vous accompagne dans la création de votre site internet, que ce soit via un CMS ou bien directement codé, nos sites sont optimisés pour le SEO. Cela va du design de la maquette à la mise en ligne.",
  metaOgTitle: "Site web - Flying For You",
  metaOgDescription: "Notre agence de marketing digital vous accompagne dans la création de votre site internet, que ce soit via un CMS ou bien directement codé, nos sites sont optimisés pour le SEO. Cela va du design de la maquette à la mise en ligne.",
  metaOgImage: "https://www.flyingforyou.fr/creaweb.svg",
  metaRobots: "index",
  metaKeywords: "",
  metaLink: "https://www.flyingforyou.fr/creation-site-web",
  titePage: "Site web - Flying For You",
}
export const digitalSEOHomePageData = {
  id: "digitalseo",
  metaTitle: "Flying For You - Référencement naturel SEO",
  metaDescription: "Nous vous accompagnons dans votre stratégie de référencement naturel (SEO), création de contenu, backlinks. Assurez-vous d’être parmi les premiers résultats lors de la recherche de vos prospects.",
  metaOgTitle: "Flying For You - Référencement naturel SEO",
  metaOgDescription: "Nous vous accompagnons dans votre stratégie de référencement naturel (SEO), création de contenu, backlinks. Assurez-vous d’être parmi les premiers résultats lors de la recherche de vos prospects.",
  metaOgImage: "https://www.flyingforyou.fr/SEO.svg",
  metaRobots: "index",
  metaKeywords: "",
  metaLink: "https://www.flyingforyou.fr/referencement-naturel",
  titePage: "Référencement naturel SEO - Flying For You",
}
export const digitalSEAHomePageData = {
  id: "digitalsea",
  metaTitle: "Régies Publicitaire - SEA - Flying For You",
  metaDescription: "Notre agence vous accompagne dans toute votre stratégie d'acquisition payante sur internet, de la recherche Web (Google Ads) pour être vu sur le réseau search de Google ou bien du SMO pour votre acquisition réseaux sociaux.",
  metaOgTitle: "Régies Publicitaire - SEA - Flying For You",
  metaOgDescription: "Notre agence vous accompagne dans toute votre stratégie d'acquisition payante sur internet, de la recherche Web (Google Ads) pour être vu sur le réseau search de Google ou bien du SMO pour votre acquisition réseaux sociaux.",
  metaOgImage: "https://www.flyingforyou.fr/SEA.svg",
  metaRobots: "index",
  metaKeywords: "",
  metaLink: "https://www.flyingforyou.fr/regie-publicitaire",
  titePage: "Régies Publicitaire - SEA - Flying For You",
}
export const digitalGraphiqueHomePageData = {
  id: "digitalgraphique",
  metaTitle: "Création Graphique - Flying For You",
  metaDescription: "Flying For You accompagne ses clients dans la création graphique et digitale ou l’optimisation de leurs outils digitaux et ou print.",
  metaOgTitle: "Création Graphique - Flying For You",
  metaOgDescription: "Flying For You accompagne ses clients dans la création graphique et digitale ou l’optimisation de leurs outils digitaux et ou print.",
  metaOgImage: "https://www.flyingforyou.fr/logoDraw.svg",
  metaRobots: "index",
  metaKeywords: "",
  metaLink: "https://www.flyingforyou.fr/creation-graphique",
  titePage: "Création Graphique - Flying For You",
}

export const useCaseHomePageData = {
  id: "useCase",
  metaTitle: "Flying For You - Références Clients",
  metaDescription: "Retrouvez ici toutes nos références clients et nos réalisations.",
  metaOgTitle: "Flying For You - Références Clients",
  metaOgDescription: "Retrouvez ici toutes nos références clients et nos réalisations.",
  metaOgImage: "",
  metaRobots: "index",
  metaKeywords: "references clients",
  metaLink: "https://www.flyingforyou.fr/usecase",
  titePage: "Flying For You - Références Clients",
}



