import React from 'react'
import { ICON_FLECHE_BLANC_CERCLE, logo } from 'utils/imageUrls'
import { Link, useLocation } from 'react-router-dom'
import { HOME_PAGE_FFY } from 'routes/vitrineRoutes'
import bigLogo from 'assets/biglogo.png'
import './headerImage.scss'

export const HeaderImage = ({ height, src, text, text2 }) => {
  return (
    <div style={{ height: height }} className="image_container">
      <img src={src} data-src={src} alt='header-birdy' width={1200} height={height} className="lazyload birdy" />
      <Link to={HOME_PAGE_FFY}>
        <img src={logo} data-src={logo} alt="logo" width={120} height={98} className="lazyload logo" />
      </Link>
      <p className='text'>{text}</p>
      <p className='text text2'>{text2}</p>

    </div>
  )
}


export const HeaderBloc = ({height, Introduction, with_logo=false}) => {
  const location = useLocation()
  return (
    <div style={{ height: height, backgroundColor: '#23272A' }} className="image_container">
      {with_logo &&  <div className='biglogo'>
        <img src={bigLogo} data-src={bigLogo} alt="bigLogo" width={329} height={274} className="lazyload" />
      </div>
      }
      <div className='icon_more'>
       <img src={ICON_FLECHE_BLANC_CERCLE} data-src={ICON_FLECHE_BLANC_CERCLE} alt="fleche vers le bas" width={50} height={50} className="lazyload" />
      </div>
      <div className={`bloctext ${location?.pathname?.includes('usecase/') && 'reduce-top'}`}>
        <Introduction />
      </div>
    </div>
  )
}


