import React, { Fragment } from 'react'
import DivideTitle from 'components/DivideTitle/divideTitle'
import { ParentContainer } from 'components/ParentContainer/parentContainer'
import './campaignStep.scss'
import { SimpleButton } from 'components/Buttons/button'
import { dataSteps } from './dataStep'

const CampaignStep = () => {
  return (
    <ParentContainer className="usecase_campaign_stap" style={{ backgroundColor: 'white' }}>
      <div className='usecase_campaign_step_container'>
        <DivideTitle type={'h3'} w_title='LES ÉTAPES' w_title2='DE LA CAMPAGNE'
          w_style={{ color: '#23272A', fontSize: 40 }} w_style2={{ color: '#23272A', fontSize: 35 }} />
        <SimpleButton text='RECHERCHE D’UN NOM' style={{ fontSize: 30, cursor: 'unset' }}
          className={'simpleBtnWhite'} />
        <div className='usecase_campaign_step_content'>
          <div className='usecase_campaign_step_1'>
            <div className='step'>
              <p>ÉTAPE 1:</p>
              <p className='step_description'>
                Trouver des mots clés qui représentent bien les valeurs de l’entreprise et qui parle à la cible.
              </p>
            </div>
            <p className='step_more'>INNOVATION · INTERACTIVE · EXPERIENCE · INSTINCT · LOOP</p>
          </div>
          <div className='usecase_campaign_step_2'>
            <div className='step'>
              <p>ÉTAPE 2:</p>
              <p className='step_description'>Trouver des ébauches de nom de marque.</p>
            </div>
            <div className='center'>
              <p className='step_more'>FEELSE · EKOSIO · TYLES · SWYTOUCH</p>
            </div>
          </div>
          <div className='step_footer'>
            <p>CES ÉTAPES ONT ÉTÉ RÉALISÉ AVEC 2 ALLERS - RETOURS CLIENT.</p>
            <p>LE NOM DE MARQUE RETENUE PAR TOUS ÉTAIT SWYTOUCH : SEE WHAT YOU TOUCH</p>
          </div>
        </div>
        <SimpleButton text='CRÉATION DE LA CHARTE' style={{ fontSize: 30, cursor: 'unset' }}
          className={'simpleBtnWhite'} />
      </div>
      {dataSteps?.map((step, index) => {
        return (
          <Fragment key={`step-${index}`}>
            <div className='usecase_campaign_step_content pl-3' style={{ paddingBottom: index === 4 && 40 }} >
              <div className='usecase_campaign_step_1 pl-1'>
                <div className='step'>
                  <p>{step?.step} </p>
                  <p className='step_description full'>
                    {step?.description}
                  </p>
                </div>
              </div>
            </div>
            {step?.images && <div className={`usecase_campaign_image_container content-${step?.imagePosition}`}>
              {step?.images?.map((img, j) => <div key={`img-${j}`} className='img_container'>
                <img src={img} alt="img" />
              </div>)}

            </div>
            }
          </Fragment>
        )
      })}
    </ParentContainer>
  )
}

export default CampaignStep