import { InternRedirectionButton } from "components/Buttons/button"
import Footer from "components/Footer/footer"
import Partnercompanies from "components/PartnerCompanies/partnercompanies"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { logo } from 'utils/imageUrls'

// import { thanks } from "utils/images-url"

const ThanksPageContainer = (props) => {
  return (
    <>
      <Helmet>
        <meta name="title" content="Flying For You - Merci pour votre demande" data-react-helmet="true" />
        <meta name="description" content="Merci pour votre demande de devis, notre équipe commerciale reviens vers vous dans les 48h." data-react-helmet="true" />
        <meta property="og:title" content="Flying For You - Merci pour votre demande" data-react-helmet="true" />
        <meta property="og:description" content="Merci pour votre demande de devis, notre équipe commerciale reviens vers vous dans les 48h." data-react-helmet="true" />
        {/* <meta property="og:image" content={thanks} data-react-helmet="true" /> */}
        <meta name="robots" content="index" />
        <meta name="keywords" content="merci-devis" />
        <link rel="canonical" href="https://www.flyingforyou.fr/" />
        <title>Flying For You - Merci pour votre demande</title>
      </Helmet>
        <FirstBlockContainer />
    </>
  )

}

const FirstBlockContainer = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    
      useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }
        
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        }
      }, []);

      const zoom = windowSize?.width < 1200 ? windowSize?.width / 1200 : 1;
  return (
    <div id='merci-devis' style={{ zoom: zoom, transformOrigin: 'top left' }}>
    <div style={{ flex: 1, margin: "40px auto", maxWidth: 1440, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "20px 0px 0px", gap: "30px" }}>
        <InternRedirectionButton text={"Accueil"} style={{ fontSize: 18, alignSelf: "start", marginLeft: 20 }} className={'redirectBtnWhite'}
          link={"/"} />
        <img src={logo} data-src={logo} alt="logo" width={150} className="lazyload logo" />
        <div style={{ color: "#fff", fontSize: 22 }}>Merci pour votre devis</div>
        <div style={{ color: "#fff", fontSize: 20 }}>Nous traitons votre demande sous 24 heures puis nous revenons vers vous !</div>
        
        {/* <div style={{ color: "#fff" }}>coucou</div> */}
    </div>
    <div style={{ width: "100%" }}>
            <Partnercompanies title="REJOIGNEZ NOS ENTREPRISES PARTENAIRES" />
            <div style={{ height: 10 }}></div>
            <Footer />
        </div>
    </div>
  )
}

export default ThanksPageContainer