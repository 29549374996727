
const BASE_IMG_URL = "https://dmsc62vqrvq4p.cloudfront.net/website-images/"
const BASE_IMG_URL_V3 = "https://dmsc62vqrvq4p.cloudfront.net/vitrine_v3/"


export const lancementMission = `${BASE_IMG_URL}lancement-mission.webp`  // ========================

// vitrine V3
export const logo = `${BASE_IMG_URL_V3}logo.webp`
export const BLOG_IMG = `${BASE_IMG_URL_V3}BLOG_IMG_2.webp`
export const birdy_city = `${BASE_IMG_URL_V3}birdy-city.webp`
export const REFERENCES_IMG = `${BASE_IMG_URL_V3}REFERENCES_IMG.webp`
export const NEWSLETTER_IMG = `${BASE_IMG_URL_V3}NEWSLETTER_IMG.webp`
export const WELCOME_TO_CLIENT_THUMBNAIL_IMG = `${BASE_IMG_URL_V3}WELCOME_TO_CLIENT_PLATFORM.webp`

// people page
export const IMG_PAGEPEOPLE_1= `${BASE_IMG_URL_V3}IMG_PAGEPEOPLE_1.webp`
export const IMG_PAGEPEOPLE_2= `${BASE_IMG_URL_V3}IMG_PAGEPEOPLE_2.webp`
export const IMG_PAGEPEOPLE_3= `${BASE_IMG_URL_V3}img-page-people2.webp`
export const IMG_PAGEPEOPLE_4= `${BASE_IMG_URL_V3}IMG_PAGEPEOPLE_4.webp`
export const IMG_PAGEPEOPLE_5= `${BASE_IMG_URL_V3}IMG_PAGEPEOPLE_5.webp`

export const MINIATURE_CHEIKH= `${BASE_IMG_URL_V3}interview/MINIATURE_CHEIKH.png`
export const MINIATURE_IVAN= `${BASE_IMG_URL_V3}interview/MINIATURE_IVAN.png`
export const MINIATURE_MAÉVA= `${BASE_IMG_URL_V3}interview/MINIATURE_MAÉVA.png`
export const MINIATURE_THIAGO= `${BASE_IMG_URL_V3}interview/MINIATURE_THIAGO.png`

export const youtube = `${BASE_IMG_URL_V3}icons/ICON_YOUTUBE.webp`
export const linkedin = `${BASE_IMG_URL_V3}icons/ICON_LINKEDIN.webp`
export const facebook = `${BASE_IMG_URL_V3}icons/ICON_FACEBOOK.webp`
export const instagram = `${BASE_IMG_URL_V3}icons/ICON_INSTAGRAM.webp`
export const TRAIT_DESSIN = `${BASE_IMG_URL_V3}icons/TRAIT_DESSIN.webp`
export const icon_add_circle = `${BASE_IMG_URL_V3}icons/ICON_PLUS_CERCLE.webp`
export const icon_less_circle = `${BASE_IMG_URL_V3}icons/ICON_MOINS_CERCLE.webp`
export const icon_white_top_right_arrow = `${BASE_IMG_URL_V3}icons/ICON_FLECHE_BLANC.webp`
export const icon_black_top_right_arrow = `${BASE_IMG_URL_V3}icons/ICON_FLECHE_NOIR_HAUT.webp`
export const icon_white_ciecle_arrow = `${BASE_IMG_URL_V3}icons/ICON_FLECHE_BLANC_CERCLE.webp`
export const icon_black_bottom_left_arrow = `${BASE_IMG_URL_V3}icons/ICON_FLECHE_NOIR_BAS.webp`
export const ICON_FLECHE_NOIR_CERCLE = `${BASE_IMG_URL_V3}icons/ICON_FLECHE_NOIR_CERCLE.webp`
export const FUSEE_SM = `${BASE_IMG_URL_V3}icons/FUSEE_SM.webp`
export const RESEAU_SM = `${BASE_IMG_URL_V3}icons/RESEAU_SM.webp`
export const TIME_SM = `${BASE_IMG_URL_V3}icons/TIME_SM.webp`
export const COUVERTURE_STREET_MARKETEUR = `${BASE_IMG_URL_V3}COUVERTURE_STREET_MARKETEUR.webp`
export const ICON_FLECHE_BLANC_CERCLE = `${BASE_IMG_URL_V3}icons/FLECHE_BAS_BLANC.webp`


// vitrine v3 partenaires
export const SFR = `${BASE_IMG_URL_V3}partenaires/SFR.webp`
export const ADA = `${BASE_IMG_URL_V3}partenaires/ADA.webp`
export const BOXY = `${BASE_IMG_URL_V3}partenaires/BOXY.webp`
export const HSBC = `${BASE_IMG_URL_V3}partenaires/HSBC.webp`
export const MCDO = `${BASE_IMG_URL_V3}partenaires/MCDO.webp`
export const ORPI = `${BASE_IMG_URL_V3}partenaires/ORPI.webp`
export const ENGIE = `${BASE_IMG_URL_V3}partenaires/ENGIE.webp`
export const PARIS = `${BASE_IMG_URL_V3}partenaires/PARIS.webp`
export const CHANEL = `${BASE_IMG_URL_V3}partenaires/CHANEL.webp`
export const DEEZER = `${BASE_IMG_URL_V3}partenaires/LOGO_DEEZER_2.webp`
export const POKAWA = `${BASE_IMG_URL_V3}partenaires/POKAWA.webp`
export const ORANGE = `${BASE_IMG_URL_V3}partenaires/ORANGE.webp`
export const EIFFAGE = `${BASE_IMG_URL_V3}partenaires/EIFFAGE.webp`
export const BELLMAN = `${BASE_IMG_URL_V3}partenaires/BELLMAN.webp`
export const COOLTRA = `${BASE_IMG_URL_V3}partenaires/COOLTRA.webp`
export const LACTALIS = `${BASE_IMG_URL_V3}partenaires/LACTALIS.webp`
export const LA_FORET = `${BASE_IMG_URL_V3}partenaires/LA_FORET.webp`
export const ANIMALIS = `${BASE_IMG_URL_V3}partenaires/ANIMALIS.webp`
export const FUN_RADIO = `${BASE_IMG_URL_V3}partenaires/FUN_RADIO.webp`
export const RADIOFRANCE = `${BASE_IMG_URL_V3}partenaires/RADIOFRANCE.webp`
export const THE_BRADERY = `${BASE_IMG_URL_V3}partenaires/THE_BRADERY.webp`
export const FITNESS_PARK = `${BASE_IMG_URL_V3}partenaires/FITNESS_PARK.webp`
export const TOTAL_ENERGIES = `${BASE_IMG_URL_V3}partenaires/TOTAL_ENERGIES.webp`
export const STEPHANE_PLAZA = `${BASE_IMG_URL_V3}partenaires/STEPHANE_PLAZA.webp`
export const PARIS_FOOD_CLUB = `${BASE_IMG_URL_V3}partenaires/PARIS_FOOD_CLUB.webp`
export const BOUYGUES_IMMOBILIER = `${BASE_IMG_URL_V3}partenaires/BOUYGUES_IMMOBILIER.webp`
export const CERGY = `${BASE_IMG_URL_V3}partenaires/LOGO_CERGY_2.webp`
export const ENERTRAG = `${BASE_IMG_URL_V3}partenaires/LOGO_ENERTRAG_2.webp`
export const MASTEOS = `${BASE_IMG_URL_V3}partenaires/LOGO_MASTEOS_2.webp`
export const REPETTO = `${BASE_IMG_URL_V3}partenaires/LOGO_REPETTO_2.webp`
export const WTN = `${BASE_IMG_URL_V3}partenaires/LOGO_WTN_2.webp`
export const CLICHY = `${BASE_IMG_URL_V3}partenaires/LOGO_CLICHY_2.webp`
export const VITAMIN_WELL = `${BASE_IMG_URL_V3}partenaires/LOGO_VITAMIN_WELL_2.webp`
export const ORLEANS = `${BASE_IMG_URL_V3}partenaires/LOGO_ORLEANS_2.webp`

// vitrine v3 STREET MARKETING HOME PAGE
export const ADIDAS = `${BASE_IMG_URL_V3}images/ADIDAS.webp`
export const BALDORIA = `${BASE_IMG_URL_V3}images/BALDORIA.webp`
export const BAZIN = `${BASE_IMG_URL_V3}images/BAZIN.webp`
export const BOXY_IMAGE = `${BASE_IMG_URL_V3}images/BOXY.webp`
export const CAMPUSCOACH = `${BASE_IMG_URL_V3}images/CAMPUSCOACH.webp`
export const IMMOBEL = `${BASE_IMG_URL_V3}images/IMMOBEL.webp`
export const JHO = `${BASE_IMG_URL_V3}images/JHO.webp`
export const JHO_2 = `${BASE_IMG_URL_V3}images/JHO_2.webp`
export const JSUSHI = `${BASE_IMG_URL_V3}images/JSUSHI.webp`
export const LITTLEWORKER = `${BASE_IMG_URL_V3}images/LITTLEWORKER.webp`
export const NUK = `${BASE_IMG_URL_V3}images/NUK.webp`
export const OUESTLED = `${BASE_IMG_URL_V3}images/OUESTLED.webp`
export const IMG_SHOW_REEL = `${BASE_IMG_URL_V3}images/SHOWREEL_MINIATURE.webp`


// vitrine v3 FLYING BOX HOME PAGE
export const FLYING_BOX_BAZIN = `${BASE_IMG_URL_V3}flyingBox/FLYING_BOX_BAZIN.webp`
export const FLYING_BOX_BAZIN_2 = `${BASE_IMG_URL_V3}flyingBox/FLYING_BOX_BAZIN_2.webp`
export const FLYING_BOX_BIRDY = `${BASE_IMG_URL_V3}flyingBox/FLYING_BOX_BIRDY.webp`
export const FLYING_BOX_MACDO = `${BASE_IMG_URL_V3}flyingBox/FLYING_BOX_MACDO.webp`
export const FLYING_BOX_MACDO_2 = `${BASE_IMG_URL_V3}flyingBox/FLYING_BOX_MACDO_2.webp`
export const FLYING_BOX_MACDO_3 = `${BASE_IMG_URL_V3}flyingBox/FLYING_BOX_MACDO_3.webp`
export const FLYING_BOX_TEAM = `${BASE_IMG_URL_V3}flyingBox/FLYING_BOX_TEAM.webp`
export const FLYING_BOX_ZAPP = `${BASE_IMG_URL_V3}flyingBox/FLYING_BOX_ZAPP.webp`
export const FLYING_BOX_ZAPP_2 = `${BASE_IMG_URL_V3}flyingBox/FLYING_BOX_ZAPP_2.webp`
export const FLYING_BOX_ZAPP_3 = `${BASE_IMG_URL_V3}flyingBox/FLYING_BOX_ZAPP_3.webp`
export const LOGO_BLANC_FFY = `${BASE_IMG_URL_V3}flyingBox/LOGO_BLANC_FFY.webp`
export const FLYING_BOX_IMG_1 = `${BASE_IMG_URL_V3}flyingBox/FLYING_BOX_IMG_1.webp`
export const FLYING_BOX_IMG_2 = `${BASE_IMG_URL_V3}flyingBox/FLYING_BOX_IMG_2.webp`




// vitrine v3 FLYING HAND HOME PAGE
export const FLYING_HAND_ADIDAS = `${BASE_IMG_URL_V3}flyingHand/FLYING_HAND_ADIDAS.webp`
export const FLYING_HAND_ALCOPA = `${BASE_IMG_URL_V3}flyingHand/FLYING_HAND_ALCOPA.webp`
export const FLYING_HAND_ANIMALIS = `${BASE_IMG_URL_V3}flyingHand/FLYING_HAND_ANIMALIS.webp`
export const FLYING_HAND_ANIMALIS_2 = `${BASE_IMG_URL_V3}flyingHand/FLYING_HAND_ANIMALIS_2.webp`
export const FLYING_HAND_BIRDY = `${BASE_IMG_URL_V3}flyingHand/FLYING_HAND_BIRDY.webp`
export const FLYING_HAND_BODYHIT = `${BASE_IMG_URL_V3}flyingHand/FLYING_HAND_BODYHIT.webp`
export const FLYING_HAND_BODYHIT_2 = `${BASE_IMG_URL_V3}flyingHand/FLYING_HAND_BODYHIT_2.webp`
export const FLYING_HAND_BODYHIT_3 = `${BASE_IMG_URL_V3}flyingHand/FLYING_HAND_BODYHIT_3.webp`
export const FLYING_HAND_CAMPUSCOACH = `${BASE_IMG_URL_V3}flyingHand/FLYING_HAND_CAMPUSCOACH.webp`
export const FLYING_HAND_CAMPUSCOACH_2 = `${BASE_IMG_URL_V3}flyingHand/FLYING_HAND_CAMPUSCOACH_2.webp`
export const FLYING_HAND_CAMPUSCOACH_3 = `${BASE_IMG_URL_V3}flyingHand/FLYING_HAND_CAMPUSCOACH_3.webp`
export const FLYING_HAND_JHO = `${BASE_IMG_URL_V3}flyingHand/FLYING_HAND_JHO.webp`
export const FLYING_HAND_JSUSHI = `${BASE_IMG_URL_V3}flyingHand/FLYING_HAND_JSUSHI.webp`
export const FLYING_HAND_JSUSHI_2 = `${BASE_IMG_URL_V3}flyingHand/FLYING_HAND_JSUSHI_2.webp`
export const FLYING_HAND_JSUSHI_3 = `${BASE_IMG_URL_V3}flyingHand/FLYING_HAND_JSUSHI_3.webp`
export const FLYING_HAND_NUK = `${BASE_IMG_URL_V3}flyingHand/FLYING_HAND_NUK.webp`
export const FLYING_HAND_NUK_2 = `${BASE_IMG_URL_V3}flyingHand/FLYING_HAND_NUK_2.webp`
export const FLYING_HAND_PFC = `${BASE_IMG_URL_V3}flyingHand/FLYING_HAND_PFC.webp`
export const FLYING_HAND_PFC_2 = `${BASE_IMG_URL_V3}flyingHand/FLYING_HAND_PFC_2.webp`
export const FLYING_HAND_POKAWA = `${BASE_IMG_URL_V3}flyingHand/FLYING_HAND_POKAWA.webp`
// export const LOGO_BLANC_FFY = `${BASE_IMG_URL_V3}flyingBox/LOGO_BLANC_FFY.webp`


// vitrine v3 FLYING STORE HOME PAGE
export const FLYING_STORE_19M = `${BASE_IMG_URL_V3}flyingStore/FLYING_STORE_19M.webp`
export const FLYING_STORE_19M_2 = `${BASE_IMG_URL_V3}flyingStore/FLYING_STORE_19M_2.webp`
export const FLYING_STORE_BIRDY = `${BASE_IMG_URL_V3}flyingStore/FLYING_STORE_BIRDY.webp`
export const FLYING_STORE_BOXY = `${BASE_IMG_URL_V3}flyingStore/FLYING_STORE_BOXY.webp`
export const FLYING_STORE_BOXY_2 = `${BASE_IMG_URL_V3}flyingStore/FLYING_STORE_BOXY_2.webp`
export const FLYING_STORE_BOXY_3 = `${BASE_IMG_URL_V3}flyingStore/FLYING_STORE_BOXY_3.webp`
export const FLYING_STORE_BOXY_4 = `${BASE_IMG_URL_V3}flyingStore/FLYING_STORE_BOXY_4.webp`
export const FLYING_STORE_BUCOLIA = `${BASE_IMG_URL_V3}flyingStore/FLYING_STORE_BUCOLIA.webp`
export const FLYING_STORE_EVERESTFESTIVAL = `${BASE_IMG_URL_V3}flyingStore/FLYING_STORE_EVERESTFESTIVAL.webp`


// vitrine v3 FLYING BIKE HOME PAGE
export const FLYING_BIKE_BALDORIA = `${BASE_IMG_URL_V3}flyingBike/FLYING_BIKE_BALDORIA.webp`
export const FLYING_BIKE_BALDORIA_2 = `${BASE_IMG_URL_V3}flyingBike/FLYING_BIKE_BALDORIA_2.webp`
export const FLYING_BIKE_BALDORIA_3 = `${BASE_IMG_URL_V3}flyingBike/FLYING_BIKE_BALDORIA_3.webp`
export const FLYING_BIKE_BIRDY = `${BASE_IMG_URL_V3}flyingBike/FLYING_BIKE_BIRDY.webp`
export const FLYING_BIKE_IMMOBEL = `${BASE_IMG_URL_V3}flyingBike/FLYING_BIKE_IMMOBEL.webp`
export const FLYING_BIKE_IMMOBEL_2 = `${BASE_IMG_URL_V3}flyingBike/FLYING_BIKE_IMMOBEL_2.webp`
export const FLYING_BIKE_IMMOBEL_3 = `${BASE_IMG_URL_V3}flyingBike/FLYING_BIKE_IMMOBEL_3.webp`
export const FLYING_BIKE_SCHLOUKMAP = `${BASE_IMG_URL_V3}flyingBike/FLYING_BIKE_SCHLOUKMAP.webp`
export const FLYING_BIKE_VICTORIA = `${BASE_IMG_URL_V3}flyingBike/FLYING_BIKE_VICTORIA.webp`


// vitrine v3 FLYING WALL HOME PAGE
export const FLYING_WALL_BIRDY = `${BASE_IMG_URL_V3}flyingWall/FLYING_WALL_BIRDY.webp`
export const FLYING_WALL_CRUST = `${BASE_IMG_URL_V3}flyingWall/FLYING_WALL_CRUST.webp`
export const FLYING_WALL_CRUST_2 = `${BASE_IMG_URL_V3}flyingWall/FLYING_WALL_CRUST_2.webp`
export const FLYING_WALL_LITTLEWORKER = `${BASE_IMG_URL_V3}flyingWall/FLYING_WALL_LITTLEWORKER.webp`
export const FLYING_WALL_LITTLEWORKER_2 = `${BASE_IMG_URL_V3}flyingWall/FLYING_WALL_LITTLEWORKER_2.webp`
export const FLYING_WALL_LITTLEWORKER_3 = `${BASE_IMG_URL_V3}flyingWall/FLYING_WALL_LITTLEWORKER_3.webp`
export const FLYING_WALL_MAISONPARIENTE = `${BASE_IMG_URL_V3}flyingWall/FLYING_WALL_MAISONPARIENTE.webp`
export const FLYING_WALL_OP2 = `${BASE_IMG_URL_V3}flyingWall/FLYING_WALL_OP2.webp`
export const FLYING_WALL_OUESTLED = `${BASE_IMG_URL_V3}flyingWall/FLYING_WALL_OUESTLED.webp`
export const FLYING_WALL_OUESTLED_2 = `${BASE_IMG_URL_V3}flyingWall/FLYING_WALL_OUESTLED_2.webp`
export const FLYING_WALL_ROSSINANTE = `${BASE_IMG_URL_V3}flyingWall/FLYING_WALL_ROSSINANTE.webp`
export const FLYING_WALL_ROSSINANTE_2 = `${BASE_IMG_URL_V3}flyingWall/FLYING_WALL_ROSSINANTE_2.webp`

// vitrine v3 HOME MARKETING DIGITAL
export const COUVERTURE_MARKETING_DIGITAL = `${BASE_IMG_URL_V3}marketing-digital/COUVERTURE_MARKETING_DIGITAL.webp`
export const IMG_MARKETING_DIGITAL_1 = `${BASE_IMG_URL_V3}marketing-digital/IMG_MARKETING_DIGITAL_1.webp`
export const IMG_MARKETING_DIGITAL_2 = `${BASE_IMG_URL_V3}marketing-digital/IMG_MARKETING_DIGITAL_2.webp`
export const IMG_MARKETING_DIGITAL_3 = `${BASE_IMG_URL_V3}marketing-digital/IMG_MARKETING_DIGITAL_3.webp`
export const IMG_MARKETING_DIGITAL_4 = `${BASE_IMG_URL_V3}marketing-digital/IMG_MARKETING_DIGITAL_4.webp`
export const IMG_MARKETING_DIGITAL_5 = `${BASE_IMG_URL_V3}marketing-digital/IMG_MARKETING_DIGITAL_5.webp`
export const ESTERELECHOGRAPHIE_IMG = `${BASE_IMG_URL_V3}marketing-digital/ESTERELECHOGRAPHIE_IMG.webp`
export const SELVITYS_IMG = `${BASE_IMG_URL_V3}marketing-digital/SELVITYS_IMG.webp`
export const APP_STORE = `${BASE_IMG_URL_V3}marketing-digital/APP-STORE.webp`
export const GOOGLE_PLAY = `${BASE_IMG_URL_V3}marketing-digital/GOOGLE-PLAY.webp`
export const STREET_MARKETEUR_ANIMALIS = `${BASE_IMG_URL_V3}marketing-digital/STREET_MARKETEUR_ANIMALIS.webp`
export const STREET_MARKETEUR_ADIDAS = `${BASE_IMG_URL_V3}marketing-digital/STREET_MARKETEUR_ADIDAS.webp`
export const STREET_MARKETEUR_BAZIN = `${BASE_IMG_URL_V3}marketing-digital/STREET_MARKETEUR_BAZIN.webp`
export const STREET_MARKETEUR_CRUST = `${BASE_IMG_URL_V3}marketing-digital/STREET_MARKETEUR_CRUST.webp`
export const STREET_MARKETEUR_ZAPP = `${BASE_IMG_URL_V3}marketing-digital/STREET_MARKETEUR_ZAPP.webp`
export const COUVERTURE_CREATION_GRAPHIQUE = `${BASE_IMG_URL_V3}marketing-digital/COUVERTURE_CREATION_GRAPHIQUE.webp`

//////////////////////////////////////
export const swy_touch1 = `${BASE_IMG_URL_V3}useCases/swy_touch1.png`
export const swy_touch2 = `${BASE_IMG_URL_V3}useCases/swy_touch2.png`
export const touch_step1_1_1 = `${BASE_IMG_URL_V3}useCases/touch_step1_1_1.png`
export const touch_step1_1 = `${BASE_IMG_URL_V3}useCases/touch_step1_1.png`
export const touch_step1 = `${BASE_IMG_URL_V3}useCases/touch_step1.png`
export const touch_step2_2 = `${BASE_IMG_URL_V3}useCases/touch_step2_2.png`
export const touch_step2 = `${BASE_IMG_URL_V3}useCases/touch_step2.png`
export const touch_step3_1 = `${BASE_IMG_URL_V3}useCases/touch_step3_1.png`
export const touch_step3_2 = `${BASE_IMG_URL_V3}useCases/touch_step3_2.png`
export const touch_step3 = `${BASE_IMG_URL_V3}useCases/touch_step3.png`
export const touch_sub_image = `${BASE_IMG_URL_V3}useCases/touch_sub_image.png`
export const swy_touch_logo = `${BASE_IMG_URL_V3}useCases/swy_touch_logo.png`


export const CASE_SWYTOUCH = `${BASE_IMG_URL_V3}useCases/CASE_SWYTOUCH.webp`

export const vignettePokawa = `${BASE_IMG_URL}vignettes_yt/vignette-pokawa.webp`
export const vignetteOp2 = `${BASE_IMG_URL}vignettes_yt/vignette-op2-0.webp`
export const vignetteNUK = `${BASE_IMG_URL}vignettes_yt/vignette-nuk.webp`
export const vignetteSchloukmap = `${BASE_IMG_URL}vignettes_yt/vignette-schloukmap.webp`
