import React from 'react'
import Slider from 'react-slick'
import partnerList from 'utils/partnerList'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './infinitSlider.scss'

const InfinitSlider = () => {
  const list = Array.from({ length: Math.ceil(partnerList?.length / 5) }, (_, index) => index)
  const Slide = ({ partner }) => <div key={partner?.name} >
    {partner?.link}
  </div>

  return (
    <div className='slick_container'>
      <div style={{ padding: 50 }}>
        <Slider infinite={true} speed={1300} fade
          autoplay={true} dots={false} arrows={false} lazyLoad={'ondemand'}>
          {list.map((e, index) => {
            return (
              <div key={`slide-${index}`} className='partner_slider_container'>
                {partnerList?.slice(index * 5, ((index * 5) + 5)).map((partner, indexj) => {
                  return <Slide key={`item-${indexj}`} partner={partner} />
                })}
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

export default InfinitSlider