import React, { lazy, useRef } from 'react'
import { IMG_PAGEPEOPLE_2, IMG_PAGEPEOPLE_3, IMG_PAGEPEOPLE_4, IMG_PAGEPEOPLE_5 } from 'utils/imageUrls'
import { INTERVIEW_CHEIKH, INTERVIEW_IVAN, INTERVIEW_MAEVA, INTERVIEW_THIAGO, } from 'utils/videoUrls'
import { cultureFFY, findInCollab, nosValeur, offresFFY, peopelBonus, peopleObjective, statsPeopleFFY } from 'utils/dataText'

import './indexSections.scss'

const IntroductionFFY = lazy(() => import('components/IntroductionFFY/introductionFFY'))
const TextVideo = lazy(() => import('components/TextAndVideo/textVideo'))
const VideoPlayer = lazy(() => import('components/VideoPlayer/videoPlayer'))
const Statistics = lazy(() => import('components/Statistics/statistics'))
const Engagement = lazy(() => import('components/Engagements/Engagement'))
const PeopleImageBloc = lazy(() => import('./ImageBloc/imageBloc'))
const Expertise = lazy(() => import('components/Expertise/expertise'))

const style = { fontSize: 64, fontWeight: 700 }
export const IntroductionPeoplePage = () => <IntroductionFFY
  w_title={<>VENEZ RENCONTRER<br /> L’ÉQUIPE</>} widthBtn={false} logo={true}
  w_title2={"!"} g_title={<>LA PLUS STYLÉE</>} ffy_style={{ padding: '10px 0' }}
  btn_size={25} title_type2={true} w_style={style} g_style={style}
/>

const IndexSectionsPoeplePage = () => {
  const sectionRef = useRef(null)
  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className='people_ffy'>
      <VideoPlayer video={INTERVIEW_CHEIKH} height={750} fullWidth />
      <Statistics listStats={statsPeopleFFY} sText={{ fontSize: 40, fontWeight: 700 }}
        bg_color={'white'} sTitle={{ fontSize: 20, fontWeight: 600, textAlign: 'center' }} color="#23272A"
        sdivider={{ height: 'unset', margin: '0 25px' }} sStat={{ flex: 1, alignItems: 'center' }}
        extraBody={<div className='content-people'>
          <p>Notre nom n’est pas anodin : nous ne sommes ni une agence de voyage, ni une agence de décoration à ballons.</p>
          <p>Nous sommes une agence de communication qui accompagne ses clients pendant le décollage, durant le vol, jusqu’au moment de l’atterrissage !</p>
          <p>Nous mixons street et digital marketing pour proposer des campagnes de notoriété et d’acquisition efficaces.</p>
        </div>}
      />
      <PeopleImageBloc img1={IMG_PAGEPEOPLE_2} img2={IMG_PAGEPEOPLE_3} />
      <TextVideo content_width={'95%'} removePadding={true} full={false}
        includeVideo={true} isAnImage={true} white_btn={true} imgSrc={IMG_PAGEPEOPLE_4}
        title_style={{ fontSize: 40, fontStyle: 'normal' }} title2_style={{ fontStyle: 'normal' }}
        listAdvantages={peopleObjective} extra_btn={false} />
      <div id='collaborateur'>
        <TextVideo content_width={'70%'} title="CE QUE NOUS RECHERCHONS"
          title_2="CHEZ NOS COLLABORATEURS" removePadding={true}
          includeVideo={false} white_btn={true}
          title_style={{ fontSize: 40, fontStyle: 'normal' }}
          title2_style={{ fontStyle: 'normal', paddingBottom: 30 }}
          listAdvantages={findInCollab} extra_btn={false} />
      </div>
      <VideoPlayer video={INTERVIEW_IVAN} height={750} fullWidth />
      <TextVideo content_width={'95%'} full={false} changeSectionBtn scrollToSection={scrollToSection}
        includeVideo={true} isAnImage={true} white_btn={true} imgSrc={IMG_PAGEPEOPLE_5}
        title_style={{ fontSize: 40, fontStyle: 'normal' }} title2_style={{ fontStyle: 'normal' }}
        listAdvantages={peopelBonus} extra_btn={true} defaultTextExtraBtn="VOIR TOUTES NOS OFFRES" />
      <Engagement w_title="LA" g_title="CULTURE FFY" list_items={cultureFFY} />
      <Expertise list_data={nosValeur} w_title="NOS VALEURS" title_type={'h3'} btn_style={{cursor: 'unset'}}
        w_style={{ fontSize: 64, color: '#23272A' }}  linkBtn={false} isBtnSimple />
      <VideoPlayer video={INTERVIEW_MAEVA} height={750} fullWidth />
      <div id='offres_ffy' ref={sectionRef}>
        <Engagement w_title="LES" g_title="OFFRES FFY" list_items={offresFFY}
          stext={{ fontSize: 35 }} />
      </div>
      <VideoPlayer video={INTERVIEW_THIAGO} height={750} fullWidth />
    </div>
  )
}

export default IndexSectionsPoeplePage
