import React from 'react';
import { Helmet } from "react-helmet-async";

const GlobalSection = ({ metaData, AllSections }) => {
  return (
    <>
      <Helmet>
        <meta name="title" content={metaData?.metaTitle} data-react-helmet="true" />
        <meta name="description" content={metaData?.metaDescription} data-react-helmet="true" />
        <meta property="og:title" content={metaData?.metaOgTitle} data-react-helmet="true" />
        <meta property="og:description" content={metaData?.metaOgDescription} data-react-helmet="true" />
        <meta property="og:image" content={metaData?.metaOgImage} data-react-helmet="true" />
        <meta name="robots" content={metaData?.metaRobots} />
        <meta name="keywords" content={metaData?.metaKeywords} />
        <link rel="canonical" href={metaData?.metaLink} />
        {/* <link rel="preload" fetchpriority="high" href={metaData?.firstBlock?.img} as='image' /> */}
        {/* <link rel="preload" fetchpriority="high" href={logoDark} as='image' /> */}
        {/* <link rel="preload" fetchpriority="high" href={metaData?.metaOgImage} as='image' /> */}
        <title>{metaData?.titePage}</title>
      </Helmet>
      <AllSections />
    </>
  )
}

export default GlobalSection