import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { Link } from 'react-router-dom'
import { MdClose } from 'react-icons/md'
import { navbarLinks } from 'utils/links'
import { InternRedirectionButton } from 'components/Buttons/button'
import { Collapse, IconButton, List, ListItem, Typography } from '@mui/material'
import { icon_black_bottom_left_arrow, icon_black_top_right_arrow } from 'utils/imageUrls'
import { HOME_STREET_MARKETEUR } from 'routes/vitrineRoutes'
import './drawer.scss'

const ListLinks = ({ handleClose, handleOpenSubMenu, handleCloseSubMenu, subMenu }) => {
  const [zoom, setZoom] = useState(0)
  useEffect(() => {
    function handleResize() {
      const googleReviews = document.getElementById('vitrineFFY')
      const currentZoom = window.getComputedStyle(googleReviews).getPropertyValue('zoom');
      setZoom(currentZoom);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [zoom])

  return (
    <div className="list_links" role="presentation">
      <Box display='flex' padding={'10px 20px 20px 20px'} justifyContent={'flex-end'}>
        <IconButton onClick={(e) => { e.stopPropagation(); handleClose() }}> <MdClose color='black' /> </IconButton>
      </Box>
      <List>
        {navbarLinks.map((link, index) => (
          <ListItem key={link?.name} disablePadding>
            <Box className="link_container">
              <Link to={link?.link} className='item_container'
                onMouseOver={subMenu !== index ? () => handleOpenSubMenu(index) : () => handleCloseSubMenu}>
                <Typography variant='p' className={"main_item"}> {link?.name} </Typography>
                {zoom < 0.416667 && link?.subLinks ?
                  <img src={icon_black_bottom_left_arrow} alt="close_menu" width={20} height={20} className='icon' />
                  :
                  link?.subLinks ? (subMenu !== index) ?
                    <img src={icon_black_top_right_arrow} alt="open_menu" width={20} height={20} className='icon' />
                    : <img src={icon_black_bottom_left_arrow} alt="close_menu" width={20} height={20} className='icon' /> : null}
              </Link>
              <Box >
                {link?.subLinks && link?.subLinks?.map((subItem, subIndex) => (
                  <Collapse
                    in={zoom < 0.416667 ? subMenu !== 0 : subMenu === index}
                    className={"list_sub_items"}
                    timeout={600}
                    unmountOnExit
                    key={subIndex}
                  >
                    <List component="div" disablePadding>
                      <Link to={subItem?.link}>
                        <Typography className="sub_item">{subItem?.name}</Typography>
                      </Link>
                    </List>
                  </Collapse>
                ))}
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
      <div className='btn_link'>
        <InternRedirectionButton text={"ESPACE STREET MARKETEURS"} style={{ fontSize: 22, margin: 'auto' }}
          className={'redirectBtnWhite'} link={HOME_STREET_MARKETEUR} />
      </div>
    </div>
  )
}

export default ListLinks