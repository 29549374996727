import React from 'react'
import './divideTitle.scss'

export const DivideTitle = (props) => {
  const { type, w_title, w_title2, g_title, w_style, g_style, start_space, w_style2, style } = props;
  const Type = type;
  return (
    <div className='content_title' style={style}>
      <Type className='main_title' style={w_style} >{w_title}</Type>
      <Type className='sub_title' style={w_style2}>{w_title2}
        <span style={g_style}>{start_space && <>&nbsp;</>} {g_title}</span>
      </Type>
    </div>
  )
}

export const DivideTitle2 = (props) => {
  const { type, w_title, w_title2, g_title, w_style, g_style, content_style  } = props;
  const Type = type;
  return ( 
    <div className='content_title2' style={content_style}>
      <Type className='main_title' style={w_style}
      // , fontSize: size_w_title, color: w_color, fontWeight: font_weight }}
       >{w_title}
        <span style={g_style}//, color: g_color, fontSize: size_g_title }}
        >&nbsp;{g_title}&nbsp;</span>
        {w_title2}
      </Type>
      {/* <Type className='sub_title' style={{ fontSize: size_g_title, color: w_color }}>
      </Type> */}
    </div>
  )
}

export const CustomDivider = ({bg_color, color, position, width}) => {
  return <div style={{ backgroundColor: bg_color, display: 'flex', justifyContent: position }}>
  <div style={{ border: `1px solid ${color}`, width: width,  }} />
</div>
}


export default DivideTitle