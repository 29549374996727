import React from 'react'
import { CgMenuRightAlt } from 'react-icons/cg'
import CustomDrawer from '../Drawer/drawer'
import './horizontalNavbar.scss'

const HorizontalNavbar = ({ open, handleClose, handleOpen }) => {
  return (
    <>
      <div className='nav_container'>
        <div className='nav'>
          <div></div>
          <div className='open-menu' onClick={handleOpen} >
            <CgMenuRightAlt size={25} color='black' />
          </div>
        </div>
      </div>
      <CustomDrawer open={open} handleClose={handleClose} />
    </>
  )
}

export default HorizontalNavbar