import {
  touch_step1, touch_step1_1, touch_step1_1_1, touch_step2, touch_step2_2,
  touch_step3, touch_step3_1, touch_step3_2
} from 'utils/imageUrls'

export const dataSteps = [
  {
    type: "text",
    step: 'ÉTAPE 1 :',
    description: 'Trouver des typographies qui correspondant à l’univers souhaité.',
    images: null
  },
  {
    type: 'text',
    step: 'ÉTAPE 2 :',
    description: 'Trouver des éléments visuels se rapprochant de l’univers de la marque.',
    images: [touch_step1, touch_step1_1, touch_step1_1_1],
    imagePosition: 'left'
  },
  {
    type: 'text',
    step: 'ÉTAPE 3 :',
    description: 'Choisir des couleurs allant avec la marque.',
    images: [touch_step2, touch_step2_2],
    imagePosition: 'right'
  },
  {
    type: 'text',
    step: 'ÉTAPE 4 :',
    description: 'Faire des assemblages pour créer un logo.',
    images: [touch_step3, touch_step3_2, touch_step3_1],
    imagePosition: 'left'
  },
  {
    type: 'text',
    step: 'ÉTAPE 5 :',
    description: 'Définir les interdits d’utilisation et les déclinaisons du logo final.',
    images: null
  },
]