import React, { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import API from 'services/axios-config';
import { logo } from 'utils/imageUrls'

const Deeplinking = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const confirmEmail = () => {
            API.patch("confirmation_email", Object.fromEntries([...searchParams]))
            .then(res => console.log(res))
            .catch(err => console.log(err))
        }
        confirmEmail()
    }, [])

    return (
        <div style={{ flex: 1, height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "0 15px", gap: "30px" }}>
            <img src={logo} data-src={logo} alt="logo" width={150} className="lazyload logo" />
            <div style={{ fontSize: 14, maxWidth: 350, color: "#fff" }}>Merci d'avoir validé votre email, vous pouvez fermer cette page et retourner sur l'application.</div>
        </div>
    )
}

export default Deeplinking