import { BLOG, EMAIL, HELP, HOME_DIGITAL_CRATION_GRAPHIC, HOME_DIGITAL_SEA_SMO, HOME_DIGITAL_SEO, HOME_DIGITAL_WEB_SITE, HOME_FLYING_BIKE, HOME_FLYING_BOX, HOME_FLYING_HAND, HOME_FLYING_STORE, HOME_FLYING_WALL, HOME_PEOPLE_PAGE, HOME_USE_CASE, LOCATION, PHONE } from "routes/vitrineRoutes";

export const dataFooter = [
  {
    title: "Nos Services Street", list: [
      { name: "Flying Box", link: HOME_FLYING_BOX },
      { name: "Flying Hand", link: HOME_FLYING_HAND },
      { name: "Flying Bike", link: HOME_FLYING_BIKE },
      { name: "Flying Wall", link: HOME_FLYING_WALL },
      { name: "Flying Store", link: HOME_FLYING_STORE },
    ]
  },
  {
    title: "Nos services Digitaux", list: [
      { name: "Création de site web", link: HOME_DIGITAL_WEB_SITE },
      { name: "SEA / SMA", link: HOME_DIGITAL_SEA_SMO },
      { name: "SEO", link: HOME_DIGITAL_SEO },
      { name: "Création graphique", link: HOME_DIGITAL_CRATION_GRAPHIC },
    ]
  },
  {
    title: "Informations sur FFY", list: [
      { name: "Blog", link: BLOG },
      { name: "Références", link: HOME_USE_CASE },
      { name: "Équipe FFY", link: HOME_PEOPLE_PAGE },
      { name: "Centre d’aide", link: HELP },
    ]
  },
  {
    title: "Contact", list: [
      { name: "(+33) 6 12 42 25 29", link: PHONE },
      { name: "contact@flyingforyou.fr", link: EMAIL },
      { name: "18, Terrasse Bellini, 92800 puteaux", link: LOCATION },
    ]
  },
]