import React from 'react'
import { ParentContainer } from 'components/ParentContainer/parentContainer'
import InfinitSlider from 'components/InfinitSlider/infinitSlider'
import './partnercompanies.scss'

const Partnercompanies = ({ title }) => {
  return (
    <ParentContainer style={{ backgroundColor: 'white' }}>
      <div className='title_companies_bloc'>
        <h2>{title} </h2>
      </div>
      <InfinitSlider />
    </ParentContainer>
  )
}

export default Partnercompanies