import React, { useEffect, useMemo, useState } from 'react'
import { HeaderBloc, HeaderImage } from 'components/HeaderImage/headerImage';
import Navbar from 'components/NavBar/navbar';
import Footer from 'components/Footer/footer';
import VideoPlayer from './useCases/home/components/VideoPlayer/videoPlayer';
import './vitrineContainer.scss'

const VitrineContainer = ({ header = false, birdy_img, Sections, text, text2 = "", Introduction, hidden = true, videoUrl }) => {

  const [windowScroll, setWindowScroll] = useState(0)
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    const handleScroll = () => {
      setWindowScroll(window.pageYOffset);
    }
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener("resize", handleResize);
    }
  }, []);

  const zoom = windowSize?.width < 1440 ? windowSize?.width / 1440 : 1;
  const memoizedBody = useMemo(() => <Sections />, [])
  const positionFromTop = Math.max(windowSize?.height - windowScroll, 0)
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return (
    <div id='vitrineFFY' style={{ zoom: zoom, transformOrigin: 'top left' }}>
      <div style={{ position: "", maxWidth: hidden ? '1440px' : 'unset', margin: "auto" }}>
        {header ?
          <HeaderImage height={windowSize?.height} src={birdy_img} text={text} text2={text2} />
          :
          // <HeaderBloc Introduction={Introduction} height={windowSize?.height} />
          hidden ?
            <HeaderBloc Introduction={Introduction} height={windowSize?.height} />
            : <VideoPlayer height={windowSize?.height} url={videoUrl} fullWidth={true} />

        }
        <Navbar windowHeight={windowSize?.height} scroll={windowScroll} windowWidth={windowSize?.width} />
        <div className='sections' style={{
          zIndex: 11, position: 'absolute',
          top: `${isSafari ? 1100 : positionFromTop}px`, left: 0, right: 0
        }}>
          {memoizedBody}
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default VitrineContainer