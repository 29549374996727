import API from "services/axios-config"
import { specialCover } from "../specialUsecase/dataConfig"

export const getAllUsecases = (setUseCaseList, setIsLoaded) => {
  API?.get('/vitrine_usecases')
    .then((res) => {
      setUseCaseList([...res?.data, specialCover])
      setIsLoaded(true)
    })
    .catch((err) => console.log(err))
}