import {
  FLYING_BIKE_BALDORIA_3, FLYING_BIKE_IMMOBEL, FLYING_BIKE_SCHLOUKMAP, FLYING_BIKE_VICTORIA, FLYING_BOX_BAZIN_2,
  FLYING_BOX_IMG_1,
  FLYING_BOX_IMG_2,
  FLYING_BOX_MACDO, FLYING_BOX_ZAPP, FLYING_HAND_ANIMALIS_2, FLYING_HAND_BODYHIT_3, FLYING_HAND_NUK, FLYING_STORE_19M,
  FLYING_STORE_BOXY_3, FLYING_STORE_BOXY_4, FLYING_STORE_BUCOLIA, FLYING_WALL_LITTLEWORKER, FLYING_WALL_LITTLEWORKER_3,
  FLYING_WALL_OP2, FLYING_WALL_OUESTLED_2, FUSEE_SM, RESEAU_SM, TIME_SM
} from "./imageUrls"

import {
  HOME_DIGITAL_CRATION_GRAPHIC, HOME_DIGITAL_SEA_SMO, HOME_DIGITAL_SEO, HOME_DIGITAL_WEB_SITE, HOME_FLYING_BIKE,
  HOME_FLYING_BOX, HOME_FLYING_HAND, HOME_FLYING_STORE, HOME_FLYING_WALL
} from "routes/vitrineRoutes"


export const engagementData = [
  {
    text: `Nous sommes capables de créer de A à Z une opération correspondant à vos attentes en 
  seulement 48H, tout en ciblant de manière précise votre cible.`,
    title: 'RÉACTIVITÉ'
  },
  {
    text: `Offrez à vos prospects une expérience unique grâce à nos recommandations créatives pour le marketing ON et OFF line.`,
    title: 'CRÉATIVITÉ'
  },
  {
    text: `Soyez rassurés sur l’impact de votre campagne en terme d’écologie car nous avons une approche 
    éco-responsable afin de pouvoir préserver notre planète.`,
    title: 'ÉCO-RESPONSABILITÉ'
  }
]

export const ourExpertise = [
  [{ title: "FLYING HAND", link: HOME_FLYING_HAND }, { title: "FLYING BOX", link: HOME_FLYING_BOX }, { title: "FLYING WALL", link: HOME_FLYING_WALL }],
  [{ title: "FLYING STORE", link: HOME_FLYING_STORE }, { title: "CRÉATION SITE WEB", link: HOME_DIGITAL_WEB_SITE }, { title: "SEO", link: HOME_DIGITAL_SEO }],
  [{ title: "CRÉATION GRAPHIQUE", link: HOME_DIGITAL_CRATION_GRAPHIC }, { title: "SEA & SMO", link: HOME_DIGITAL_SEA_SMO }]
]

export const expertiseData = [
  { name: "FLYING HAND" },
  { name: "FLYING BOX" },
  { name: "FLYING WALL" },
  { name: "FLYING STORE" },
  { name: "CRÉATION SITE WEB" },
  { name: "SEO" },
  { name: "CRÉATION GRAPHIQUE" },
  { name: "SEA & SMO" },
]

export const whyFFYText = [
  "CIBLAGE ULTRA PRÉCIS",
  "APPROCHE ROISTE",
  "ÉCOUTE ET ADAPTABILITÉ",
  "TRANSPARENCE ET REPORTING",
  "TECHNOLOGIE ET ALGORITHME",
  "FORCE ET LOGISTIQUE",
]

export const serviceSMarketingData = [
  {
    type: "FLYING BOX",
    text: "La distribution de flyers en boîtes aux lettres est le moyen le plus probant pour que votre message atteigne votre cible. Pour distribuer des journaux ou encore des tracts publicitaires de façon optimale, nous utilisons notre expertise en géomarketing.",
    link: HOME_FLYING_BOX
  },
  {
    type: "FLYING HAND",
    text: "Grâce au main-à-main, vous ciblez un lieu où vos prospects affluent dans le but de distribuer votre prospectus au moment opportun.  C’est un moyen simple et pertinent pour toucher votre cible. Nous nous assurons de choisir les animateurs les plus en cohérence avec le profil de votre entreprise afin de transmettre vos valeurs.",
    link: HOME_FLYING_HAND
  },
  {
    type: "FLYING STORE",
    text: "Distribuer vos médias tactiques à des emplacements stratégiques tels que chez les commerçants vous permet de vous adresser à vos clients sans être intrusif. Dans ce service, les supports sont plutôt variés : flyers, sous-bocks, gobelets publicitaires, sets de table, boîtes à pizza…",
    link: HOME_FLYING_STORE
  },
  {
    type: "FLYING BIKE",
    text: "Support de communication très visible et non polluant, avec un effet WAOUH! assuré. Le vélo publicitaire est aussi très complémentaire avec de la distribution de flyers et les éléments de branding, permettant à votre marque de faire le plein de visibilité ! De quoi considérablement booster votre présence dans la rue.",
    link: HOME_FLYING_BIKE
  },
  {
    type: "FLYING WALL",
    text: "L’affichage urbain est le meilleur moyen de s’assurer une visibilité et un impact fort dans des points de passage stratégiques où atteindre votre cible. Aller à la rencontre de votre future clientèle en diffusant un message imposant qu’on ne peut pas louper, c’est ça l’affichage.",
    link: HOME_FLYING_WALL
  }
]

export const statsSMarketing = [
  {
    title: "43%",
    text: "des Français déclarent s’être rendus au moins une fois en magasin ou avoir eu l’intention d’y aller après avoir lu un imprimé publicitaire. Source BALmetrie",
  },
  {
    title: "60%",
    text: "des exposés se souviennent d’un dispositif d’opération spéciale issu du street marketing après l’avoir vu dans dans la rue.",
  },
  {
    title: "2 À 3 fois",
    text: "plus de nouveaux clients sont recensés à la suite d'une campagne street-marketing originale.",
  }
]

export const whyChooseFFY = [
  {
    title: 'sans ffy',
    content: [
      "RAPPORTS DE MISSION OPAQUES.",
      "LOGISTIQUE À VOTRE COMPTE.",
      "CRÉATION GRAPHIQUE ET IMPRESSION DE VOS SUPPORTS PAS FORCÉMENT PRIS EN CHARGE.",
      "TROP D’INTERMÉDIAIRES POUR UNE SEULE CAMPAGNE.",
      "NOMBREUX FLYERS QUI FINISSENT À LA POUBELLE.",
      "FLYERS DISTRIBUÉS À L’AVEUGLE.",
    ],
  },
  {
    title: 'avec ffy',
    content: [
      "SUIVI DES RÉSULTATS DE VOTRE CAMPAGNE.",
      "RAPPORTS DE MISSION CLAIRS ET DÉTAILLÉS.",
      "PROPOSITION DE CRÉATION ET RÉALISATION DE VOS SUPPORTS DE COMMUNICATION.",
      "LOGISTIQUE PRISE EN CHARGE PAR NOS ÉQUIPES.",
      "1 SEUL ACTEUR POUR TOUTES VOS CAMPAGNES.",
      "MEILLEURE GESTION DES FLYERS POUR ÉVITER LE GÂCHIS.",
    ]
  }
]

export const ourPlusData = [
  {
    title: "Transparence et reporting",
    text: "Suivez et analysez facilement les performances de vos campagnes de distribution grâce à nos divers outils. En amont de l’opération, un planning et un plan de distribution précis est fourni, vous permettant d’effectuer vos propres contrôles. Ces outils vous permettent de mieux planifier vos campagnes et faire baisser le coût d’acquisition clients (CAC).",
  },
  {
    title: "Technologie et algorithme",
    text: "Nos technologies apportent un soutien fort à vos campagnes, quel que soit le mode de distribution. Notre outil Big Data nous permet de choisir les emplacements de vos campagnes et nos algorithmes d'optimisation de parcours accompagnent nos distributeurs, leur permettant de trouver l’itinéraire le plus adéquat lors de la distribution de vos flyers.",
  },
  {
    title: "Force logistique",
    text: "En maîtrisant toutes les étapes de la conception jusqu’à la distribution de votre support, nous pouvons activer votre campagne de distribution de flyers ou de street marketing dans un délai très court. Cela vous permet de concevoir une communication réactive et de toucher votre cible au bon moment.",
  },
]

export const howitWorksData = [
  {
    title: "PLANIFIEZ LA MISSION",
    text: "Nous utilisons la ville et ses rues comme moyen de communiquer votre message directement à vos prospects, à travers des campagnes innovantes et réfléchies. Pour ça, nous nous basons sur les connaissances de nos experts en géomarketing et notre algorithme de traitement de données. Une mission réussie est une mission bien préparée et commence par l’étude et la planification de votre projet publicitaire.",
  },
  {
    title: "UN CONSEILLER VOUS RAPPELLE",
    text: "Après avoir analysé vos besoins, un expert vous rappelle et vous conseille sur la coordination de la création, l’organisation et la logistique de votre campagne. C’est le début de notre accompagnement, avec comme objectif le succès votre projet publicitaire de street marketing.",
  },
  {
    title: "LE JOUR-J",
    text: "Votre campagne démarre. Des professionnels du milieu se rendent sur terrain au nom de votre entreprise et mènent votre opération à bien en s’adaptant à leur environnement. Vous êtes tenu.e au courant au fur et à mesure de l’avancée de votre campagne publicitaire à travers des reportings et feedbacks réguliers.",
  },
  {
    title: "LE TOUR EST JOUÉ !",
    text: "Enfin, tout est automatisé, règlement comme facture. Notre équipe Customer Success est là pour répondre à vos questions, et reste à disposition pour vous aider à préparer votre prochaine mission de street marketing.",
  }
]


export const statsFlyingBox = [
  {
    title: "98%",
    text: "de recevabilité de votre support de communication (flyers, prospectus…)",
  },
  {
    title: "91%",
    text: "des foyers français lisent les courriers publicitaires.",
  },
  {
    title: "2 à 3 fois",
    text: "plus de clients constatés à la suite d’une campagne de street marketing originale.",
  }
]
export const advantagesFlyingBox = [
  {
    title: "Rapidité",
    text: "En 48 heures, nos équipes organisent la distribution de votre support de communication dans les boîtes aux lettres de votre cible. Votre campagne de street marketing est lancée rapidement, sans pour autant perdre en qualité.",
  },
  {
    title: "Suivi en temps réel",
    text: "Tous nos distributeurs bénéficient d’un tracking à l’aide de notre outil de géolocalisation en temps réel. Ainsi, nous sommes sûrs de couvrir toute votre zone de chalandise et de nous assurer que vos prospectus, flyers ou autres supports publicitaires arrivent à bon port.",
  },
  {
    title: "Rapport détaillé",
    text: "À la fin de la distribution, nous vous fournissons un rapport détaillé de l’opération. Il comporte photos et bilans quanti et quali de la campagne, des chiffres vous permettant de calculer le ROI pour votre entreprise.",
  },
]
export const statsFlyingHand = [
  {
    title: "91%",
    text: "des personnes interrogées apprécient les opérations  de street marketing.",
  },
  {
    title: "38%",
    text: "des passants se souviennent de la marque représentée par les animateurs au cours d’une distribution de flyers en main-à-main.",
  },
  {
    title: "81%",
    text: "des passants se rendent en magasin après la réception d’un flyer publicitaire.",
  }
]
export const advantagesFlyingHand = [
  {
    title: "Rapidité",
    text: "En 48 heures, nos équipes sont capables d’organiser la distribution de vos flyers ou autres de communication dans les rues fréquentées par votre cible. Votre distribution est lancée rapidement, sans pour autant perdre en qualité.",
  },
  {
    title: "VISIBILITÉ",
    text: "Notre connaissance du secteur du street marketing nous permet de vous proposer les spots de distribution les plus adaptés à votre projet et les points de distribution les plus visibles auprès de vos potentiels clients.",
  },
  {
    title: "CAPITAL SYMPATHIE",
    text: "Nos animatrices et animateurs expérimenté.es sont souriant.es et dynamiques. Ils ne manqueront pas d’augmenter le capital sympathie de votre entreprise auprès de tous les passants à travers la distribution !",
  },
]
export const DistributionComplements = [
  {
    title: "LE KIT BRANDING",
    text: "Permet d’humaniser directement la distribution pour votre marque grâce à l’habillage de nos représentants sur site, tout en mettant son identité visuelle en avant.",
  },
  {
    title: "FLAG BAG",
    text: "Ajoute énormément de visibilité à la campagne publicitaire et permet de diffuser un complément d’informations au public.",
  },
  {
    title: "KAKÉMONOS",
    text: "Idéal pour faire du Drive to Store en ajoutant des informations de localisation sur les produits et/ou les services, et invite à se rendre en magasin physiquement.",
  },
]

export const advantagesFlyingStore = [
  {
    title: "CIBLAGE",
    text: "Nous sélectionnons méticuleusement chaque commerce étant susceptible d’attirer du trafic à votre opération de Street-Marketing et de la visibilité à votre marque. Grâce à ce ciblage personnalisé, chaque support de communication (prospectus, brochure…) touche vos clients potentiels.",
  },
  {
    title: "SUIVI EN TEMPS RÉEL",
    text: "Nous trackons tous nos distributeurs via notre outil de géolocalisation en temps réel afin de bien couvrir toute votre zone de chalandise et nous assurer que vos prospectus / flyers ou autre supports arrivent à bon port.",
  },
  {
    title: "RAPPORT DÉTAILLÉ",
    text: "Nous vous fournissons un rapport détaillé de l’opération, de l’impression à la distribution. Une fois celle-ci terminée vous pourrez directement calculer votre ROI. Ce rapport comporte photos et bilans quanti et quali de votre campagne publicitaire.",
  },
]

export const statsFlyingStore = [
  {
    title: "75%",
    text: "taux d’acceptation moyen en commerce.",
  },
  {
    title: "38%",
    text: "des consommateurs Français considèrent la publicité en commerce comme celle qui incite le plus à l’achat.",
  },
  {
    title: "59%",
    text: "des Français se sentent plus proches d’une marque ou d’une entreprise qui communique en magasin.",
  }
]

export const advantagesFlyingBike = [
  {
    title: "ÉCO-RESPONSABILITÉ",
    text: <>Aucune émission de CO2 pour votre campagne publicitaire.<br />Impression de bâche (affichage) éco-friendly.</>,
  },
  {
    title: "MOBILITÉ",
    text: <>L’affichage mobile permet d’augmenter le nombre de prospects pouvant voir votre message publicitaire et en ce sens, interagir avec vous.<br />Les bâches sont disponibles en différents formats pour votre vélo et favoriser la créativité.</>,
  },
  {
    title: "SUIVI ET PERFORMANCES",
    text: <>Grâce à nos outils de géomarketing nous confectionnons un itinéraire traversant votre zone de chalandise.<br />L’intégration d’outils comme des QR Code permet d’avoir un retour quantitatif sur votre campagne de street marketing !</>,
  },
]

export const statsFlyingBike = [
  {
    title: "75%",
    text: <>des personnes se souviennent d’un affichage mobile ou dynamique.<br />Source : LSA conso 2020</>,
  },
  {
    title: "55 À 120 000",
    text: <>contacts en activation mobile<br />
      (10 000 contacts/heure).<br />Source : Marketing Unit Intra - IPSOS, 2020</>,
  },
  {
    title: "59%",
    text: <>taux de mémorisation de l’affichage mobile, tous formats confondus.<br />Source : LSA conso, 2020</>,
  }
]

export const advantagesFlyingWall = [
  {
    title: "ÉCO-RESPONSABILITÉ",
    text: <>Utilisation de papier recyclé ou sans métaux lourds certifié PEFC 100%.<br />Impression sans solvant.<br />Utilisation de colle écologique à base d’amidon de pomme de terre !</>,
  },
  {
    title: "CRÉATIVITÉ",
    text: "Laissez libre court à votre imagination grâce à la liberté offerte par l’affichage sauvage.\nVariez les formats, les tailles et les agencements de vos visuels facilement.\nAinsi vous marquerez facilement les esprits !",
  },
  {
    title: "PERFORMANCES",
    text: <>Que ce soit pour un lancement de produit, dans l’optique de faire du branding ou d’améliorer votre visibilité, l’affichage urbain vous permet de toucher vos prospects facilement.<br />
      Ce mode de communication s’adapte parfaitement à n’importe quel objectif !</>,
  }
]

export const statsFlyingWall = [
  {
    title: "44%",
    text: <>des personnes se souviennent d’un affichage statique.<br />Source : LSA conso 2020</>,
  },
  {
    title: "40000",
    text: <>nombre moyen de contacts quotidiens touchés par un affichage extérieur.<br />Source : Illigo, 2020</>,
  },
  {
    title: "85%",
    text: <>des Français se déplacent chaque jour et donc, passent devant des panneaux d’affichage.<br />Source : LSA conso, 2020</>,
  }
]

export const marketeurJoin = [
  {
    title: 'AVOIR LE STATUT AUTO-ENTREPRENEUR',
    icon: FUSEE_SM
  },
  {
    title: 'ÊTRE DISPONIBLE + DE 5H PAR SEMAINE',
    icon: TIME_SM
  },
  {
    title: 'PARTAGER NOS VALEURS',
    icon: RESEAU_SM
  },
]


export const alternateImagesFBox = [
  {
    img1: FLYING_BOX_ZAPP,
    img2: FLYING_BOX_IMG_1
  },
  {
    img1: FLYING_BOX_IMG_2,
    img2: FLYING_BOX_BAZIN_2,
  },
]
export const alternateImagesFHand = [
  {
    img1: FLYING_HAND_ANIMALIS_2,
    img2: FLYING_BOX_MACDO
  },
  {
    img1: FLYING_HAND_NUK,
    img2: FLYING_HAND_BODYHIT_3,
  },
]
export const alternateImagesFStore = [
  {
    img1: FLYING_STORE_BUCOLIA,
    img2: FLYING_STORE_BOXY_3
  },
  {
    img1: FLYING_STORE_BOXY_4,
    img2: FLYING_STORE_19M,
  },
]
export const alternateImagesFBike = [
  {
    img1: FLYING_BIKE_VICTORIA,
    img2: FLYING_BIKE_BALDORIA_3
  },
  {
    img1: FLYING_BIKE_SCHLOUKMAP,
    img2: FLYING_BIKE_IMMOBEL,
  },
]
export const alternateImagesFWall = [
  {
    img1: FLYING_WALL_OUESTLED_2,
    img2: FLYING_WALL_LITTLEWORKER_3
  },
  {
    img1: FLYING_WALL_LITTLEWORKER,
    img2: FLYING_WALL_OP2,
  },
]


export const secteurActivity = [
  "RESTAURATION", "IMMOBILIER", "SONDAGE", "LOCATION VÉHICULE", "MISE EN RELATION",
  "FINANCE", "ESTHÉTIQUE", "INNOVATION", "LIVRAISON", "POLITIQUE", "SPORT", "RADIO", 
  "MUSIQUE", "HÔTELLERIE", "E-COMMERCE", "AUTOMOBILE", "ENERGIE", "ANIMAUX", "BATIMENT",
  "EDUCATION", "MEDICAL", "INSTITUTION PUBLIQUE", "MODE", "CULTURE"
];

export const problmatique = [
  "ACQUISITION CLIENT", "VISIBILITÉ", "IDENTITÉ", "LANCEMENT"
]

export const solutionsName = [
  "FLYING HAND", "FLYING BOX", "FLYING STORE", "FLYING BIKE", "FLYING WALL",
  "CRÉATION GRAPHIQUE", "SITE WEB", "PUB EN LIGNE"
]

export const problimatisationMacDo = [
  {
    title: 'PROBLÉMATIQUE',
    content: [
      "ACQUISITION CLIENT",
      "RESTAURATION",
    ],
  },
  {
    title: 'SOLUTION PROPOSÉE',
    content: [
      <>FLYING BOX :<br />
        CIBLAGE PRÉCIS ET DE GRAND ENVERGURE<br />
        ÉQUIPE DE STREET MARKETEUR PROFESSIONNELS ET EFFICACES
      </>,

    ]
  }
]

export const statsPeopleFFY = [
  {
    title: "ANNÉE DE CRÉATION",
    text: <>2019</>,
  },
  {
    title: "COLLABORATEURS",
    text: <>10</>,
  },
  {
    title: "PARITÉ H/F",
    text: <>40%&nbsp;&nbsp;&nbsp;60%</>,
  },
  {
    title: "ÂGE MOYEN",
    text: <>24</>,
  }
]

export const peopleObjective = [
  {
    title: "NOTRE OBJECTIF ?",
    text: <>
      Améliorer l’image de marque de nos clients et les accompagner dans la diffusion de leurs messages à leurs clients.
      <br /><br /> Que ça soit la promotion de produits, de services ou d’évènements, dans tous les cas, nos différents domaines d’expertises sont exploités.
      <br /><br />Nous voulons optimiser les résultats de toutes nos campagnes clients, tout en fournissant un environnement de travail sain, bienveillant et cool à nos collaborateurs !
      <br /><br />Notre engagement sociétal repose sur notre souhait de permettre à toute personne, qu’importe sa classe sociale, de réaliser des animations de street-marketing et de générer des revenus. Nous proposons également des alternatives responsables pour toutes nos offres, permettant ainsi à nos clients de se rendre acteurs dans la protection de l’environnement.
    </>,
    link: null
  }
]

export const findInCollab = [
  {
    title: "",
    text: "Nous recherchons des esprits audacieux, qui osent, qui prennent des risques tout en faisant attention à ce qu’il y a autour. Nous recherchons des personnes authentiques, rigoureuses, qui débordent de créativité et qui n’ont pas peur de relever des challenges quotidiens. `Si vous êtes un pro du stand up, c’est un plus : ici, on aime bien rire de temps en temps ! ",
    link: null
  }
]

export const peopelBonus = [
  {
    title: "BONUS +++ RECHERCHÉ",
    text: "Si vous présentez des gènes de super-héros, si vous vous connaissez un peu en communication et si vous aimez le sport (pour nous motiver à en faire au moins 1 fois par mois...), alors nous nous sommes bien trouvés.",
    link: "#offres_ffy"
  }
]

export const cultureFFY = [
  {
    text: "Accompagner les professionnels dans la mise en place de leurs campagnes de communication à travers des canaux viables et créatifs.",
    title: 'NOTRE MISSION'
  },
  {
    text: "Révolutionner le street-marketing en le couplant au digital et au brand marketing, tout en créant un écosystème collaboratif et sain.",
    title: 'NOTRE VISION'
  },
]

export const offresFFY = [
  {
    text: <>AGENT OPÉRATIONNEL<br/><span style={{ fontSize: 24, fontWeight: 600, color: "#00FFE0" }}>CDI</span> </>,
    btn: true,
    link: "https://flyingforyou.notion.site/Fiche-de-poste-Agent-op-rationnel-CDI-a11501e3820a46ed8674766007995707?pvs=4"
  },
  // {
  //   text: <>CHEF(FE) DE PROJET MARKETING<br/><span style={{ fontSize: 24, fontWeight: 600, color: "#00FFE0" }}>STAGE</span> </>,
  //   btn: true,
  //   link: "https://flyingforyou.notion.site/Chef-fe-de-Projet-Marketing-Stage-c4a50e37474d4a2e8ab27fdbd5a588ab?pvs=4"
  // },
  // {
  //   text: <>BUSINESS DEVELOPER<br/><span style={{ fontSize: 24, fontWeight: 600, color: "#00FFE0" }}>ALTERNANCE</span> </>,
  //   btn: true,
  //   link: "https://flyingforyou.notion.site/Business-Developer-ALTERNANCE-4bcc4412a6824c17970c074f84e06eb8?pvs=4"
  // },
  {
    text: <>DÉVELOPPEUR FULL STACK<br/><span style={{ fontSize: 24, fontWeight: 600, color: "#00FFE0" }}>ALTERNANCE</span> </>,
    btn: true,
    link: "mailto:imessias@flyingforyou.fr",
    // link: "https://flyingforyou.notion.site/D-veloppeur-Full-Stack-Alternance-fcef77f2c3b1482daf58d65bbdc6d3c3?pvs=4"
  },
  
  {
    text: <>ACCOUNT MANAGER<br/><span style={{ fontSize: 24, fontWeight: 600, color: "#00FFE0" }}>CDI</span> </>,
    btn: true,
    link: "mailto:cheikh.kane@flyingforyou.fr"
  },
]

export const nosValeur = [
  [{ title: "ÉCOUTE", link: null }, { title: "HUMILITÉ", link: null }, { title: "RESPECT", link: null }],
  [{ title: "BONNE HUMEUR", link: null }, { title: "FLEXIBILITÉ", link: null }, { title: "ALTRUISME", link: null }],
]
