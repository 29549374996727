import React, { Fragment } from 'react'
import ImageBloc, { DoubleImageBloc } from '../components/ImageBloc/imageBloc'
import { IntroductionUseCase } from '../components/IntroductionFFY/introductionFFY'
import { UseCaseProblimatisation } from '../components/Explain/explain'
import { convertExplainToArray, convertStatsToArray } from '../functions/functions'
import { UseCaseAction } from 'components/Buttons/button'
import { CustomDivider } from 'components/DivideTitle/divideTitle'
import Comments from '../components/Comments/comments'
import Statistics from '../components/Statistics/statistics'
import { useNavigate } from 'react-router-dom'


export const IntroductionForUseCase = ({ data, header_url, widthp1 }) => {
  return (
    <IntroductionUseCase title={data?.title} activitySector={data?.activity}
      paraph_style2={{ fontSize: 32, paddingBottom: 80 }} paraph_style1={{ fontSize: 24, width: widthp1, paddingBottom: 80 }}
      style={{ fontSize: 64, fontWeight: 700 }} ffy_style={{ padding: '10px 0 50px 60px' }}
      paraph1={data?.subTitle} paraph2={data?.description} avatar={header_url} />
  )
}

const IndexSectionsUseCase = ({ data }) => {
  console.log(data)
  const navigate = useNavigate()
  const { video_header, header, double_image, explain, stats, sub_image, commentary, } = data
  const components = [
    {
      reference: header, name: 'header',
      component: video_header?.visible && <IntroductionForUseCase data={header?.datas}
        header_url={data?.header_url} widthp1='70%' />,
    },
    {
      reference: double_image, name: 'double_image',
      component: <DoubleImageBloc srcLeft={data?.file1_url}
        srcRight={data?.file2_url} />,
    },
    {
      reference: explain, name: 'explain',
      component: <UseCaseProblimatisation bg_color={'#23272A'} alternate_color={false} style_bloc1={{ flex: 2 }}
        list_items={convertExplainToArray(explain?.datas)} color={'white'} style_bloc2={{ flex: 2 }} />,
    },
    {
      reference: stats, name: 'stats',
      component: <> <Statistics w_title={<>LA CAMPAGNE<br />EN QUELQUES CHIFFRES</>} g_title={""}
        listStats={convertStatsToArray(stats?.datas?.content)} bg_color={'white'}
        color={'#23272A'} w_style={{ color: '#23272A', fontSize: 40 }} justify={stats?.datas?.content?.length < 3 ? 'center' : 'space-between'}
        w_style2={{ fontSize: 35 }}
        margin={stats?.datas?.content?.length < 3 ? '100px' : '0'} />
        <CustomDivider bg_color={"#23272A"} width="100%" color={"#23272A"} />
      </>,
    },
    { reference: sub_image, name: 'sub_image', component: <ImageBloc src={data?.sub_image_url} height={615} />, },
    {
      reference: commentary, name: 'commentary',
      component: <Comments avatar={data?.commentary_url || ""} comments={commentary?.datas?.description} />
    }
  ]

  const displaySortedComponents = components
    ?.sort((a, b) => +a?.reference?.position - +b?.reference?.position)
    ?.map((section, index) =>
      <Fragment key={`${section?.reference}-${index}`}>
        {section?.reference?.visible &&
          section?.component}
      </Fragment>
    )

  return (
    <>
      {displaySortedComponents}
      <UseCaseAction nextName={data?.next_name} name prevName={data?.prev_name} navigate={navigate} />
      <CustomDivider bg_color={"#23272A"} width="100%" color={"#23272A"} />
    </>
  )
}


export default IndexSectionsUseCase