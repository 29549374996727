import React from 'react'
import { Typography } from '@mui/material'
import './statistics.scss'
import { ParentContainer } from 'components/ParentContainer/parentContainer';
import DivideTitle from 'components/DivideTitle/divideTitle';

const Statistics = (props) => {
  const { w_title, w_title2, g_title, listStats, w_style, justify, margin,
    bg_color, color, g_style, start_space, w_style2 } = props;
  return (
    <ParentContainer className='usecase_stats_section' style={{ backgroundColor: bg_color }}>
      <div className='usecase_stats'>
        <DivideTitle type={'h3'} w_title={w_title} g_title={g_title} w_title2={w_title2}
          g_style={g_style} w_style={w_style} start_space={start_space} w_style2={w_style2} />
        <div style={{ marginLeft: margin }}>
          <div className='usecase_stats_container' style={{ justifyContent: justify }}>
            {listStats?.map((stat, index) => {
              return (
                <div className='usecase_stats_content' key={`stat-${index}`}>
                  <div className='usecase_stats_stat' style={{ color: color }}>
                    <Typography variant='p' className='usecase_stats_title'>{stat?.title?.toUpperCase()} </Typography>
                    <Typography variant='p' className='usecase_stats_text'>{stat?.text} </Typography>
                  </div>
                  {index < listStats?.length - 1 &&
                    <div className='usecase_stats_divider' style={{ border: `1px solid ${color}` }} ></div>}
                </div>)
            })}
          </div>
        </div>
      </div>
    </ParentContainer>
  )
}

export default Statistics