import { InternRedirectionButton } from 'components/Buttons/button'
import './style.scss'
import Footer from 'components/Footer/footer'
import { useEffect, useState } from 'react';


const Notfound = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);

  const zoom = windowSize?.width < 1440 ? windowSize?.width / 1440 : 1;

  return (
    <div className='not-found-container' style={{
      zoom: zoom, transformOrigin: 'top left'
    }}>
      <div className='not-found-content'>
        <div className='not-found-content-text'>Cette page n'existe pas</div>
        <InternRedirectionButton text={"Retour à l'accueil"} style={{ fontSize: 18, }}
          className={'redirectBtnWhite'} link={"/"} />
      </div>
      <div style={{ width: "100%", flex: 1, backgroundColor: 'green' }}>
        <Footer style={{height: '100%',}} />
      </div>
    </div>

  )
}

export default Notfound