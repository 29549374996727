import { Typography } from "@mui/material"
import { SimpleButton } from "components/Buttons/button"
import { ParentContainer } from "components/ParentContainer/parentContainer"
import './chooseffy.scss'


export const UseCaseProblimatisation = ({ bg_color, color = "23272A", list_items,
alternate_color = true, style_bloc1, style_bloc2 }) => {
return (
  <ParentContainer className="usecase_explain" style={{ backgroundColor: bg_color }}>
    <div className='usecase_explain_container'>
      <div className='usecase_explain_content_container'>
        {list_items?.map((item, index) => {
          return (
            <div key={`content-${index}`} className='usecase_explain_content'>
              <div className='btn' style={style_bloc1}>
                <SimpleButton text={item?.title?.toUpperCase()} style={{ fontSize: 35, cursor: 'unset' }}
                  className={`${alternate_color ? index === 0 ? 'simpleBtnWhite' : 'simpleBtnBlack' : 'simpleBtnWhite'}`} />
              </div>
              <div className='usecase_explain_content_bloc_list' style={style_bloc2}>
                <div style={{ border: `1px solid ${color}` }} className='usecase_explain_divider'></div>
                <div className='usecase_explain_content_list'>
                  {item?.content?.map((argument, indexj) => {
                    return (
                      <Typography key={`arg-${indexj}`} style={{ color: color }} className='usecase_explain_argument'>{argument}</Typography>
                    )
                  })}
                </div>
              </div>
            </div>)
        })}
      </div>
    </div>
  </ParentContainer>
)
}
export const SpecialExplain = ({ bg_color, color = "23272A", list_items, style_bloc1, style_bloc2 }) => {
return (
  <ParentContainer className="usecase_explain" style={{ backgroundColor: bg_color }}>
    <div className='usecase_explain_container'>
      <div className='usecase_explain_content_container'>
        {list_items?.map((item, index) => {
          return (
            <div key={`content-${index}`} className='usecase_explain_content'>
              <div className='btn' style={style_bloc1}>
                <p className="explain_special_title">{item?.title} </p>
              </div>
              <div className='usecase_explain_content_bloc_list' style={style_bloc2}>
                <div style={{ border: `1px solid ${color}` }} className='usecase_explain_divider'></div>
                <div className='usecase_explain_content_list'>
                  {item?.content?.map((argument, indexj) => {
                    return (
                      <Typography key={`arg-${indexj}`} style={{ color: color, paddingLeft: 10 }} className='usecase_explain_argument'>{argument}</Typography>
                    )
                  })}
                </div>
              </div>
            </div>)
        })}
      </div>
    </div>
  </ParentContainer>
)
}