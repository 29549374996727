import React from 'react'
import { DownloadBtns, RedirectButton } from 'components/Buttons/button'
import DivideTitle, { DivideTitle2 } from 'components/DivideTitle/divideTitle'
import logoFFY from 'assets/biglogo.png'
import './introductionffy.scss'
import { logo } from 'utils/imageUrls'


const IntroductionFFY = ({ w_title, w_title2, g_title, paraph1, paraph2, g_style, ffy_style, className = "",
  w_style, btn_size = 32, title_type2 = false, title_start_space, marketeur = false, w_style2, useCase = false, logo = false }) => {

  return (
    <div id='ffy_introduction'  style={{ backgroundColor: "#23272a" }}>
      <div className={`${className} introduction_container`}>
        {logo && <div className='introduction_logo'>
          <img src={logoFFY} alt="logo-ffy" width={329} height={274} />
        </div>}
        <div className='introduction_content' style={ffy_style}>
          {
            !title_type2 ?
              <DivideTitle type={'h1'} w_title={w_title} w_style={{ fontSize: 32, ...w_style }}
                g_style={{ fontSize: 32, color: '#00FFE0', ...g_style }} w_style2={w_style2}
                w_title2={w_title2} g_title={g_title} start_space={title_start_space} />
              :
              <DivideTitle2 type={'h1'} w_title={w_title} w_style={w_style}
                g_style={{ color: '#00FFE0', ...g_style }} w_title2={w_title2} g_title={g_title} />
          }
          <div className='introduction_paragraphs'>
            <p className='paraph1'>{paraph1} </p>
            <p className='paraph2'>{paraph2} </p>
          </div>
          {
            !useCase ?
              !marketeur ?
                <div className='btn'>
                  <RedirectButton text={"DEVIS GRATUIT"} style={{ fontSize: btn_size }} className={'redirectBtnWhite'}
                    link={"https://flyingforyou.typeform.com/to/GijrgN0Q?utm_campaign=SEO+-+Distrib&utm_source=R%C3%A9f%C3%A9rencement+Naturel&utm_medium=typeform-distrib-SEO&utm_term=distribution-de-flyers&utm_content=typeform-distrib-SEO-2&typeform-source=www.flyingforyou.fr"} />
                </div>
                :
                <DownloadBtns />
              : null
          }
        </div>
      </div>

    </div>
  )
}

export default IntroductionFFY


export const IntroductionUseCase = ({ w_title, paraph1, paraph2, ffy_style, paraph_style1,
  w_style, paraph_style2 }) => {
  return (
    <div style={{ backgroundColor: "#23272a" }}>
      <div className='logos'>
        <div className='ffylogo'>
          <img src={logo} alt="logoffy" />
        </div>
        <div style={{ borderLeft: '1px solid white', height: 60, marginLeft: 10, marginRight: 20 }}></div>
        <div className='usecase-logo'>
          <img src={logo} alt="usecase-logo" />
        </div>
      </div>
      <div className='container'>
        <div className='content' style={ffy_style}>
          <DivideTitle type={'h1'} w_title={w_title} w_style={{ w_style }} />
          <div className='paragraphs'>
            <p style={paraph_style1}>{paraph1} </p>
            <p style={paraph_style2} className='paraph2'>{paraph2} </p>
          </div>
        </div>
      </div>
    </div>
  )
}