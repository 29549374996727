import {
  SFR,
  ADA,
  BOXY,
  HSBC,
  MCDO,
  ORPI,
  ENGIE,
  PARIS,
  CHANEL,
  DEEZER,
  POKAWA,
  ORANGE,
  EIFFAGE,
  COOLTRA,
  LACTALIS,
  LA_FORET,
  ANIMALIS,
  FUN_RADIO,
  RADIOFRANCE,
  THE_BRADERY,
  FITNESS_PARK,
  TOTAL_ENERGIES,
  STEPHANE_PLAZA,
  PARIS_FOOD_CLUB,
  BOUYGUES_IMMOBILIER, 
} from "./imageUrls";

const partnerList = [
 
  {
    name: "SFR",
    link: <img alt="mc-donald-logo" width={146} height={146} src={SFR} />,
  },
  {
    name: "ADA",
    link: <img alt="logo-ada" width={146} height={146} src={ADA} />,
  },
  {
    name: "BOXY",
    link: <img alt="hsbc-logo" width={146} height={146} src={BOXY} />,
  },
  {
    name: "HSBC",
    link: <img alt="logo-animalis" width={146} height={146} src={HSBC} />,
  },
  {
    name: "MCDO",
    link: <img alt="logo-body-hit" width={146} height={146} src={MCDO} />,
  },
  {
    name: "ORPI",
    link: <img alt="logo-est-ensemble" width={146} height={146} src={ORPI} />,
  },
  {
    name: "ENGIE",
    link: <img alt="ogo-fever" width={146} height={146} src={ENGIE} />,
  },
  {
    name: "PARIS",
    link: <img alt="logo-pokawa" width={146} height={146} src={PARIS} />,
  },
  {
    name: "CHANEL",
    link: <img alt="logo-radio-france" width={146} height={146} src={CHANEL} />,
  },
  {
    name: "DEEZER",
    link: <img alt="logo-schloukmap" width={146} height={146} src={DEEZER} />,
  },
  {
    name: "POKAWA",
    link: <img alt="logo-victoria" width={146} height={146} src={POKAWA} />,
  },
  {
    name: "ORANGE",
    link: <img alt="logo-yellow-korner" width={146} height={146} src={ORANGE} />,
  },
  {
    name: "EIFFAGE",
    link: <img alt="logo-ada" width={146} height={146} src={EIFFAGE} />,
  },
  {
    name: "COOLTRA",
    link: <img alt="hsbc-logo" width={146} height={146} src={COOLTRA} />,
  },
  {
    name: "LACTALIS",
    link: <img alt="bellman-logo" width={146} height={146} src={LACTALIS} />,
  },
  {
    name: "LA_FORET",
    link: <img alt="logo-engie" width={146} height={146} src={LA_FORET} />,
  },
  {
    name: "ANIMALIS",
    link: <img alt="ogo-fever" width={146} height={146} src={ANIMALIS} />,
  },
 
  {
    name: "FUN_RADIO",
    link: <img alt="logo-radio-france" width={146} height={146} src={FUN_RADIO} />,
  },
  {
    name: "RADIOFRANCE",
    link: <img alt="mc-donald-logo" width={146} height={146} src={RADIOFRANCE} />,
  },
  {
    name: "THE_BRADERY",
    link: <img alt="cooltra-logo" width={146} height={146} src={THE_BRADERY} />,
  },
  {
    name: "FITNESS_PARK",
    link: <img alt="logo-animalis" width={146} height={146} src={FITNESS_PARK} />,
  },
  {
    name: "TOTAL_ENERGIES",
    link: <img alt="logo-est-ensemble" width={146} height={146} src={TOTAL_ENERGIES} />,
  },
  {
    name: "STEPHANE_PLAZA",
    link: <img alt="ogo-fever" width={146} height={146} src={STEPHANE_PLAZA} />,
  },
  {
    name: "PARIS_FOOD_CLUB",
    link: <img alt="logo-fun-radio" width={146} height={146} src={PARIS_FOOD_CLUB} />,
  },
  {
    name: "BOUYGUES_IMMOBILIER",
    link: <img alt="logo-victoria" width={146} height={146} src={BOUYGUES_IMMOBILIER} />,
  },
]

export default partnerList