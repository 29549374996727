import React, { Fragment } from 'react'
import { usecaseData } from './dataConfig'
import ImageBloc, { DoubleImageBloc } from '../components/ImageBloc/imageBloc'
import { IntroductionUseCase } from '../components/IntroductionFFY/introductionFFY'
import { SpecialExplain, UseCaseProblimatisation } from '../components/Explain/explain'
import { convertExplainToArray, convertSpecialExplainToArray } from '../functions/functions'
import { SimpleButton, UseCaseAction } from 'components/Buttons/button'
import { CustomDivider } from 'components/DivideTitle/divideTitle'
import Comments from '../components/Comments/comments'
import { useNavigate } from 'react-router-dom'
import CampaignStep from './campaignStep/campaignStep'
import VitrineContainer from 'views/vitrineContainer'


export const IntroductionForUseCase = ({ data, header_url, widthp1 }) => {
  return (
    <IntroductionUseCase title={data?.title} activitySector={data?.activity}
      paraph_style2={{ fontSize: 32, paddingBottom: 80 }} paraph_style1={{ fontSize: 24, width: widthp1, paddingBottom: 80 }}
      style={{ fontSize: 64, fontWeight: 700 }} ffy_style={{ padding: '10px 0 50px 60px' }} padding='0'
      paraph1={data?.subTitle} paraph2={data?.description} avatar={header_url} />
  )
}

const SpecialUsecase = () => {
  const data = usecaseData
  const navigate = useNavigate()
  const { video_header, header, double_image, explain, sub_image, commentary, campaignStep, special_explain } = data
  const components = [
    {
      reference: header, name: 'header',
      component: video_header?.visible && <IntroductionForUseCase data={header?.datas}
        header_url={data?.header_url} widthp1='70%' />,
    },
    {
      reference: double_image, name: 'double_image',
      component: <DoubleImageBloc srcLeft={data?.file1_url}
        srcRight={data?.file2_url} />,
    },
    {
      reference: explain, name: 'explain',
      component: <UseCaseProblimatisation bg_color={'#23272A'} alternate_color={false} style_bloc1={{ flex: 2 }}
        list_items={convertExplainToArray(explain?.datas)} color={'white'} style_bloc2={{ flex: 2 }} />,
    },
    {
      reference: campaignStep, name: 'steps',
      component: <CampaignStep />
    },
    {
      reference: sub_image, name: 'sub_image', component: <div style={{ position: 'relative' }}>
        <ImageBloc src={data?.sub_image_url} height={761} />
        <SimpleButton text='AVANT / APRÈS' style={{
          fontSize: 40, cursor: 'unset', zIndex: 9999999,
          position: 'absolute', bottom: 104, left: 94,
          backgroundColor: "transparent"
        }}
          className={'simpleBtnWhite'} />
      </div>,
    },
    {
      reference: special_explain, name: 'special_explain',
      component: <SpecialExplain bg_color={'#23272A'} alternate_color={false} style_bloc1={{ flex: 2 }}
        list_items={convertSpecialExplainToArray(special_explain?.datas)} color={'white'} style_bloc2={{ flex: 2 }} />,
    },
    {
      reference: commentary, name: 'commentary',
      component: <Comments avatar={data?.commentary_url || ""} comments={commentary?.datas?.description} />
    }
  ]

  const displaySortedComponents = components
    ?.sort((a, b) => +a?.reference?.position - +b?.reference?.position)
    ?.map((section, index) =>
      <Fragment key={`${section?.reference}-${index}`}>
        {section?.reference?.visible &&
          section?.component}
      </Fragment>
    )

  return (
    <>
      <VitrineContainer
        Sections={() => <>
          {displaySortedComponents}
          <UseCaseAction nextId={data?.next_id} name prevId={data?.prev_id} navigate={navigate} />
          <CustomDivider bg_color={"#23272A"} width="100%" color={"#23272A"} />
        </>}
        Introduction={() => <IntroductionForUseCase data={data?.header?.datas} header_url={data?.header_url} />}
        hidden={!data?.video_header?.visible} widthp1={'90%'}
        videoUrl={data?.video_header?.datas?.url}
      />

    </>
  )
}

export default SpecialUsecase

