import FetchingScreen from "components/Loader/loader";
import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  HOME_DIGITAL_CRATION_GRAPHIC, HOME_DIGITAL_MARKETING, HOME_DIGITAL_SEA_SMO, HOME_DIGITAL_SEO,
  HOME_DIGITAL_WEB_SITE, HOME_FLYING_BIKE, HOME_FLYING_BOX, HOME_FLYING_HAND, HOME_FLYING_STORE,
  HOME_FLYING_WALL, HOME_PAGE_FFY, HOME_PEOPLE_PAGE, HOME_STREET_MARKETEUR, HOME_STREET_MARKETING, HOME_USE_CASE
}
  from "routes/vitrineRoutes.js";
import ReactGA from 'react-ga';

import Deeplinking from 'views/Deeplink/deeplink';
import DeeplinkingReset from 'views/Deeplink/passwordConfirmation'
import Cgu from "views/Legals/cgu";
import Cgv from "views/Legals/cgv";
import Notfound from "views/Legals/notFound";
import ThanksPageContainer from "views/ThanksPages/thanksPageContainer";
import SingleUseCaseView from "views/useCases/home/singleUsecase/singleUseCaseView";
import UseCaseView from "views/useCases/useCaseList/useCaseHomeView";
import SpecialUsecase from "views/useCases/home/specialUsecase/specialUsecase";
import PeoplePageContainer from "views/PeoplePage";
const HomeFFYContainer = lazy(() => import(/* webpackChunkName: "Home-FFY" */"views/home"));
const HomeFlyingBoxContainer = lazy(() => import(/* webpackChunkName: "SmServices-FlyingBox" */"views/StreetMarketing/SmServices/FlyingBox"));
const HomeFlyingHandContainer = lazy(() => import(/* webpackChunkName: "SmServices-FlyingHand" */"views/StreetMarketing/SmServices/FlyingHand"));
const HomeFlyingSotreContainer = lazy(() => import(/* webpackChunkName: "SmServices-FlyingStore" */"views/StreetMarketing/SmServices/FlyingStore"));
const HomeFlyingBikeContainer = lazy(() => import(/* webpackChunkName: "SmServices-FlyingBike" */"views/StreetMarketing/SmServices/FlyingBike"));
const HomeFlyingWallContainer = lazy(() => import(/* webpackChunkName: "SmServices-FlyingWall" */"views/StreetMarketing/SmServices/FlyingWall"));
const HomeDigitalContainer = lazy(() => import(/* webpackChunkName: "DigitalMarketing-Home" */"views/DigitalMarketing/Home"));
const WebSiteContainer = lazy(() => import(/* webpackChunkName: "DigitalServices-WebSite" */"views/DigitalMarketing/DigitalServices/WebSite"));
const SeoContainer = lazy(() => import(/* webpackChunkName: "DigitalServices-SEO" */"views/DigitalMarketing/DigitalServices/SEO"));
const SEASMOContainer = lazy(() => import(/* webpackChunkName: "SEA-SMO" */"views/DigitalMarketing/DigitalServices/SEA&SMO"));
const CreationGraphiqueContainer = lazy(() => import(/* webpackChunkName: "DigitalServices-CreationGraphique" */"views/DigitalMarketing/DigitalServices/CreationGraphique"));
const HomeSMarketingContainer = lazy(() => import(/* webpackChunkName: "StreetMarketing-Home" */"views/StreetMarketing/Home"));
const HomeMarketeurContainer = lazy(() => import(/* webpackChunkName: "views-Marketeur" */"views/Marketeur"));

const SuspenseComponent = ({ children }) =>
  <Suspense fallback={<div style={{ minHeight: '85vh' }}><FetchingScreen isFetching={true} /></div>}>
    {children}
  </Suspense>


const App = () => {

  useEffect(() => {
    setTimeout(() => {
      const TRACKING_ID = "UA-137375328-3"; // OUR_TRACKING_ID
      ReactGA.initialize(TRACKING_ID);
      const ReactPixel = require('react-facebook-pixel');
      ReactPixel.default.init('573916863300174');
      ReactPixel.default.pageView()
      const TagManager = require('react-gtm-module');
      const tagManagerArgs = {
        gtmId: 'GTM-NR68RLM'
      }
      TagManager.initialize(tagManagerArgs)
    }, 1500)
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path={HOME_PAGE_FFY} element={<SuspenseComponent children={<HomeFFYContainer />} />} />
        <Route path={HOME_STREET_MARKETING} element={<SuspenseComponent children={<HomeSMarketingContainer />} />} />
        <Route path={HOME_FLYING_BOX} element={<SuspenseComponent children={<HomeFlyingBoxContainer />} />} />
        <Route path={HOME_FLYING_HAND} element={<SuspenseComponent children={<HomeFlyingHandContainer />} />} />
        <Route path={HOME_FLYING_STORE} element={<SuspenseComponent children={<HomeFlyingSotreContainer />} />} />
        <Route path={HOME_FLYING_BIKE} element={<SuspenseComponent children={<HomeFlyingBikeContainer />} />} />
        <Route path={HOME_FLYING_WALL} element={<SuspenseComponent children={<HomeFlyingWallContainer />} />} />
        <Route path={HOME_DIGITAL_MARKETING} element={<SuspenseComponent children={<HomeDigitalContainer />} />} />
        <Route path={HOME_DIGITAL_WEB_SITE} element={<SuspenseComponent children={<WebSiteContainer />} />} />
        <Route path={HOME_DIGITAL_SEO} element={<SuspenseComponent children={<SeoContainer />} />} />
        <Route path={HOME_DIGITAL_SEA_SMO} element={<SuspenseComponent children={<SEASMOContainer />} />} />
        <Route path={HOME_DIGITAL_CRATION_GRAPHIC} element={<SuspenseComponent children={<CreationGraphiqueContainer />} />} />
        <Route path={HOME_STREET_MARKETEUR} element={<SuspenseComponent children={< HomeMarketeurContainer />} />} />
        <Route path={HOME_USE_CASE} element={<SuspenseComponent children={<UseCaseView />} />} />
        <Route path={HOME_PEOPLE_PAGE} element={<SuspenseComponent children={< PeoplePageContainer />} />} />

        <Route path={`${HOME_USE_CASE}/SWYTOUCH`} element={<SuspenseComponent children={<SpecialUsecase />} />} />
        <Route path={`${HOME_USE_CASE}/:name`} element={<SuspenseComponent children={<SingleUseCaseView />} />} />
        <Route path="/deeplink/confirm-email/*" element={<SuspenseComponent children={<Deeplinking />} />} />
        <Route path="/deeplink/reset-password/*" element={<SuspenseComponent children={<DeeplinkingReset />} />} />
        <Route path="/merci-devis" element={<SuspenseComponent children={<ThanksPageContainer />} />} />
        <Route path="/merci-devis-seo" element={<SuspenseComponent children={<ThanksPageContainer />} />} />
        <Route path="/merci-devis-affiche" element={<SuspenseComponent children={<ThanksPageContainer />} />} />
        <Route path="/conditions-generales-d-utilisation" element={<SuspenseComponent children={<Cgu />} />} />
        <Route path="/conditions-generales-de-vente" element={<SuspenseComponent children={<Cgv />} />} />

        <Route path="*" element={<SuspenseComponent children={<Notfound />} />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
